import React, { useState, useContext, createContext } from "react";
import axios from 'axios';
import configApp from '../config';
import _ from 'lodash';
import { usePopup } from "../components/popup/PopupContext";

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

function fillUser(data, subscriptions) {
    let user = {};
    user.email = data.email;
    user.state = data.state;
    user.create = data.create;
    user.name = data.name;
    user.prenom = data.prenom;
    user.country = data.country;
    user.promo = data.promo;
    user.remember = data.remember;
    user.newsletter = data.newsletter;
    user._id = data._id;
    user.isActive = data.isActive;
    user.subscriptions = subscriptions !== undefined ? subscriptions : []
    return user;
}

function useProvideAuth() {

    const popup = usePopup();

    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    const login = async (email, password) => {
        try {
            const loginResponse = await axios.post(configApp.URL_BACKEND + "/api/auth/login/", { 'email': email, 'password': password, 'from': 'web' });
            const user = loginResponse.data;

            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + user.token
            };
            //            const subResponse = await axios.get(configApp.URL_BACKEND + "/api/auth/subscription/");
            //            const { subscriptions } = subResponse.data;
            setUser(fillUser(user, []));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message === "usernonexist") {
                popup.openError("usernonexist");
            } else if (error && error.response && error.response.data && error.response.data.message === "wrongpassword") {
                popup.openError("wrongpassword");
            } else {
                popup.openError("error signup");
            }
        }
    };

    const refreshToken = async (user) => {
        try {
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/check/", { 'from': 'web' });
            if (data === 'no') {
                setUser(null);
            } else {
                if (user === null) {
                    axios.defaults.headers.common = {
                        'Authorization': 'Bearer ' + data.token
                    };
                    //const subResponse = await axios.get(configApp.URL_BACKEND + "/api/auth/subscription/");
                    //const { subscriptions } = subResponse.data;
                    setUser(fillUser(data, []));
                }
            }
        } catch (error) {
            //console.log(error);
            popup.openError('error during token');
        }
    };

    const signup = async (email, password, remember, language) => {
        try {
            const userToCreate = { 'email': email, 'password': password, 'remember': remember, 'from': 'web', 'language': language };
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/signup/", userToCreate);
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + data.token
            };
            setUser(fillUser(data, []));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.error && error.response.data.error._message === "User validation failed") {
                popup.openError("userexist");
            } else {
                popup.openError("error signup");
            }
        }
    };

    const signout = async (_id) => {
        try {
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/signout/", { '_id': _id });
            axios.defaults.headers.common = {
                'Authorization': ''
            };
            setUser(null);
        } catch (error) {
            setUser(null);
            popup.openError('error signout');
        }
    };

    const update = async (userUpdate) => {
        try {
            const userToUpdate = {
                '_id': userUpdate._id, 'password': userUpdate.password
                , 'name': userUpdate.name, 'prenom': userUpdate.prenom, 'country': userUpdate.country
                , 'promo': userUpdate.promo, 'email': userUpdate.email, 'remember': userUpdate.remember
                , 'state': userUpdate.state, 'from': 'web', 'newsletter': userUpdate.newsletter
            };
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/user/", userToUpdate);
            setUser(fillUser(data, []));
        } catch (error) {
            popup.openError('error update');
        }
    };

    const updatePaypalId = async (userUpdate) => {
        try {
            const userToUpdate = { '_id': userUpdate._id, 'paypalid': userUpdate.paypalid, 'state': "full", 'subscriptionStart': new Date(), 'subscriptionStop': null, 'activePayment': "paypal" };
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/create-subscription-paypal", userToUpdate);
            user.state = "full";
            setUser(fillUser(user, []));
        } catch (error) {
            popup.openError('error update paypal id');
        }
    };

    const activate = async (id, langue) => {
        try {
            const userToActivate = { 'id': id, 'langue': langue };
            axios.post(configApp.URL_BACKEND + "/api/mail/activate", userToActivate);
        } catch (error) {
            popup.openError('error activate');
        }
    };

    const cancelSubcription = async (user) => {
        try {
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/cancelsubscription/", user);
            setUser(fillUser(data, []));
            return ("ok");
        } catch (error) {
            popup.openError('error cancel subscription');
        }
    };

    const subcription = async (paymentMethod , promo) => {
        try {
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/create-subscription", { 'paymentMethodId': paymentMethod.id, 'priceLookupKey': 'Basic' , 'promo' : promo });
            let { subError, subscription } = data;
            setUser(fillUser(user, [subscription]));
            return { subError, subscription };
        } catch (error) {
            popup.openError('error subscription');
        }
    };

    const paiementsuccessfull = async () => {
        try {
            await axios.post(configApp.URL_BACKEND + "/api/auth/paiementsuccessfull", {});
            user.state = "full";
            setUser(fillUser(user, []));
        } catch (error) {
            popup.openError('error subscription');
        }
    };

    const sendError = async (message) => {
        try {
            const errorToSend = { 'email': user.email, 'userid': user._id, 'message': message, 'status': 'open' };
            axios.post(configApp.URL_BACKEND + "/api/error", errorToSend);
        } catch (error) {
            popup.openError('error paiement ');
        }
    };

    return {
        user,
        error,
        activate,
        subcription,
        cancelSubcription,
        updatePaypalId,
        login,
        refreshToken,
        signout,
        signup,
        update,
        sendError,
        paiementsuccessfull
    };
}