import React from "react";

function SubcriptionFree() {
  return (
    <section className="subcriptionfree">
      <h1>GRATUIT</h1>
      <ul>
        <li>Créer une congif pour le mode mot et texte.</li>
        <li>Créer, modifier, supprimer des leçons.</li>
        <li>Créer, modifier,supprimer des mots et textes.</li>
        <li>Importer des leçons via un fichier texte.</li>
        <li className="backgroundgreen">Importer tous les niveaux JLPT N5,N4,N3,N2 et N1.</li>
        <li>Utiliser tous les styles de flashcard.</li>
        <li >Imprimer jusqu'à 16 Images ou Kanji.</li>
        {/* <li>
          Pas d’engagement.<br />Vous pouvez arrêter l’abonnement à tout moment d’un
          simple clic.
          <br />Tout mois commencé est dû.
        </li> */}
      </ul>
    </section>
  );
}

export { SubcriptionFree };
