import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { deleteConfig, updateConfigName } from '../../redux/actions/configActions';
import IconSite from './../IconSite';
import { useAuth } from "./../use-auth";
import { usePopup } from "../../components/popup/PopupContext";
import { useTranslation } from "react-i18next";

function Config(props) {

    const [name, setName] = useState(props.name);
    const [isEnable, setIsEnable] = useState(props.isEnable);
    
    const [isFadingOut, setIsFadingOut] = useState(false);

    const dispatch = useDispatch();

    const auth = useAuth();
    const popup = usePopup();
    const { t } = useTranslation();

   /* const handleEnableConfig = (checked) => {
        if (checked) {
//                dispatch(addMotPrint(props._id)).catch((error) => alert("Mot.js 19 " + error));
        } else {
//            dispatch(removeMotPrint(props._id)).catch((error) => alert("Mot.js 21 " + error));
        }
    }*/

    useEffect(() => {
        dispatch(updateConfigName({ _id: props._id, name: name , isEnable : isEnable})).catch(error => alert("config.js row 21: " + error ));
        return () => {
            //
        };
    }, [name , isEnable]);

    return (

        <div className={isFadingOut ? 'config-fadeout' : 'config'}>
            { props.config_number !== 1 ?
                <button type="button" className="icon-button--small" onClick={(event) => {
                    event.preventDefault();
                    if (auth.user) {

                        const monAction = () => {
                            setIsFadingOut(true);
                            setTimeout(() => dispatch(deleteConfig(props._id)).catch(error => alert("config.js row 37: " + error)) , 500 );
                        }
                        popup.setAction(() => monAction);
                        popup.openConfirm('deleteconfirm', name);
                    }
                    else {
                        popup.open(t("signupwarning"));
                    }
                }
                }>
                    <IconSite name="icon-squared-minus" cssname="icon--small" />
                </button> : ""
            }
            <input id={props._id} type="text" value={name} className="config__input" placeholder={t("name")} onChange={(e) => setName(e.target.value)} required />
            <div className="checkbox" >
                    <input id={props._id + "avcg"} type="checkbox" checked={isEnable} onChange={(e) => setIsEnable(e.target.checked)} />
                    <label htmlFor={props._id + "avcg"} >
                        <span className="box"></span>
                    </label>
                </div>
        </div>
    )
}

export default Config