import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { selectMot } from '../../redux/actions/texteActions';

function Texte(props) {

    const dispatch = useDispatch();

    let [isSelected, setisSelected] = useState(props.isSelected);

    const toggleWord = () => {
       // setisSelected(!isSelected);
        dispatch(selectMot(props._id)).catch((error) => alert("Texte.js 13 " + error));
    }

    useEffect(() => {
        setisSelected(props.isSelected);
        return () => {
        };
    }, [props.isSelected]);

    let classN = "mot__form";
    if (props.focus === '1') {
        classN = "mot__form mot__focus";
    }

    return (

        <div className="mot">
            <div className="mot__radio" >
                <input className="mot__radio--input" id={props._id} type="radio" name="word" checked={isSelected} onChange={() => toggleWord()} />
                <label className="mot__radio--label" htmlFor={props._id} >
                </label>
            </div>
            <div className={classN}>
                <div className={props.font}>{props.name}</div>
            </div>
        </div>
    )
}

export default Texte