import {
  GAMETEXTE_FROM,
  GAMETEXTE_TO,
  GAMETEXTE_FROMTO_VISIBLE,
  GAMETEXTE_CHOICE,
  GAMETEXTE_SELF,
  GAMETEXTE_MULTI,
} from "../actionTypes";
import _ from "lodash";
import configApp from '../../config';
import axios from 'axios';

// mots     =  tata  tete  titi  toto  tutu
// randoms  =   3     2     4     0     1
// position =   0     1     2     3     4
// result   =  toto  titi  tutu  tata  tete

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const getNumber = (max, alreadyNumber) => {
  let chose = Math.floor(Math.random() * (max + 1));
  let found = alreadyNumber.find((x) => x === chose);
  if (found !== undefined && alreadyNumber.length < max + 1) {
    return getNumber(max, alreadyNumber);
  } else {
    return chose;
  }
};

const genChoice = (mots, guess) => {
  if (mots && mots.length > 3) {
    let choices = [guess];
    for (let index = 0; index < 3; index++) {
      let choose = getNumber(mots.length - 1, choices);
      choices.push(choose);
    }
    return shuffle(choices);
  }
  return [];
};

const genereRandomNumber = (type) => async (dispatch, getState) => {
  const { texte, gameTexte } = getState();
  let { mots, loadingBank, bank } = texte;
  let { from, to, self, multi } = gameTexte;
  let alreadyNumber = [];

  for (let index = 0; index < mots.length; index++) {
    let chose = getNumber(mots.length - 1, alreadyNumber);
    alreadyNumber.push(chose);
  }

  //fetch the word to find
  let guess = '';
  if (mots && mots.length > 1 && !_.isEmpty(from)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: from === 'image' ? 'image' : from._id, wordsIds: [mots[alreadyNumber[0]]._id] });
      guess = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[alreadyNumber[0]]._id });
      if (from === 'image') {
        guess = word.image;
      } else {
        let w = _.find(word.names, function (o) { return o.cid === from._id });
        guess = w ? w.t : '---';
      }
    }
  }

  let answer = '';
  if (mots && mots.length > 1 && !_.isEmpty(to)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: [mots[alreadyNumber[0]]._id] });
      answer = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[alreadyNumber[0]]._id });
      let w = _.find(word.names, function (o) { return o.cid === to._id });
      answer = w ? w.t : '---';
    }
  }

  let multiChoices = [];
  if (mots && mots.length > 1 && !_.isEmpty(to)) {
    if (loadingBank) {
      let choices = genChoice(mots, alreadyNumber[0]).map(choice => mots[choice]._id);
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: choices });
      multiChoices = _.shuffle(data);
    } else {
      multiChoices = _.shuffle(genChoice(mots, alreadyNumber[0]).map(choice => mots[choice]._id).map(idMot => {
        let word = _.find(bank, function (o) { return o._id === idMot });
        let t = _.find(word.names, function (o) { return o.cid === to._id });
        return { i: word._id, t: t ? t.t : '---' }
      }));
    }
  }

  if (type === "self") {
    dispatch(
      setSelf({
        randoms: alreadyNumber,
        position: 0,
        solution: false,
        guess: guess,
        answer: answer,
        font: self.font
      })
    );
  } else if (type === "multi") {
    dispatch(
      setMulti({
        randoms: alreadyNumber,
        position: 0,
        solution: false,
        attempt: -1,
        choices: multiChoices,
        guess: guess,
        answer: answer,
        font: multi.font
      })
    );
  }
};

const selectFromTexteIncrement = () => async (dispatch, getState) => {
  const { config, gameTexte } = getState();
  let { from } = gameTexte;
  let { configs } = config;

  let configClean = _.orderBy(_.filter(configs, function (o) { return o.type === "texte" }), ['position'], ['asc']);
  let longueur = configClean.length;
  let pos = _.get(from, 'position', 0);
  pos++;
  if (pos > longueur) {
    pos = 1;
  }
  let newFrom = _.find(configClean, function (o) { return o.position === pos });
  dispatch(selectFromTexte(newFrom));
};

const selectToTexteIncrement = () => async (dispatch, getState) => {
  const { config, gameTexte } = getState();
  let { to } = gameTexte;
  let { configs } = config;

  let configClean = _.orderBy(_.filter(configs, function (o) { return o.type === "texte" }), ['position'], ['asc']);
  let longueur = configClean.length;
  let pos = _.get(to, 'position', 0);
  pos++;
  if (pos > longueur) {
    pos = 1;
  }
  let newTo = _.find(configClean, function (o) { return o.position === pos });
  dispatch(selectToTexte(newTo));
};

const selectFromTexteDecrement = () => async (dispatch, getState) => {
  const { config, gameTexte } = getState();
  let { from } = gameTexte;
  let { configs } = config;

  let configClean = _.orderBy(_.filter(configs, function (o) { return o.type === "texte" }), ['position'], ['asc']);
  let longueur = configClean.length;
  let pos = _.get(from, 'position', 0);
  pos--;
  if (pos < 1) {
    pos = longueur;
  }
  let newFrom = _.find(configClean, function (o) { return o.position === pos });
  dispatch(selectFromTexte(newFrom));
};

const selectToTexteDecrement = () => async (dispatch, getState) => {
  const { config, gameTexte } = getState();
  let { to } = gameTexte;
  let { configs } = config;

  let configClean = _.orderBy(_.filter(configs, function (o) { return o.type === "texte" }), ['position'], ['asc']);
  let longueur = configClean.length;
  let pos = _.get(to, 'position', 0);
  pos--;
  if (pos < 1) {
    pos = longueur;
  }
  let newTo = _.find(configClean, function (o) { return o.position === pos });
  dispatch(selectToTexte(newTo));
};

const selectFromTexte = (from) => async (dispatch, getState) => {
  const { texte, gameTexte } = getState();
  let { mots, loadingBank, bank } = texte;
  let { self, multi } = gameTexte;

  if (mots && mots.length > 1 && !_.isEmpty(from)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: from === 'image' ? 'image' : from._id, wordsIds: [mots[multi.randoms[multi.position]]._id] });
      multi.guess = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[multi.randoms[multi.position]]._id });
      if (from === 'image') {
        multi.guess = word.image
      } else {
        let w = _.find(word.names, function (o) { return o.cid === from._id });
        multi.guess = w ? w.t : '---';
      }
    }
  }
  dispatch({ type: GAMETEXTE_FROM, payload: from });
  dispatch({ type: GAMETEXTE_MULTI, payload: multi });
  dispatch(setSelf(self));
};

const selectToTexte = (to) => async (dispatch, getState) => {
  const { texte, gameTexte } = getState();
  let { mots, loadingBank, bank } = texte;
  let { self, multi } = gameTexte;

  if (mots && mots.length > 1 && !_.isEmpty(to)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: [mots[multi.randoms[multi.position]]._id] });
      multi.answer = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[multi.randoms[multi.position]]._id });
      let w = _.find(word.names, function (o) { return o.cid === to._id });
      multi.answer = w ? w.t : '---';
    }
  }

  if (_.isEmpty(multi.choices)) {
    if (!multi.solution && mots && mots.length > 1 && !_.isEmpty(to)) {
      if (loadingBank) {
        let choices = genChoice(mots, multi.randoms[multi.position]).map(choice => mots[choice]._id);
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: choices });
        multi.choices = _.shuffle(data);
      } else {
        multi.choices = _.shuffle(genChoice(mots, multi.randoms[multi.position]).map(choice => mots[choice]._id).map(idMot => {
          let word = _.find(bank, function (o) { return o._id === idMot });
          let t = _.find(word.names, function (o) { return o.cid === to._id });
          return { i: word._id, t: t ? t.t : '---' }
        }));
      }
    }
  } else {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: multi.choices.map(o => o.i) });
      multi.choices = multi.choices.map(word => _.find(data, function (o) { return o.i === word.i }));
    } else {
      multi.choices = multi.choices.map(word => {
        let wordFound = _.find(bank, function (o) { return o._id === word.i });
        let t = _.find(wordFound.names, function (o) { return o.cid === to._id });
        return { i: wordFound._id, t: t ? t.t : '---' }
      });
    }
  }

  dispatch({ type: GAMETEXTE_TO, payload: to });
  dispatch({ type: GAMETEXTE_MULTI, payload: multi });
  dispatch(setSelf(self));
};

const toogle_from_to_texte = (visible) => async (dispatch, getState) => {
  dispatch({ type: GAMETEXTE_FROMTO_VISIBLE, payload: visible });
};

const chooseGame = (game) => async (dispatch, getState) => {
  dispatch({ type: GAMETEXTE_CHOICE, payload: game });
};

const setSelf = (self) => async (dispatch, getState) => {
  const { texte, gameTexte } = getState();
  let { mots, loadingBank, bank } = texte;
  let { from, to } = gameTexte;
  if (mots && mots.length > 1 && !_.isEmpty(from)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: from === 'image' ? 'image' : from._id, wordsIds: [mots[self.randoms[self.position]]._id] });
      self.guess = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[self.randoms[self.position]]._id });
      if (from === 'image') {
        self.guess = word.image
      } else {
        let w = _.find(word.names, function (o) { return o.cid === from._id });
        self.guess = w ? w.t : '---';
      }
    }
  }
  if (mots && mots.length > 1 && !_.isEmpty(to)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: [mots[self.randoms[self.position]]._id] });
      self.answer = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[self.randoms[self.position]]._id });
      let w = _.find(word.names, function (o) { return o.cid === to._id });
      self.answer = w ? w.t : '---';
    }
  }

  dispatch({ type: GAMETEXTE_SELF, payload: self });
};

const setAttempt = (multi) => async (dispatch, getState) => {
  dispatch({ type: GAMETEXTE_MULTI, payload: multi });
};

const setMulti = (multi) => async (dispatch, getState) => {
  const { texte, gameTexte } = getState();
  let { mots, loadingBank, bank } = texte;
  let { from, to } = gameTexte;
  if (mots && mots.length > 1 && !_.isEmpty(from)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: from === 'image' ? 'image' : from._id, wordsIds: [mots[multi.randoms[multi.position]]._id] });
      multi.guess = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[multi.randoms[multi.position]]._id });
      if (from === 'image') {
        multi.guess = word.image
      } else {
        let w = _.find(word.names, function (o) { return o.cid === from._id });
        multi.guess = w ? w.t : '---';
      }
    }
  }

  if (mots && mots.length > 1 && !_.isEmpty(to)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: [mots[multi.randoms[multi.position]]._id] });
      multi.answer = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[multi.randoms[multi.position]]._id });
      let w = _.find(word.names, function (o) { return o.cid === to._id })
      multi.answer = w ? w.t : '---';
    }
  }

  if (!multi.solution) {
    multi.choices = [];
    if (!multi.solution && mots && mots.length > 1 && !_.isEmpty(to)) {
      if (loadingBank) {
        let choices = genChoice(mots, multi.randoms[multi.position]).map(choice => mots[choice]._id);
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: choices });
        multi.choices = _.shuffle(data);
      } else {
        multi.choices = _.shuffle(genChoice(mots, multi.randoms[multi.position]).map(choice => mots[choice]._id).map(idMot => {
          let word = _.find(bank, function (o) { return o._id === idMot });
          let t = _.find(word.names, function (o) { return o.cid === to._id });
          return { i: word._id, t: t ? t.t : '---' }
        }));
      }
    }
  }
  dispatch({ type: GAMETEXTE_MULTI, payload: multi });
};

const setGame = () => async (dispatch, getState) => {
  const { texte, gameTexte } = getState();
  let { mots, loadingBank, bank } = texte;
  let { self, multi, from, to } = gameTexte;

  let alreadyNumber = [];
  for (let index = 0; index < mots.length; index++) {
    let chose = getNumber(mots.length - 1, alreadyNumber);
    alreadyNumber.push(chose);
  }

  //fetch the word to find
  let guess = '';
  if (mots && mots.length > 1 && !_.isEmpty(from)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: from === 'image' ? 'image' : from._id, wordsIds: [mots[alreadyNumber[0]]._id] });
      guess = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[alreadyNumber[0]]._id });
      if (from === 'image') {
        guess = word.image;
      } else {
        let w = _.find(word.names, function (o) { return o.cid === from._id });
        guess = w ? w.t : '---';
      }
    }
  }

  let answer = '';
  if (mots && mots.length > 1 && !_.isEmpty(to)) {
    if (loadingBank) {
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: [mots[alreadyNumber[0]]._id] });
      answer = _.head(data) ? _.head(data).t : '---';
    } else {
      let word = _.find(bank, function (o) { return o._id === mots[alreadyNumber[0]]._id });
      let w = _.find(word.names, function (o) { return o.cid === to._id })
      answer = w ? w.t : '---';
    }
  }

  self.randoms = alreadyNumber;
  self.solution = false;
  self.position = 0;
  self.guess = guess;
  self.answer = answer;

  multi.randoms = alreadyNumber;
  multi.solution = false;
  multi.position = 0;
  multi.attempt = -1;
  multi.guess = guess;
  multi.answer = answer;

  //generate list of choice
  multi.choices = [];
  if (!multi.solution && mots && mots.length > 1 && !_.isEmpty(to)) {
    if (loadingBank) {
      let choices = genChoice(mots, alreadyNumber[0]).map(choice => mots[choice]._id);
      const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/game/", { configId: to._id, wordsIds: choices });
      multi.choices = _.shuffle(data);
    } else {
      multi.choices = _.shuffle(genChoice(mots, alreadyNumber[0]).map(choice => mots[choice]._id).map(idMot => {
        let word = _.find(bank, function (o) { return o._id === idMot });
        let t = _.find(word.names, function (o) { return o.cid === to._id });
        return { i: word._id, t: t ? t.t : '---' }
      }));
    }
  }

  dispatch({ type: GAMETEXTE_SELF, payload: self });
  dispatch({ type: GAMETEXTE_MULTI, payload: multi });
};

export {
  genereRandomNumber,
  getNumber,
  selectFromTexte,
  selectToTexte,
  toogle_from_to_texte,
  chooseGame,
  setSelf,
  setMulti,
  setGame,
  setAttempt,
  selectFromTexteIncrement,
  selectFromTexteDecrement,
  selectToTexteDecrement,
  selectToTexteIncrement
};
