import React from "react";
import { useTranslation } from "react-i18next";

const GameInfo = (props) => {
  const { t } = useTranslation();

  return (
    <div className="flashcardwrite__info">
      <span>
        {props.lecons} {t("lecons")}
      </span>
      <span>
        {props.mots} {t("words")}
      </span>
      <span>
        {props.position + 1 } {t("words")}
      </span>
    </div>
  );
};

export default GameInfo;
