import {
  USER_SIGNOUT,
  GAME_FROM,
  GAME_TO,
  GAME_FROMTO_VISIBLE,
  GAME_CHOICE,
  GAME_SELF,
  GAME_MULTI,
  GAME_WRITE,
  GAME_ONLYFOCUS
} from "../actionTypes";

function gameReducers(
  state = {
    from: {},
    to: {},
    visible_from_to: true,
    game_choice: "self",
    onlyFocus: false,
    self: {
      font: "no",
      randoms: [],
      solution: false,
      position: 0,
      guess: '',
      answer: ''
    },
    multi: {
      font: "no",
      randoms: [],
      solution: false,
      position: 0,
      attempt: -1,
      choices: [],
      guess: '',
      answer: ''
    },
    write: {
      font: "no",
      randoms: [],
      solution: false,
      position: 0,
      guess: '',
      answer: ''
    },
  },
  action
) {
  switch (action.type) {
    case USER_SIGNOUT:
      return {
        ...state,
        from: {},
        to: {},
        onlyFocus: false,
        self: {
          font: "no",
          randoms: [],
          solution: false,
          position: 0,
          guess: '',
          answer: ''
        },
        multi: {
          font: "no",
          randoms: [],
          solution: false,
          position: 0,
          attempt: -1,
          choices: [],
          guess: '',
          answer: ''
        },
        write: {
          font: "no",
          randoms: [],
          solution: false,
          position: 0,
          guess: '',
          answer: ''
        },
      };
    case GAME_FROM:
      return { ...state, from: action.payload };
    case GAME_TO:
      return { ...state, to: action.payload };
    case GAME_FROMTO_VISIBLE:
      return { ...state, visible_from_to: action.payload };
    case GAME_CHOICE:
      return { ...state, game_choice: action.payload };
    case GAME_SELF:
      return { ...state, self: action.payload };
    case GAME_MULTI:
      return { ...state, multi: action.payload };
    case GAME_WRITE:
      return { ...state, write: action.payload };
    case GAME_ONLYFOCUS:
      return { ...state, onlyFocus: action.payload };
    default:
      return state;
  }
}

export { gameReducers };
