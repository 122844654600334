import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { exportLecon } from '../../redux/actions/leconActions';
import { usePopup } from './PopupContext';
import { useAuth } from '../use-auth';

const PopupLecon = props => {

    const dispatch = useDispatch();
    const popup = usePopup();
    const auth = useAuth();
    const { t } = useTranslation();

    const [name, setName] = useState(props.lecon.name);
    const [notes, setNotes] = useState(props.lecon.notes);

    const save = (event) => {
        event.preventDefault();
        props.handleSave(name, notes);
        props.handleClose();
    }

    const exportLeconUI = () => {
        dispatch(exportLecon(props.lecon._id , auth.user.email )).then(ok => popup.open(t("emailsent"))).catch(err => popup.open(err));
    }

    return (
        <div className="popup__lecon"  >

            <form onSubmit={save} className="popup__lecon__form">

                <div className="popup__lecon__container">
                    <label id="icon" className="popup__lecon__label" htmlFor="name"><i className="icon-user"></i>{t("leconname")}*</label>
                    <input type="text" name="name" id="name" className="popup__lecon__input" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />

                    <label id="icon" className="popup__lecon__label" htmlFor="create"><i className="icon-user"></i>{t("createdate")}</label>
                    <input type="text" name="create" id="create" value={moment(props.lecon.create).format('DD/MM/YY')} readOnly className="popup__lecon__input" />

                    <label id="icon" className="popup__lecon__label" htmlFor="last"><i className="icon-user"></i>{t("lastaccess")}</label>
                    <input type="text" name="last" id="last" value={moment(props.lecon.last).format('DD/MM/YY')} readOnly className="popup__lecon__input" />

                    <label id="icon" className="popup__lecon__label" htmlFor="mot"><i className="icon-user"></i>{t("words")}</label>
                    <input type="number" name="mot" id="mot" value={props.lecon.mots} readOnly className="popup__lecon__input" />

                    <label id="icon" className="popup__lecon__label" htmlFor="notes"><i className="icon-user"></i>{t("notes")}</label>
                    <textarea type="text" name="notes" id="notes" value={notes} className="popup__lecon__textarea" onChange={(e) => setNotes(e.target.value)} />

                </div>
                <div className="popup__lecon__container--button">
                    <button type="button"
                        className="popup__lecon__button"
                        onClick={props.handleClose}>
                        {t("cancel")}
                    </button>
                    <button type="submit"
                        className="popup__lecon__button"
                    >
                        {t("save")}
                    </button>
                    <button type="button"
                        className="popup__lecon__button"
                        onClick={exportLeconUI}>
                        {t("export")}
                    </button>
                </div>

            </form>

        </div>
    );
};

export default PopupLecon;