import axios from 'axios';
import {
    MOTS_FAIL, MOTS_REQUEST, MOTS_SUCCESS, MOTS_REMOVE_ITEM,
    MOT_LECON_SELECTED, MOT_LECON_UNSELECTED, MOT_SELECTED, MOT_UNSELECTED,
    MOT_SELECTED_UPDATE, MOT_SAVE, MOT_RESET,
    MOT_CONFIG_SELECTED, LECON_CREATE_WORD, LECON_REMOVE_WORD, LECON_MOT_UPDATE, MOT_LECON_SELECTED_BY_MOT,
    MOTS_BANK_REQUEST, MOTS_BANK_SUCCESS, MOTS_BANK_CLEAN, MOT_UPDATE_BANK_WORD, MOT_CREATE_BANK_WORD, MOT_PRINT_LIST, MOTS_FOCUS, GAME_ONLYFOCUS
} from '../actionTypes';
import configApp from '../../config';
import _ from 'lodash';
import { setGame } from './gameActions';
import { toggle_onlyFocus } from './leconActions';

//get the list of words to refresh ( when change the configuration)
const listMots = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MOTS_REQUEST });

        const { mot } = getState();
        const { lecons, configSelected, mots, loadingBank, bank } = mot;

        if (lecons && lecons.length !== 0) {
            if (loadingBank) {
                let lessons = [...lecons];
                const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", { configId: configSelected._id, lessons });
                dispatch({
                    type: MOTS_SUCCESS, payload: data
                });
            } else {
                dispatch({
                    type: MOTS_SUCCESS, payload: mots.map(mot => {
                        let motFromBank = _.find(bank, function (o) { return o._id === mot._id });
                        const foundConfig = _.find(motFromBank.names, function (c) { return c.cid === configSelected._id });
                        return {
                            '_id': mot._id, 'focus': mot.focus, 'name': foundConfig ? foundConfig.t : "---", 'lesson': mot.lesson
                        }
                    })
                });
            }
        } else {
            dispatch({ type: MOTS_SUCCESS, payload: [] });
        }
    } catch (error) {
        dispatch({ type: MOTS_FAIL, payload: error.message });
    }
}

const listFocusMots = () => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        const { configSelected, focus } = mot;

        dispatch({
            type: MOTS_FOCUS, payload: focus.map(mot => {
                const foundConfig = _.find(mot.names, function (c) { return c.cid === configSelected._id });
                return {
                    ...mot, 'name': foundConfig ? foundConfig.t : "---"
                }
            })
        });
    } catch (error) {
        dispatch({ type: MOTS_FAIL, payload: error.message });
    }
}


const deleteMot = () => async (dispatch, getState) => {
    try {
        const { mot } = getState();

        let { motSelected } = mot;
        if (motSelected._id) {
            const data = await axios.delete(configApp.URL_BACKEND + "/api/word/" + motSelected._id);
            dispatch({ type: MOTS_REMOVE_ITEM, payload: motSelected._id });
            dispatch({ type: LECON_REMOVE_WORD, payload: motSelected.lessun });
        } else {
            dispatch({ type: MOTS_REMOVE_ITEM, payload: "-1" });
        }

    } catch (error) {
        throw error;
    }
}

const selectLeconWord = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: MOTS_REQUEST });
        dispatch({ type: MOTS_BANK_REQUEST });

        const { mot, game } = getState();
        const { configSelected } = mot;
        const { onlyFocus } = game;

        if (onlyFocus) {
            dispatch(toggle_onlyFocus("word", false));
        }

        let lessons = [_id];
        //load all the word
        axios.post(configApp.URL_BACKEND + "/api/word/lessun/bank", { lessons }).then(({ data }) => dispatch({ type: MOTS_BANK_SUCCESS, payload: data }));

        const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", { configId: configSelected._id, lessons });

        dispatch({ type: MOT_LECON_SELECTED, payload: { _id, 'data': data } });
        dispatch(setGame());
    } catch (error) {
        throw error;
    }
}

const selectLeconWordByWord = (word, type) => async (dispatch, getState) => {
    try {
        const ids = await axios.get(configApp.URL_BACKEND + "/api/lecon/word/" + word + "&" + type);

        const { mot, game } = getState();
        const { configSelected } = mot;        
        const { onlyFocus } = game;
        
        if (onlyFocus) {
            dispatch(toggle_onlyFocus("word", false));
        }
        let lessons = ids.data;

        //load all the word
        axios.post(configApp.URL_BACKEND + "/api/word/lessun/bank", { lessons }).then(({ data }) => dispatch({ type: MOTS_BANK_SUCCESS, payload: data }));

        const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", { configId: configSelected._id, lessons });
        dispatch({ type: MOT_LECON_SELECTED_BY_MOT, payload: { ids: ids.data, data } });
        dispatch({ type: GAME_ONLYFOCUS, payload: false });

        dispatch(setGame());
    } catch (error) {
        throw error;
    }
}

const unselectLeconWord = (_id) => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        const { lecons, mots, bank } = mot;
        const lessons = lecons.filter(x => x !== _id);

        dispatch({ type: MOT_LECON_UNSELECTED, payload: { _id, data: mots.filter(x => x.lesson !== _id) } });
        dispatch({ type: MOTS_BANK_CLEAN, payload: bank.filter(x => x.lesson !== _id) });

        dispatch(setGame());

        if (lessons.length === 0) {
            dispatch(unselectMot());
        };

        const { motSelected } = mot;
        if (motSelected && motSelected._id) {
            const motList = motSelected.lessun;
            let found = [];
            _.each(motList, function (id) {
                found.push(_.find(lessons, function (o) { return o === id }));
            });
            const res = _.filter(found, function (o) { return o !== undefined });
            if (res.length === 0) {
                dispatch(unselectMot());
            }
        }

        dispatch({ type: MOT_PRINT_LIST, payload: [] });

    } catch (error) {
        throw error;
    }
}

const selectMot = (_id) => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        const { bank, loadingBank } = mot;
        if (loadingBank) {
            const { data } = await axios.get(configApp.URL_BACKEND + "/api/word/" + _id);
            dispatch({ type: MOT_SELECTED, payload: data });
        } else {
            let word = _.find(bank, function (o) { return o._id === _id });
            dispatch({ type: MOT_SELECTED, payload: { _id: word._id, focus: word.focus, image: word.image, lessun: [word.lesson], mot: word.names.map(o => { return { config: o.cid, translate: o.t } }) } });
        }
    } catch (error) {
        throw error;
    }
}

const unselectMot = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MOT_UNSELECTED, payload: null });
    } catch (error) {
        throw error;
    }
}

const addMotPrint = (_id) => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        const { motToPrints } = mot;
        dispatch({ type: MOT_PRINT_LIST, payload: [...motToPrints, _id] });
    } catch (error) {
        throw error;
    }
}

const removeMotPrint = (_id) => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        const { motToPrints } = mot;
        dispatch({ type: MOT_PRINT_LIST, payload: motToPrints.filter(x => x !== _id) });
    } catch (error) {
        throw error;
    }
}


const resetMot = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MOT_RESET, payload: {} });
    } catch (error) {
        throw error;
    }
}

const updateSelectedMot = (value, configId) => async (dispatch, getState) => {
    try {
        const { mot } = getState();

        let { motSelected } = mot;
        if (motSelected && motSelected.mot) {
            let found = motSelected.mot.find(x => x.config === configId);
            if (found) {
                motSelected.mot = motSelected.mot.map(x => x.config === configId ? { translate: value, config: configId } : x);
            } else {
                motSelected.mot.push({ translate: value, config: configId });
            }
        } else {
            motSelected = {
                mot: [{ translate: value, config: configId }]
            };
        }

        dispatch({ type: MOT_SELECTED_UPDATE, payload: motSelected });
    } catch (error) {
        throw error;
    }
}

const updateSelectedMotImage = (image) => async (dispatch, getState) => {
    try {
        const { mot } = getState();

        let { motSelected } = mot;
        if (motSelected) {
            motSelected.image = image;
        }

        dispatch({ type: MOT_SELECTED_UPDATE, payload: motSelected });
    } catch (error) {
        throw error;
    }
}

const saveMot = () => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        let { motSelected, lecons, configSelected } = mot;

        if (motSelected._id) {
            let res = await axios.put(configApp.URL_BACKEND + "/api/word/" + motSelected._id, motSelected);
            dispatch({ type: MOT_SAVE, payload: motSelected });
            dispatch({ type: LECON_MOT_UPDATE, payload: motSelected.lessun });
            for (let index = 0; index < lecons.length; index++) {
                let wordBank = { _id: motSelected._id, image: motSelected.image, names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }), lesson: lecons[index] };
                if (_.isEmpty(configSelected)) {
                    dispatch({ type: MOT_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, image: motSelected.image, name: _.head(motSelected.mot).translate, lesson: lecons[index] } } });
                } else {
                    dispatch({ type: MOT_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, image: motSelected.image, name: _.head(motSelected.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), lesson: lecons[index] } } });
                }
            }
        } else {
            motSelected.lessun = lecons;
            let { data } = await axios.post(configApp.URL_BACKEND + "/api/word/", motSelected);
            dispatch({ type: MOT_SAVE, payload: data });
            dispatch({ type: LECON_CREATE_WORD, payload: lecons });
            for (let index = 0; index < lecons.length; index++) {
                let wordBank = { _id: data._id, image: data.image, names: data.mot.map(o => { return { 'cid': o.config, 't': o.translate } }), lesson: lecons[index] };
                if (_.isEmpty(configSelected)) {
                    dispatch({ type: MOT_CREATE_BANK_WORD, payload: { wordBank, mot: { _id: data._id, image: data.image, name: _.head(data.mot).translate, lesson: lecons[index] } } });
                } else {
                    dispatch({ type: MOT_CREATE_BANK_WORD, payload: { wordBank, mot: { _id: data._id, image: data.image, name: _.head(data.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), lesson: lecons[index] } } });
                }
            }
        }
        dispatch(setGame());
    } catch (error) {
        throw error;
    }
}

const focusMot = (_id) => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        let { lecons, configSelected, focus } = mot;

        if (_id) {
            // let { data } = await axios.put(configApp.URL_BACKEND + "/api/word/focus/" + _id, { _id: _id });
            // let motSelected = data;
            // for (let index = 0; index < lecons.length; index++) {
            //     let wordBank = { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }), lesson: lecons[index] };
            //     if (_.isEmpty(configSelected)) {
            //         dispatch({ type: MOT_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot).translate, lesson: lecons[index] } } });
            //     } else {
            //         dispatch({ type: MOT_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), lesson: lecons[index] } } });
            //     }
            // }
            axios.put(configApp.URL_BACKEND + "/api/word/focus/" + _id, { _id: _id }).then(result => {
                let { data } = result;
                let motSelected = data;
                for (let index = 0; index < lecons.length; index++) {
                    let wordBank = { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }), lesson: lecons[index] };
                    if (_.isEmpty(configSelected)) {
                        dispatch({ type: MOT_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot).translate, lesson: lecons[index] } } });
                    } else {
                        dispatch({ type: MOT_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), lesson: lecons[index] } } });
                    }
                }

                if (motSelected.focus === '1') {
                    if (_.isEmpty(configSelected)) {
                        let wordBank = { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot).translate, names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }) };
                        dispatch({ type: MOTS_FOCUS, payload: [...focus, wordBank] });
                    } else {
                        let wordBank = { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }) };
                        dispatch({ type: MOTS_FOCUS, payload: [...focus, wordBank] });
                    }

                } else {
                    dispatch({ type: MOTS_FOCUS, payload: focus.filter(o => o._id !== motSelected._id) });
                }
            }
            );
        }
    } catch (error) {
        throw error;
    }
}

const selectConfig = (configId) => async (dispatch, getState) => {
    try {
        const { config } = getState();
        let { configs } = config;
        let found = configs.find(x => x._id === configId);

        dispatch({ type: MOT_CONFIG_SELECTED, payload: found });

        dispatch(listMots());
        dispatch(listFocusMots());

    } catch (error) {
        throw error;
    }
}

const listFocusWord = (_id) => async (dispatch, getState) => {
    try {
        //load all the word
        axios.post(configApp.URL_BACKEND + "/api/word/focus", { _id, type: 'word' }).then(({ data }) => dispatch({ type: MOTS_FOCUS, payload: data }));
    } catch (error) {
        throw error;
    }
}

const selectFocusMot = (_id) => async (dispatch, getState) => {
    try {
        const { mot } = getState();
        const { focus } = mot;
        let word = _.find(focus, function (o) { return o._id === _id });
        dispatch({ type: MOT_SELECTED, payload: { _id: word._id, focus: word.focus, image: word.image, lessun: [word.lesson], mot: word.names.map(o => { return { config: o.cid, translate: o.t } }) } });
    } catch (error) {
        throw error;
    }
}


export {
    listFocusWord, selectFocusMot,
    listMots, deleteMot, selectLeconWord,
    unselectLeconWord, selectMot, updateSelectedMot,
    saveMot, resetMot, selectConfig, unselectMot,
    selectLeconWordByWord, updateSelectedMotImage, addMotPrint, removeMotPrint,
    focusMot
};