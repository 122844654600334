import React, { useEffect, useState } from "react";

function Policy() {

  const [allowVideo, setAllowVideo] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  useEffect(() => {
    setAllowVideo(true);
  }, []);

  const videoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <section className="policy">
      <div className="policy__container">

        <div id='top' className="doc__index">
          <div className="doc__short">
            <span className="material-icons md-24">policy</span>
            <a href="#privacy">
              <h1>Politique de confidentialité pour memodeep.com.</h1>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">copyright</span>
            <a href="#term">
              <h1>Conditions générales.</h1>
            </a>
          </div>
        </div>

        <h1>Politique de confidentialité pour memodeep.com</h1>

        <p>Chez memodeep.com, accessible depuis memodeep.com, l'une de nos principales priorités est la confidentialité de nos visiteurs. Ce document de politique de confidentialité contient des types d'informations qui sont collectées et enregistrées par memodeep.com et comment nous les utilisons.</p>

        <p>Si vous avez des questions supplémentaires ou souhaitez plus d'informations sur notre politique de confidentialité, n'hésitez pas à nous contacter.</p>

        <p>Cette politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site Web en ce qui concerne les informations qu'ils ont partagées et/ou collectées sur memodeep.com. Cette politique ne s'applique pas aux informations collectées hors ligne ou via des canaux autres que ce site Web. Notre politique de confidentialité a été créée avec l'aide du
          <a href="https://h-supertools.com/web/privacy-policy-generator/">Générateur de politique de confidentialité H-supertools</a>.</p>

        <h2>Consentement</h2>

        <p>En utilisant notre site Web, vous consentez par la présente à notre politique de confidentialité et acceptez ses conditions.</p>

        <h2>Informations que nous collectons</h2>

        <p>Les informations personnelles que vous êtes invité à fournir, et les raisons pour lesquelles vous êtes invité à les fournir, vous seront clairement indiquées au moment où nous vous demanderons de fournir vos informations personnelles.</p>
        <p>Si vous nous contactez directement, nous pouvons recevoir des informations supplémentaires vous concernant telles que votre nom, votre adresse e-mail, votre numéro de téléphone, le contenu du message et/ou des pièces jointes que vous pouvez nous envoyer, et toute autre information que vous pouvez choisir de fournir .</p>
        <p>Lorsque vous créez un compte, nous pouvons vous demander vos coordonnées, y compris des éléments tels que le nom, le nom de l'entreprise, l'adresse, l'adresse e-mail et le numéro de téléphone.</p>

        <h2>Comment nous utilisons vos informations</h2>

        <p>Nous utilisons les informations que nous collectons de diverses manières, notamment pour :</p>

        <ul>
          <li>Fournir, exploiter et maintenir notre site Web</li>
          <li>Améliorer, personnaliser et développer notre site Web</li>
          <li>Comprendre et analyser comment vous utilisez notre site Web</li>
          <li>Développer de nouveaux produits, services, fonctionnalités et fonctionnalités</li>
          <li>Communiquer avec vous, directement ou via l'un de nos partenaires, y compris pour le service client, pour vous fournir des mises à jour et d'autres informations relatives au site Web, et à des fins de marketing et de promotion</li>
          <li>Vous envoyer des e-mails</li>
          <li>Trouver et prévenir la fraude</li>
        </ul>

        <h2>Fichiers journaux</h2>

        <p>memodeep.com suit une procédure standard d'utilisation des fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent des sites Web. Toutes les sociétés d'hébergement le font et font partie de l'analyse des services d'hébergement. Les informations collectées par les fichiers journaux incluent les adresses de protocole Internet (IP), le type de navigateur, le fournisseur d'accès Internet (FAI), la date et l'heure, les pages de renvoi/de sortie et éventuellement le nombre de clics. Ceux-ci ne sont liés à aucune information personnellement identifiable. Le but des informations est d'analyser les tendances, d'administrer le site, de suivre les mouvements des utilisateurs sur le site Web et de recueillir des informations démographiques.</p>

        <h2>Cookies et balises Web</h2>

        <p>Comme tout autre site Web, memodeep.com utilise des « cookies ». Ces cookies sont utilisés pour stocker des informations, notamment les préférences des visiteurs et les pages du site Web auxquelles le visiteur a accédé ou visité. Les informations sont utilisées pour optimiser l'expérience des utilisateurs en personnalisant le contenu de notre page Web en fonction du type de navigateur des visiteurs et/ou d'autres informations.</p>

        <h2>Cookie Google DoubleClick DART</h2>

        <p>Google est l'un des fournisseurs tiers de notre site. Il utilise également des cookies, appelés cookies DART, pour diffuser des annonces aux visiteurs de notre site en fonction de leur visite sur www.website.com et d'autres sites sur Internet. Cependant, les visiteurs peuvent choisir de refuser l'utilisation des cookies DART en visitant la politique de confidentialité du réseau de publicité et de contenu Google à l'URL suivante : <a href="https://policies.google.com/technologies/ads">https :/ /policies.google.com/technologies/ads</a></p>

        <h2>Nos partenaires publicitaires</h2>

        <p>Certains annonceurs de notre site peuvent utiliser des cookies et des balises Web. Nos partenaires publicitaires sont listés ci-dessous. Chacun de nos partenaires publicitaires a sa propre politique de confidentialité pour ses politiques sur les données des utilisateurs. Pour un accès plus facile, nous avons créé un lien hypertexte vers leurs politiques de confidentialité ci-dessous.</p>

        <ul>
          <li>
            <p>Google</p>
            <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
          </li>
        </ul>

        <h2>Politiques de confidentialité des partenaires publicitaires</h2>

        <p>Vous pouvez consulter cette liste pour trouver la politique de confidentialité de chacun des partenaires publicitaires de memodeep.com.</p>

        <p>Les serveurs publicitaires ou réseaux publicitaires tiers utilisent des technologies telles que les cookies, JavaScript ou les balises Web qui sont utilisées dans leurs publicités et liens respectifs qui apparaissent sur memodeep.com, qui sont envoyés directement au navigateur des utilisateurs. Ils reçoivent automatiquement votre adresse IP lorsque cela se produit. Ces technologies sont utilisées pour mesurer l'efficacité de leurs campagnes publicitaires et/ou pour personnaliser le contenu publicitaire que vous voyez sur les sites Web que vous visitez.</p>

        <p>Notez que memodeep.com n'a pas accès ou contrôler ces cookies qui sont utilisés par des annonceurs tiers.</p>

        <h2>Politiques de confidentialité des tiers</h2>

        <p>La politique de confidentialité de memodeep.com ne s'applique pas aux autres annonceurs ou sites Web. Ainsi, nous vous conseillons de consulter les Politiques de confidentialité respectives de ces serveurs publicitaires tiers pour des informations plus détaillées. Il peut inclure leurs pratiques et des instructions sur la façon de se retirer de certaines options. </p>

        <p>Vous pouvez choisir de désactiver les cookies via les options de votre navigateur. Pour en savoir plus sur la gestion des cookies avec des navigateurs Web spécifiques, vous pouvez les trouver sur les sites Web respectifs des navigateurs.</p>

        <h2>Droits de confidentialité du CCPA (Ne pas vendre mes informations personnelles)</h2>

        <p>En vertu du CCPA, entre autres droits, les consommateurs californiens ont le droit de :</p>
        <p>Demandez qu'une entreprise qui collecte les données personnelles d'un consommateur divulgue les catégories et les éléments spécifiques de données personnelles qu'une entreprise a collectées sur les consommateurs.</p>
        <p>Demander à une entreprise de supprimer toutes les données personnelles sur le consommateur qu'une entreprise a collectées.</p>
        <p>Demandez à une entreprise qui vend les données personnelles d'un consommateur de ne pas vendre les données personnelles du consommateur.</p>
        <p>Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter.</p>

        <h2>Droits de protection des données GDPR</h2>

        <p>Nous souhaitons nous assurer que vous êtes pleinement conscient de tous vos droits en matière de protection des données. Chaque utilisateur a droit aux éléments suivants :</p>
        <p>Le droit d'accès – Vous avez le droit de demander des copies de vos données personnelles. Nous pouvons vous facturer une somme modique pour ce service.</p>
        <p>Le droit de rectification – Vous avez le droit de demander que nous corrigions toute information que vous jugez inexacte. Vous avez également le droit de nous demander de compléter les informations que vous jugez incomplètes.</p>
        <p>Le droit d'effacement - Vous avez le droit de demander que nous effacions vos données personnelles, sous certaines conditions.</p>
        <p>Le droit de restreindre le traitement – ​​Vous avez le droit de demander que nous restreignions le traitement de vos données personnelles, sous certaines conditions.</p>
        <p>Le droit de vous opposer au traitement – ​​Vous avez le droit de vous opposer au traitement de vos données personnelles, sous certaines conditions.</p>
        <p>Le droit à la portabilité des données - Vous avez le droit de demander que nous transférions les données que nous avons collectées à une autre organisation, ou directement à vous, sous certaines conditions.</p>
        <p>Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter.</p>

        <h2>Informations sur les enfants</h2>

        <p>Une autre partie de notre priorité consiste à protéger les enfants lorsqu'ils utilisent Internet. Nous encourageons les parents et tuteurs à observer, participer et/ou surveiller et guider leur activité en ligne.</p>

        <p>memodeep.com ne collecte sciemment aucune information personnelle identifiable d'enfants de moins de 13 ans. Si vous pensez que votre enfant a fourni ce type d'informations sur notre site Web, nous vous encourageons fortement à nous contacter immédiatement et nous ferons notre tous les efforts pour supprimer rapidement ces informations de nos dossiers.</p>

        <br />
        <hr />
        <h2 id="term" ><strong>Conditions générales</strong></h2>

        <p>Bienvenue sur memodeep.com !</p>

        <p>Ces termes et conditions décrivent les règles et règlements pour l'utilisation du site Web de memodeep.com, situé à memodeep.com.</p>

        <p>En accédant à ce site Web, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser memodeep.com si vous n'acceptez pas tous les termes et conditions énoncés sur cette page.</p>

        <p>La terminologie suivante s'applique aux présentes conditions générales, à la déclaration de confidentialité et à l'avis de non-responsabilité et à tous les accords : « Client », « Vous » et « Votre » se réfèrent à vous, la personne qui se connecte sur ce site Web et est conforme aux conditions générales de la Société. conditions. « La Société », « Nous-mêmes », « Nous », « Notre » et « Nous », fait référence à notre Société. « Partie », « Parties » ou « Nous » désigne à la fois le Client et nous-mêmes. Tous les termes se réfèrent à l'offre, l'acceptation et la prise en compte du paiement nécessaire pour entreprendre le processus de notre assistance au client de la manière la plus appropriée dans le but exprès de répondre aux besoins du client en ce qui concerne la fourniture des services indiqués de la société, conformément à et sous réserve de la loi en vigueur aux Pays-Bas. Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, en majuscules et/ou il/elle ou ils sont considérés comme interchangeables et donc comme faisant référence à ceux-ci.</p>

        <h2><strong>Cookies</strong></h2>

        <p>Nous utilisons des cookies. En accédant à memodeep.com, vous avez accepté d'utiliser des cookies en accord avec la politique de confidentialité de memodeep.com. </p>

        <p>La plupart des sites Web interactifs utilisent des cookies pour nous permettre de récupérer les détails de l'utilisateur à chaque visite. Les cookies sont utilisés par notre site Web pour activer la fonctionnalité de certaines zones afin de faciliter la visite des personnes qui visitent notre site Web. Certains de nos partenaires affiliés/publicitaires peuvent également utiliser des cookies.</p>

        <h2><strong>Licence</strong></h2>

        <p>Sauf indication contraire, memodeep.com et/ou ses concédants de licence détiennent les droits de propriété intellectuelle pour tout le matériel sur memodeep.com. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez y accéder depuis memodeep.com pour votre usage personnel, sous réserve des restrictions définies dans les présentes conditions générales.</p>

        <p>Vous ne devez pas :</p>
        <ul>
          <li>Republier le matériel de memodeep.com</li>
          <li>Vendre, louer ou sous-licencier du matériel de memodeep.com</li>
          <li>Reproduire, dupliquer ou copier du matériel de memodeep.com</li>
          <li>Redistribuer le contenu de memodeep.com</li>
        </ul>

        <p>Le présent accord commence à la date des présentes. Nos conditions générales ont été créées à l'aide du <a href="https://h-supertools.com/web/terms-and-conditions-generator/">générateur de conditions générales H-supertools</a>. </p>

        <p> Certaines parties de ce site Web offrent aux utilisateurs la possibilité de publier et d'échanger des opinions et des informations dans certaines zones du site Web. memodeep.com ne filtre pas, ne modifie pas, ne publie ni ne révise les Commentaires avant leur présence sur le site Web. Les commentaires ne reflètent pas les vues et opinions de memodeep.com, de ses agents et/ou affiliés. Les commentaires reflètent les points de vue et opinions de la personne qui publie ses points de vue et opinions. Dans la mesure permise par les lois applicables, memodeep.com ne sera pas responsable des Commentaires ou de toute responsabilité, dommages ou dépenses causés et/ou subis à la suite de toute utilisation et/ou publication et/ou apparition des Commentaires. sur ce site.</p>

        <p>memodeep.com se réserve le droit de surveiller tous les commentaires et de supprimer tout commentaire pouvant être considéré comme inapproprié, offensant ou entraînant une violation des présentes conditions générales.</p>

        <p>Vous garantissez et déclarez que :</p>

        <ul>
          <li>Vous avez le droit de publier des commentaires sur notre site Web et disposez de toutes les licences et autorisations nécessaires pour le faire ;</li>
          <li>Les commentaires n'enfreignent aucun droit de propriété intellectuelle, y compris, sans s'y limiter, les droits d'auteur, les brevets ou les marques de commerce de tiers ;</li>
          <li>Les commentaires ne contiennent aucun élément diffamatoire, calomnieux, offensant, indécent ou autrement illégal qui constitue une atteinte à la vie privée</li>
          <li>Les commentaires ne seront pas utilisés pour solliciter ou promouvoir des activités commerciales ou personnalisées ou présenter des activités commerciales ou des activités illégales.</li>
        </ul>

        <p>Vous accordez par la présente à memodeep.com une licence non exclusive pour utiliser, reproduire, éditer et autoriser des tiers à utiliser, reproduire et éditer l'un de vos commentaires sous toutes les formes, formats ou supports.</p>

        <h2><strong>Lien hypertexte vers notre contenu</strong></h2>

        <p>Les organisations suivantes peuvent créer un lien vers notre site Web sans autorisation écrite préalable :</p>

        <ul>
          <li>Agences gouvernementales ;</li>
          <li>Moteurs de recherche ;</li>
          <li>Organismes de presse ;</li>
          <li>Les distributeurs d'annuaires en ligne peuvent créer un lien vers notre site Web de la même manière qu'ils créent un lien hypertexte vers les sites Web d'autres entreprises répertoriées ; et</li>
          <li>Entreprises accréditées à l'échelle du système, à l'exception de la sollicitation d'organisations à but non lucratif, de centres commerciaux caritatifs et de groupes de collecte de fonds caritatifs qui ne peuvent pas créer de lien hypertexte vers notre site Web.</li>
        </ul>

        <p>Ces organisations peuvent établir un lien vers notre page d'accueil, vers des publications ou vers d'autres informations sur le site Web tant que le lien : (a) n'est en aucun cas trompeur ; (b) n'implique pas faussement le parrainage, l'approbation ou l'approbation de la partie de liaison et de ses produits et/ou services ; et (c) s'inscrit dans le contexte du site de la partie de liaison.</p>

        <p>Nous pouvons considérer et approuver d'autres demandes de liens provenant des types d'organisations suivants :</p>

        <ul>
          <li>sources d'informations sur les consommateurs et/ou les entreprises ;</li>
          <li>sites communautaires dot.com ;</li>
          <li>associations ou autres groupes représentant des œuvres caritatives ;</li>
          <li>distributeurs d'annuaires en ligne ;</li>
          <li>portails Internet ;</li>
          <li>cabinets d'experts-comptables, d'avocats et de conseil ; et</li>
          <li>établissements d'enseignement et associations professionnelles.</li>
        </ul>

        <p>Nous approuverons les demandes de lien de ces organisations si nous décidons que : (a) le lien ne nous ferait pas regarder de manière défavorable à nous-mêmes ou à nos entreprises accréditées ; (b) l'organisation n'a aucun dossier négatif avec nous ; (c) le bénéfice pour nous de la visibilité du lien hypertexte compense l'absence de memodeep.com ; et (d) le lien est dans le contexte d'informations générales sur les ressources.</p>

        <p>Ces organisations peuvent créer un lien vers notre page d'accueil tant que le lien : (a) n'est en aucun cas trompeur ; (b) n'implique pas faussement le parrainage, l'approbation ou l'approbation de la partie de liaison et de ses produits ou services ; et (c) s'inscrit dans le contexte du site de la partie de liaison.</p>

        <p>Si vous êtes l'une des organisations énumérées au paragraphe 2 ci-dessus et que vous souhaitez créer un lien vers notre site Web, vous devez nous en informer en envoyant un e-mail à memodeep.com. Veuillez inclure votre nom, le nom de votre organisation, vos coordonnées ainsi que l'URL de votre site, une liste de toutes les URL à partir desquelles vous avez l'intention de créer un lien vers notre site Web et une liste des URL de notre site vers lesquelles vous souhaitez relier. Attendez 2-3 semaines pour une réponse.</p>

        <p>Les organisations approuvées peuvent créer un lien hypertexte vers notre site Web comme suit :</p>

        <ul>
          <li>En utilisant notre raison sociale ; ou</li>
          <li>En utilisant le localisateur de ressources uniforme auquel il est lié ; ou</li>
          <li>Par l'utilisation de toute autre description de notre site Web lié à ce qui a du sens dans le contexte et le format du contenu sur le site de la partie de liaison.</li>
        </ul>

        <p>Aucune utilisation du logo ou d'autres illustrations de memodeep.com ne sera autorisée pour le lien en l'absence d'un accord de licence de marque.</p>

        <h2><strong>iFrames</strong></h2>

        <p>Sans approbation préalable et autorisation écrite, vous ne pouvez pas créer de cadres autour de nos pages Web qui modifient de quelque manière que ce soit la présentation visuelle ou l'apparence de notre site Web.</p>

        <h2><strong>Responsabilité du Contenu</strong></h2>

        <p>Nous ne serons pas tenus responsables du contenu qui apparaît sur votre site Web. Vous acceptez de nous protéger et de nous défendre contre toutes les réclamations qui surviennent sur votre site Web. Aucun lien ne doit apparaître sur un site Web pouvant être interprété comme diffamatoire, obscène ou criminel, ou qui enfreint, viole ou préconise la violation ou toute autre violation des droits de tiers.</p>

        <h2><strong>Votre confidentialité</strong></h2>

        <p>Veuillez lire la politique de confidentialité</p>

        <h2><strong>Réservation des droits</strong></h2>

        <p>Nous nous réservons le droit de vous demander de supprimer tous les liens ou tout lien particulier vers notre site Web. Vous acceptez de supprimer immédiatement tous les liens vers notre site Web sur demande. Nous nous réservons également le droit de modifier ces termes et conditions et sa politique de lien à tout moment. En vous connectant en permanence à notre site Web, vous acceptez d'être lié et de suivre ces termes et conditions de liaison.</p>

        <h2><strong>Suppression des liens de notre site Web</strong></h2>

        <p>Si vous trouvez sur notre site Web un lien offensant pour quelque raison que ce soit, vous êtes libre de nous contacter et de nous en informer à tout moment. Nous examinerons les demandes de suppression de liens, mais nous ne sommes pas obligés de vous répondre directement.</p>

        <p>Nous ne garantissons pas que les informations sur ce site Web sont correctes, nous ne garantissons pas leur exhaustivité ou leur exactitude ; nous ne promettons pas non plus de garantir que le site Web reste disponible ou que le matériel sur le site Web est tenu à jour.</p>

        <h2><strong>Avertissement</strong></h2>

        <p>Dans toute la mesure permise par la loi applicable, nous excluons toutes les déclarations, garanties et conditions relatives à notre site Web et à l'utilisation de ce site Web. Rien dans cette clause de non-responsabilité :</p>

        <ul>
          <li>limiter ou exclure notre ou votre responsabilité en cas de décès ou de blessure ;</li>
          <li>limiter ou exclure notre ou votre responsabilité en cas de fraude ou de déclaration frauduleuse ;</li>
          <li>limiter l'une de nos ou vos responsabilités d'une manière non autorisée par la loi en vigueur ; ou</li>
          <li>exclure l'une de nos ou vos responsabilités qui ne peuvent être exclues en vertu de la loi applicable.</li>
        </ul>

        <p>Les limitations et interdictions de responsabilité définies dans cette section et ailleurs dans cette clause de non-responsabilité : (a) sont soumises au paragraphe précédent ; et (b) régissent toutes les responsabilités découlant de la clause de non-responsabilité, y compris les responsabilités découlant du contrat,
          et pour manquement à une obligation légale.</p>

        <p>Tant que le site Web et les informations et services sur le site Web sont fournis gratuitement, nous ne serons pas responsables de toute perte ou dommage de quelque nature que ce soit.</p>
      </div>
    </section>
  );
}
export { Policy };