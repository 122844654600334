import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  genereRandomNumber,
  selectFromDecrement,
  selectFromIncrement,
  selectToDecrement,
  selectToIncrement,
  setWrite,
} from "../../../redux/actions/gameActions";
import IconSite from "../../IconSite";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { reducerReactPosition } from "../reducerReactPosition";
import GameInfo from "../GameInfo";
import GameBarAction from "../GameBarAction";
import GameInsideFromToNav from "../GameInsideFromToNav";
import { GAME_WRITE } from "../../../redux/actionTypes";
import { focusMot } from "../../../redux/actions/motActions";


function FlashcardWrite() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mot = useSelector((state) => state.mot);
  const { lecons, mots } = mot;

  const game = useSelector((state) => state.game);
  const { from, write, onlyFocus } = game;

  let fontUI = write.font === "no" || write.font === undefined ? "flashcardwrite__guess" : write.font;

  let [writing, setWriting] = useState("");

  const getToGuessImage = () => {
    if (mots && mots.length > 1 && mots.length === write.randoms.length) {
      return {
        image: write.guess
      };
    }
    return { translate: "---" };
  };

  const getClass = () => {
    if (
      write.answer &&
      write.solution &&
      mots &&
      mots.length > 1 &&
      mots.length === write.randoms.length &&
      writing
    ) {

      if (write.answer.toUpperCase().trim() === writing.toUpperCase().trim()) {
        return "flashcardwrite__answer--green";
      }
    }
    return "flashcardwrite__answer";
  };

  const showSolution = () => {
    dispatch(setWrite({ ...write, solution: true }));
  };

  const changeFontUI = () => {
    if (fontUI === "flashcardwrite__guess") {
      dispatch({ type: GAME_WRITE, payload: { ...write, font: "flashcardwrite__guess bigfont" } });
    } else {
      dispatch({ type: GAME_WRITE, payload: { ...write, font: "flashcardwrite__guess" } });
    }
  }

  const getFocus = () => {
    if (mots && mots.length > 1 && mots.length === write.randoms.length) {
      return mots[write.randoms[write.position]].focus;
    }
    return '0';
  };

  return (
    <section className="flashcardwrite">
      <GameInfo mots={mots.length} lecons={lecons.length} position={write.position} />

      {from === 'image' && getToGuessImage().image ?
        <img className="imageMot"
          src={getToGuessImage().image}
          alt="new"
        />
        :
        <div className={fontUI}>{write.guess}</div>
      }

      <GameInsideFromToNav guess={write.guess} onlyFocus={onlyFocus} isfocus={getFocus()} changeFont={changeFontUI} selectFromDecrement={selectFromDecrement} selectFromIncrement={selectFromIncrement} focus={() =>
        reducerReactPosition("focus", mots, dispatch, write, focusMot)
      } />

      <input
        id="writing"
        type="text"
        name="writing"
        value={writing}
        placeholder={t("answer")}
        onChange={(e) => setWriting(e.target.value)}
        className="flashcardwrite__input"
      />

      {write.solution && (
        <div className={getClass()}>{write.answer}</div>
      )}
      <GameBarAction
        selectToDecrement={selectToDecrement}
        selectToIncrement={selectToIncrement}
        goLeft={() =>
          reducerReactPosition("decrement", mots, dispatch, write, setWrite)
        }
        goRight={() => {
          reducerReactPosition("increment", mots, dispatch, write, setWrite);
          setWriting("");
        }}
        reStart={() => dispatch(genereRandomNumber("write"))}
        showSolution={() => showSolution()}
        focus={() =>
          reducerReactPosition("focus", mots, dispatch, write, focusMot)
        }

      />
    </section>
  );
}

export default FlashcardWrite;
