import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createLecon,
  toggle_lecon,
  toggle_favorites,
  unSelectAllLecon,
  toggle_onlyFocus
} from "../../redux/actions/leconActions";
import { listVisitorLecons } from "../../redux/actions/visitorActions";
import Lecon from "./Lecon";
import IconSite from "../IconSite";
import { useAuth } from "../use-auth";
import { useTranslation } from "react-i18next";
import { usePopup } from "../popup/PopupContext";
import configApp from "../../config";
import selectlesson from "../../images/PNG/selectLesson.webp";

let Spinner = require('react-spinkit');

function Lecons(props) {
  const auth = useAuth();
  const popup = usePopup();

  const { t } = useTranslation();
  let [search, setSearch] = useState("");

  const lecon = useSelector((state) => state.lecon);
  const { loading, error } = lecon;

  let listClass = "lecons__list";
  let iconClass = "icon-resize-100";

  if (!props.visibility) {
    listClass = "lecons__list--visible";
    iconClass = "icon-resize-full-screen";
  }

  let favoriteClass = "material-icons green md-24";
  let progressionClass = "material-icons green md-24";
  let completedClass = "material-icons green md-24";
  let onlyFocusClass = "material-icons green md-24";

  if (!props.favorite) {
    favoriteClass = "material-icons md-24 md-light";
  }

  if (!props.progression) {
    progressionClass = "material-icons md-24 md-light";
  }

  if (!props.completed) {
    completedClass = "material-icons md-24 md-light";
  }

  if (props.game && !props.game.onlyFocus) {
    onlyFocusClass = "material-icons md-24 md-light";
  }

  const dispatch = useDispatch();

  const createNewLecon = () => {
    if (auth.user) {
      //if (auth.user.state === configApp.SUBCRIPTIONFREE) {
        dispatch(
          createLecon(
            { name: "Name me", user: auth.user, type: props.type },
            props.createReducerType
          )
        ).catch((error) => alert("Lecon.js 53 " + error));
      // } else {
      //   if (
      //     props.lecons.filter((o) => {
      //       return o.type === props.type;
      //     }).length < configApp.MAXLECON
      //   ) {
      //     dispatch(
      //       createLecon(
      //         { name: "Name me", user: auth.user, type: props.type },
      //         props.createReducerType
      //       )
      //     ).catch((error) => alert("Lecon.js 65 " + error));
      //   } else {
      //     popup.open(t("subcribeneed"));
      //   }
      // }
    } else {
      popup.open(t("signupwarning"));
    }
  };


  const toggleOnlyFocus = () => {
    dispatch(
      toggle_onlyFocus(
        props.type,
        !props.game.onlyFocus
      )
    ).catch((error) => alert("Lecon.js 84 " + error));
  };

  const toggleFavorites = () => {
    if (auth.user) {
      dispatch(
        toggle_favorites(
          props.type,
          !props.favorite,
          props.progression,
          props.completed,
          auth.user._id,
          props.getReducerType
        )
      ).catch((error) => alert("Lecon.js 84 " + error));
    } else {
      dispatch(listVisitorLecons(props.type, !props.favorite)).catch((error) => alert("Lecon.js 86 " + error));
    }
  };

  const toggleProgression = () => {
    if (auth.user) {
      dispatch(
        toggle_favorites(
          props.type,
          props.favorite,
          !props.progression,
          props.completed,
          auth.user._id,
          props.getReducerType
        )
      ).catch((error) => alert("Lecon.js 84 " + error));
    } else {
      dispatch(listVisitorLecons(props.type, !props.favorite)).catch((error) => alert("Lecon.js 86 " + error));
    }
  };

  const toggleCompleted = () => {
    if (auth.user) {
      dispatch(
        toggle_favorites(
          props.type,
          props.favorite,
          props.progression,
          !props.completed,
          auth.user._id,
          props.getReducerType
        )
      ).catch((error) => alert("Lecon.js 84 " + error));
    } else {
      dispatch(listVisitorLecons(props.type, !props.favorite)).catch((error) => alert("Lecon.js 86 " + error));
    }
  };

  return loading ? (
    <section className="lecons">
      <div className="lecons__menu">
        <div><Spinner name='cube-grid-bounce' /></div>
      </div>
    </section>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <section className="lecons">
      <div className="lecons__menu">
        {props.createLecon === "true" ? (
          <button onClick={() => createNewLecon()} className="icon-button">
            <IconSite name="icon-squared-plus" cssname="icon" />
          </button>
        ) : (
          ""
        )}
        {props.createLecon === "true" ? (
          <span>{t("addlecon")}</span>
        ) : (
          <span>{t("selectlecon")}</span>
        )}
        <button
          type="button"
          onClick={() => toggleFavorites()}
          className="icon-button"
        >
          <span className={favoriteClass}>favorite</span>
        </button>
        <button
          type="button"
          onClick={() => toggleProgression()}
          className="icon-button"
        >
          <span className={progressionClass}>trending_up</span>
        </button>
        <button
          type="button"
          onClick={() => toggleCompleted()}
          className="icon-button"
        >
          <span className={completedClass}>task</span>
        </button>

        {props.selected.length > 0 && (
          <button
            type="button"
            onClick={() => dispatch(unSelectAllLecon(props.type))}
            className="icon-button"
          >
            <span className="material-icons md-24 md-light">check_box</span>
          </button>
        )}
        {props.createLecon === 'false' && (
          <button
            type="button"
            onClick={() => toggleOnlyFocus()}
            className="icon-button"
          >
            <span className={onlyFocusClass}>grade</span>
          </button>

        )}

        <button
          type="button"
          onClick={() => dispatch(toggle_lecon(props.type, !props.visibility)).catch((error) => alert("Lecon.js 137 " + error))}
          className="icon-button"
        >
          <IconSite name={iconClass} cssname="icon" />
        </button>
      </div>
      <div className="lecons__search">
        <input
          id="search"
          type="text"
          name="search"
          value={search}
          placeholder={t("search")}
          onChange={(e) => setSearch(e.target.value)}
          className="lecons__search__input"
        />
        <button
          type="button"
          onClick={() => {
            if (search && search.trim() !== '') {
              dispatch(props.selectLeconByWord(search.trim(), props.type)).catch((error) => alert("Lecon.js 155 " + error))
            }
          }}
          className="icon-button--green"
        >
          <IconSite name="icon-magnifying-glass" cssname="icon--green" />
        </button>
      </div>
      <div className="lecons__list--container">
        <ul className={listClass}>
          {props.lecons.map((lecon) => (
            <li key={lecon._id} className="lecons__item">
              <Lecon
                _id={lecon._id}
                lecon={lecon}
                createLecon={props.createLecon}
                type={props.type}
                selected={props.selected}
                selectLecon={props.selectLecon}
                unselectLecon={props.unselectLecon}
                deleteReducerType={props.deleteReducerType}
                updateReducerType={props.updateReducerType}
              />
            </li>
          ))}
        </ul>
        {auth.user === null &&
          <div className="tuto">
            <img
              alt="Upload"
              className="tuto__image"
              src={selectlesson}
            />
            <p>{t("lecontuto")}</p>
            <p className="tuto__help">{t("trick")}</p>
          </div>}
      </div>
    </section>
  );
}

export default Lecons;
