import React from "react";

import { Trans, useTranslation } from "react-i18next";

const PopupMemoConfirm = props => {

    const { t, i18n } = useTranslation();

    const param = props.name;

    return (
        <div className="popup__confirmation"  >
            <div className="popup__confirmation__container">
                <div className="popup__confirmation__notification" >
                    <Trans i18nKey={props.content} >{{param}}</Trans>
                </div>
                <button
                    className="popup__confirmation__button"
                    onClick={props.handleClose}>
                    {t("cancel")}
                   </button>
                <button
                    className="popup__confirmation__button"
                    onClick={props.handleConfirm}>
                    {t(props.actionname)}
                </button>
            </div>
        </div>
    );
};

export default PopupMemoConfirm;