import React, { useState, useEffect, useContext } from "react";
import "./sass/main.scss";
import "./css/icon-font.css";
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  Redirect
} from "react-router-dom";
import {
  USER_SIGNOUT,
  LECONS_FAIL,
  LECONS_REQUEST,
  LECONS_SUCCESS,
  LECON_REMOVE_ITEM,
  LECON_CREATE_ITEM,
  LECON_CHANGE_ITEM,
  LECONS_TEXTE_SUCCESS,
  LECON_TEXTE_REMOVE_ITEM,
  LECON_TEXTE_CREATE_ITEM,
  LECON_TEXTE_CHANGE_ITEM,
} from "../src/redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Mots from "./components/input/Mots";
import Textes from "./components/input/Textes";
import Lecons from "./components/input/Lecons";
import Configs from "./components/input/Configs";
import Translates from "./components/input/Translates";
import TranslateTextes from "./components/input/TranslateTextes";
import Flashcards from "./components/game/word/Flashcards";
import FlashcardsTexte from "./components/game/texte/FlashcardsTexte";

import FromTo from "./components/game/FromTo";
import User from "./components/user/User";
import Footer from "./components/Footer";
import Mail from "./components/Mail";

import { selectLeconWord, unselectLeconWord, selectLeconWordByWord, listFocusWord } from "./redux/actions/motActions";
import {
  selectLeconTexte,
  unselectLeconTexte,
  selectLeconTexteByWord,
  listFocusTexte
} from "./redux/actions/texteActions";

import {
  selectFromWord,
  selectToWord,
  toogle_from_to_word,
} from "./redux/actions/gameActions";
import {
  selectFromTexte,
  selectToTexte,
  toogle_from_to_texte,
} from "./redux/actions/gameTexteActions";

import { listLecons, toggle_favorites } from "./redux/actions/leconActions";
import { listVisitorConfigs, listVisitorLecons } from "./redux/actions/visitorActions";

import axios from "axios";
import { useAuth } from "./components/use-auth";
import { usePopup } from "./components/popup/PopupContext";

import PopupMemo from "./components/popup/PopupMemo";
import PopupMemoConfirm from "./components/popup/PopupMemoConfirm";
import PopupError from "./components/popup/PopupError";
import _ from "lodash";
import { listConfigs } from "./redux/actions/configActions";
import SubcriptionFree from "./components/user/SubcriptionFree";
import SubcriptionFull from "./components/user/SubcriptionFull";
import Presentation from "./components/user/Presentation";
import Release from "./components/user/Release";
import logo from "./images/PNG/LOGO2.png";
import Document from "./components/user/Document";
import { useCookies } from 'react-cookie'
import PopupDisplayMessage from "./components/popup/PopupDisplayMessage";
import useGoogleAnalytics from "./googleAnalyticsHook";
import Policy from "./components/user/Policy";

var Spinner = require("react-spinkit");

function Routes() {
  useGoogleAnalytics();
  const langueState = useSelector((state) => state.langue);

  const { langue } = langueState;

  const { t, i18n } = useTranslation();

  const lecon = useSelector((state) => state.lecon);
  const {
    lecons,
    textes,
    loading,
    error,
    word_visible,
    texte_visible,
    word_favorite,
    texte_favorite,
    word_progression,
    word_completed,
    texte_progression,
    texte_completed
  } = lecon;

  const mot = useSelector((state) => state.mot);
  const texte = useSelector((state) => state.texte);

  const game = useSelector((state) => state.game);
  const gameTexte = useSelector((state) => state.gameTexte);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  axios.defaults.withCredentials = true;
  const auth = useAuth();

  const popup = usePopup();

  const [userName, setUserName] = useState("Visitor");
  const [cookies, setCookie] = useCookies()

  //for test
  // const [width, setWidth] = useState(0);
  // const handleResize = () => {
  //   setWidth(document.getElementById('m') ? document.getElementById('m').offsetWidth : 0);
  // }
  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  //end for test

  useEffect(() => {
    const refresh = async () => {
      setIsLoading(true);
      await auth.refreshToken(auth.user);
      setIsLoading(false);
    }
    refresh();
    i18n.changeLanguage(langue);
    return () => { };
  }, []);

  useEffect(() => {
    //    let checker;
    if (!isLoading) {
      if (auth.user) {
        // checker = setInterval(() => {
        //   auth.refreshToken(auth.user);
        // }, 24*60*60); // 1000 => 1 s  default : 900000
        setUserName(auth.user.name);

        if (word_favorite || word_progression || word_completed) {
          dispatch(
            toggle_favorites(
              'word',
              word_favorite,
              word_progression,
              word_completed,
              auth.user._id,
              'LECONS_SUCCESS'
            )
          ).catch((error) => alert("Lecon.js 84 " + error));
        } else {
          dispatch(listLecons(auth.user._id, "word", LECONS_SUCCESS));
        }
        if (texte_favorite || texte_progression || texte_completed) {
          dispatch(
            toggle_favorites(
              'texte',
              texte_favorite,
              texte_progression,
              texte_completed,
              auth.user._id,
              'LECONS_TEXTE_SUCCESS'
            )
          ).catch((error) => alert("Lecon.js 84 " + error));
        } else {
          dispatch(listLecons(auth.user._id, "texte", LECONS_TEXTE_SUCCESS));
        }
        dispatch(listConfigs(auth.user._id));
        dispatch(listFocusWord(auth.user._id ));
        dispatch(listFocusTexte(auth.user._id ));
      } else {
        setUserName("Visitor");
        dispatch(listVisitorLecons("word", false));
        dispatch(listVisitorLecons("texte", false));
        dispatch(listVisitorConfigs());
        // if (cookies['displayAgain'] === undefined) {
        //   popup.openDisplayMessage(t("signup"));
        // }
      }
      //dispatch({ type: USER_SIGNOUT });
    }
    // setTimeout(() => {
    //   setIsLoading(false);        
    // }, 500);
    return () => {
      //      clearInterval(checker);
    };
  }, [auth.user, isLoading]);

  if (isLoading) {
    return <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}><Spinner name="cube-grid-bounce" /></div>;
  }

  return (
    <>
      {popup.isOpen && (
        <PopupMemo content={popup.msg} handleClose={popup.close} />
      )}
      {popup.isConfirmOpen && (
        <PopupMemoConfirm
          content={popup.msg}
          name={popup.param}
          actionname={popup.actionname ? popup.actionname : "delete"}
          handleClose={popup.close}
          handleConfirm={popup.confirm}
        />
      )}
      {popup.isErrorOpen && (
        <PopupError content={popup.msg} handleClose={popup.close} />
      )}
      {/* {popup.isDisplayMessageOpen && (
        <PopupDisplayMessage
          content={popup.msg}
          name={popup.param}
          actionname={t("noshowagain")}
          handleClose={popup.close}
          handleConfirm={() => { setCookie('displayAgain', false, { maxAge: 630720000 }); popup.close(); }}
        />
      )} */}

      <div className="container">
        <header className="header">
          <nav className="user-nav">
            <img className="user-nav__logo--left" src={logo} />
            <NavLink
              activeClassName="user-nav__items--selected"
              className="user-nav__items"
              to="/motgames"
            >
              <span className="material-icons md-36 md-light">dashboard</span>
              <span className="sep"></span>
              <span>{t("game")}</span>
              {t("word")}
            </NavLink>
            <NavLink
              activeClassName="user-nav__items--selected"
              className="user-nav__items"
              to="/textegames"
            >
              <span className="material-icons md-36 md-light">dashboard</span>
              <span className="sep"></span>
              <span>{t("game")}</span>
              {t("texte")}
            </NavLink>
            <NavLink
              activeClassName="user-nav__items--selected"
              className="user-nav__items"
              to="/lecon"
            >
              <span className="material-icons md-36 md-light">
                dashboard_customize
              </span>
              <span className="sep"></span>
              {t("word")}
            </NavLink>
            <NavLink
              activeClassName="user-nav__items--selected"
              className="user-nav__items"
              to="/phrase"
            >
              <span className="material-icons md-36 md-light">description</span>
              <span className="sep"></span>
              {t("texte")}
            </NavLink>
            <NavLink
              activeClassName="user-nav__items--selected"
              className="user-nav__items"
              to="/config"
            >
              <span className="material-icons md-36 md-light">settings</span>
              <span className="sep"></span>
              {t("setting")}
            </NavLink>
            <NavLink
              activeClassName="user-nav__items--selected"
              className="user-nav__items"
              to="/user"
            >
              <span className="material-icons md-36 md-light">face</span>
              <span className="sep"></span>
              {t("user")}
            </NavLink>
            <NavLink
              activeClassName="user-nav__items--selected"
              className="user-nav__items--display"
              to="/question"
            >
              <span className="material-icons md-36 md-light">help_center</span>
              <span className="sep"></span>
              {t("help")}
            </NavLink>
            <img className="user-nav__logo--right" src={logo} />
          </nav>
        </header>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            {auth.user === null ?
              <Redirect to="/myself" />
              :
              <Redirect to="/motgames" />
            }
          </Route>
          <Route path="/motgames">
            <main className="main-3">
              <Lecons
                createLecon="false"
                type="word"
                lecons={lecons}
                getReducerType={LECONS_SUCCESS}
                visibility={word_visible}
                favorite={word_favorite}
                progression={word_progression}
                completed={word_completed}
                selected={mot.lecons}
                selectLecon={selectLeconWord}
                unselectLecon={unselectLeconWord}
                selectLeconByWord={selectLeconWordByWord}
                game={game}
              ></Lecons>
              <FromTo
                type="word"
                selectFrom={selectFromWord}
                selectTo={selectToWord}
                toogle_from_to={toogle_from_to_word}
                rstate={game}
              ></FromTo>
              <Flashcards />
            </main>
          </Route>
          <Route path="/textegames">
            <main className="main-3">
              <Lecons
                createLecon="false"
                type="texte"
                lecons={textes}
                getReducerType={LECONS_TEXTE_SUCCESS}
                visibility={texte_visible}
                favorite={texte_favorite}
                progression={texte_progression}
                completed={texte_completed}
                selected={texte.lecons}
                selectLecon={selectLeconTexte}
                unselectLecon={unselectLeconTexte}
                selectLeconByWord={selectLeconTexteByWord}
                game={gameTexte}
              ></Lecons>
              <FromTo
                type="texte"
                selectFrom={selectFromTexte}
                selectTo={selectToTexte}
                toogle_from_to={toogle_from_to_texte}
                rstate={gameTexte}
              ></FromTo>
              <FlashcardsTexte />
            </main>
          </Route>
          <Route path="/lecon">
            <main className="main-3">
              <Lecons
                createLecon="true"
                type="word"
                getReducerType={LECONS_SUCCESS}
                createReducerType={LECON_CREATE_ITEM}
                deleteReducerType={LECON_REMOVE_ITEM}
                updateReducerType={LECON_CHANGE_ITEM}
                lecons={lecons}
                visibility={word_visible}
                favorite={word_favorite}
                progression={word_progression}
                completed={word_completed}
                selected={mot.lecons}
                selectLecon={selectLeconWord}
                unselectLecon={unselectLeconWord}
                selectLeconByWord={selectLeconWordByWord}
              ></Lecons>
              <Translates type="word" lecons={lecons}></Translates>
              <Mots type="word"></Mots>
            </main>
          </Route>
          <Route path="/phrase">
            <main className="main-3">
              <Lecons
                createLecon="true"
                type="texte"
                getReducerType={LECONS_TEXTE_SUCCESS}
                createReducerType={LECON_TEXTE_CREATE_ITEM}
                deleteReducerType={LECON_TEXTE_REMOVE_ITEM}
                updateReducerType={LECON_TEXTE_CHANGE_ITEM}
                lecons={textes}
                visibility={texte_visible}
                favorite={texte_favorite}
                progression={texte_progression}
                completed={texte_completed}
                selected={texte.lecons}
                selectLecon={selectLeconTexte}
                unselectLecon={unselectLeconTexte}
                selectLeconByWord={selectLeconTexteByWord}
              ></Lecons>
              <TranslateTextes type="texte" lecons={textes}></TranslateTextes>
              <Textes type="texte"></Textes>
            </main>
          </Route>
          <Route path="/config">
            <main className="main-3">
              <Configs></Configs>
            </main>
          </Route>
          <Route path="/user">
            <main className="main-3">
              <User />
              <SubcriptionFree />
              <SubcriptionFull />
            </main>
          </Route>
          <Route path="/question">
            <main className="main-1">
              <Document />
            </main>
          </Route>
          <Route path="/mail">
            <main className="main-1">
              <Mail />
            </main>
          </Route>
          <Route path="/myself">
            <main className="main-1">
              <Presentation />
            </main>
          </Route>
          <Route path="/release">
            <main className="main-1">
              <Release />
            </main>
          </Route>
          <Route path="/policy">
            <main className="main-1">
              <Policy />
            </main>
          </Route>
          <Route path="/print">
            test
          </Route>
        </Switch>
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
      <Routes />
    </BrowserRouter>
  )
}



export default App;
