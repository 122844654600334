import axios from 'axios';
import { CONFIGS_FAIL, CONFIGS_REQUEST, CONFIGS_SUCCESS, CONFIG_REMOVE_ITEM, CONFIG_CREATE_ITEM, MOT_CONFIG_REMOVE_ITEM, CONFIG_CHANGE_NAME} from '../actionTypes';
import configApp from '../../config';

const listConfigs = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONFIGS_REQUEST });
        const { data } = await axios.get(configApp.URL_BACKEND + "/api/config/user/" + _id);
        dispatch({ type: CONFIGS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CONFIGS_FAIL, payload: error.message });
    }
}

const deleteConfig = (configId) => async (dispatch, getState) => {
    try {
        const { data } = await axios.delete(configApp.URL_BACKEND + "/api/config/" + configId);
        dispatch({ type: CONFIG_REMOVE_ITEM, payload: data });
        dispatch({ type: MOT_CONFIG_REMOVE_ITEM, payload: configId });
    } catch (error) {
        throw error;
    }
}

const createConfig = (user , type, translate ) => async (dispatch, getState) => {
    try {
        let config = { user: user , type : type , value : translate };
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/config/", config);
        dispatch({ type: CONFIG_CREATE_ITEM, payload: data });
    } catch (error) {
        throw error;
    }
}

const updateConfigName = (config) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONFIG_CHANGE_NAME, payload: config });
    } catch (error) {
        throw error;
    }
}

const saveConfigs = () => async (dispatch, getState) => {
    try {
        const { config } = getState();
        let { configs } = config;
        if (configs && configs.length !== 0) {
            const { data } = await axios.put(configApp.URL_BACKEND + "/api/config/all", configs);
        }
    } catch (error) {
        throw error;
    }
}

const upload = (formData) => async (dispatch, getState) => {
    try {
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/upload/", formData);
    } catch (error) {
        throw error;
    }
}

export { listConfigs, deleteConfig, createConfig, updateConfigName, saveConfigs, upload };