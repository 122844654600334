import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";
import { PresentationShort as PresentationShortEN } from "../user/EN/PresentationShort";
import { PresentationShort as PresentationShortFR } from "../user/FR/PresentationShort";
import { PresentationShort as PresentationShortJP } from "../user/JP/PresentationShort";

//import vid1 from "../../images/VIDEO/video.mp4";
//import vid2 from "../../images/VIDEO/video.webm";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLangue } from "../../redux/actions/langueActions";

const PopupDisplayMessage = (props) => {

  const langueState = useSelector(state => state.langue);
  const { langue } = langueState;

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const param = props.name;
  const language = t("language");

  const history = useHistory();
  const navigateToPresentation = () => { history.push('/myself'); props.handleClose(); }
  const navigateToHelp = () => { history.push('/question'); props.handleClose(); }

  const [langued, setLangued] = useState(langue);

  useEffect(() => {
    if (langued === 'jap') {
      i18n.changeLanguage('jap');
    } else if (langued === 'fre') {
      i18n.changeLanguage('fre');
    } else {
      i18n.changeLanguage('en');
    }
    dispatch(changeLangue(langued));
    return () => {
    };
  }, [langued]);

  return (
    <div className="popup__presentation">
      <div className="popup__presentation__container">
        <div className="user__language">
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
              onChange={() => setLangued('en')}
              type="radio" name="langue" />
            <label className="user__language__radio--label" htmlFor="en" ></label>
            <span className="user__language__lg">English</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
              onChange={() => setLangued('fre')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="fr" ></label>
            <span className="user__language__lg">Français</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
              onChange={() => setLangued('jap')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="jp" ></label>
            <span className="user__language__lg">日本語</span>
          </div>
        </div>
        {language === "JP" && <PresentationShortJP />}
        {language === "EN" && <PresentationShortEN />}
        {language === "FR" && <PresentationShortFR />}
        <div className="popup__presentation__presentation">

          <div
            className="popup__presentation__presentation__content"
            onClick={navigateToPresentation}
          >
            <span className="material-icons blue md-36">portrait</span>
            <h2>{t("presentation").toUpperCase()}</h2>
          </div>
          <div
            className="popup__presentation__presentation__content"
            onClick={navigateToHelp}
          >
             <span className="material-icons blue md-36">help_center</span>
            <h2>{t("help").toUpperCase()}</h2>
          </div>
        </div>
        <div className="popup__presentation__action">
          <button
            className="popup__presentation__button"
            onClick={props.handleConfirm}
          >
            {t(props.actionname)}
          </button>
          <button
            className="popup__presentation__button"
            onClick={props.handleClose}
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupDisplayMessage;
