import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  genereRandomNumber,
  selectFromTexteDecrement,
  selectFromTexteIncrement,
  selectToTexteDecrement,
  selectToTexteIncrement,
  setAttempt, setMulti
} from "../../../redux/actions/gameTexteActions";

import IconSite from "../../IconSite";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { reducerReactPosition } from "../reducerReactPosition";
import GameInfo from "../GameInfo";
import GameBarAction from "../GameBarAction";
import GameInsideFromToNav from "../GameInsideFromToNav";
import { GAMETEXTE_MULTI } from "../../../redux/actionTypes";
import { focusMot } from "../../../redux/actions/texteActions";

function FlashcardMultiTexte() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const texte = useSelector((state) => state.texte);
  const { lecons, mots } = texte;

  const gameTexte = useSelector((state) => state.gameTexte);
  const { multi, onlyFocus } = gameTexte;

  let fontUI = multi.font === "no" || multi.font === undefined ? "flashcardmultitexte__guess" : multi.font;

  const changeFontUI = () => {
    if (fontUI === "flashcardmultitexte__guess") {
      dispatch({ type: GAMETEXTE_MULTI, payload: { ...multi, font: "flashcardmultitexte__guess bigfont" } });
    } else {
      dispatch({ type: GAMETEXTE_MULTI, payload: { ...multi, font: "flashcardmultitexte__guess" } });
    }
  }

  const getClass = (mot) => {
    if (multi.solution) {
      if (mot === multi.answer) {
        return "flashcardmultitexte__solution";
      }
    }
    if (multi.attempt === -1) {
      return "flashcardmultitexte__proposal";
    }

    if (multi.attempt.t === mot) {
      if (multi.attempt.t === multi.answer) {
        return "flashcardmultitexte__solution";
      } else {
        return "flashcardmultitexte__wrong";
      }
    }
    return "flashcardmultitexte__proposal";
  };

  const showSolution = () => {
    dispatch(setMulti({ ...multi, solution: true }));
  };

  const getFocus = () => {
    if (mots && mots.length > 1 && mots.length === multi.randoms.length) {
      return mots[multi.randoms[multi.position]].focus;
    }
    return '0';
  };

  return (
    <section className="flashcardmultitexte">
      <GameInfo mots={mots.length} lecons={lecons.length} position={multi.position} />

      <div className={fontUI}>{multi.guess}</div>

      <GameInsideFromToNav guess={multi.guess} onlyFocus={onlyFocus} isfocus={getFocus()} changeFont={changeFontUI} selectFromDecrement={selectFromTexteDecrement}
        selectFromIncrement={selectFromTexteIncrement}
        focus={() =>
          reducerReactPosition("focus", mots, dispatch, multi, focusMot)
        }
      />

      <ul className="flashcardmultitexte__answer">
        {multi.choices.map((mot, index) => (
          <li
            key={index}
            onMouseDown={() => {
              dispatch(setAttempt({ ...multi, attempt: mot }));
            }}
            className="flashcardmultitexte__choice"
          >
            <div className={getClass(mot.t)}>{mot.t}</div>
          </li>
        ))}
      </ul>
      <GameBarAction
        selectToDecrement={selectToTexteDecrement}
        selectToIncrement={selectToTexteIncrement}
        goLeft={() =>
          reducerReactPosition("decrement", mots, dispatch, multi, setMulti)
        }
        goRight={() =>
          reducerReactPosition("increment", mots, dispatch, multi, setMulti)
        }
        reStart={() => dispatch(genereRandomNumber("multi"))}
        showSolution={() => showSolution()}
      />
    </section>
  );
}

export default FlashcardMultiTexte;
