import React from 'react'
import { useTranslation } from "react-i18next";
import { SubcriptionFull as SubcriptionFullEN }  from "./EN/SubcriptionFull";
import { SubcriptionFull as SubcriptionFullFR } from "./FR/SubcriptionFull";
import { SubcriptionFull as SubcriptionFullJP } from "./JP/SubcriptionFull";

function SubcriptionFull() {

    const { t } = useTranslation();

    const language = t("language");
  
    return (
      <>
        {language === "JP" && <SubcriptionFullJP />}
        {language === "EN" && <SubcriptionFullEN />}
        {language === "FR" && <SubcriptionFullFR />}
      </>
    );
}
 
export default  SubcriptionFull