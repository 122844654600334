import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  genereRandomNumber,
  getNumber,
  selectFromTexteDecrement,
  selectFromTexteIncrement,
  selectToTexteDecrement,
  selectToTexteIncrement,
  setSelf,
} from "../../../redux/actions/gameTexteActions";
import { reducerReactPosition } from "./../reducerReactPosition";
import IconSite from "../../IconSite";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import GameInfo from "../GameInfo";
import GameBarAction from "../GameBarAction";
import GameInsideFromToNav from "../GameInsideFromToNav";
import { GAMETEXTE_SELF } from "../../../redux/actionTypes";
import { focusMot } from "../../../redux/actions/texteActions";

function FlashcardSelfTexte() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const texte = useSelector((state) => state.texte);
  const { lecons, mots } = texte;

  const gameTexte = useSelector((state) => state.gameTexte);
  const { self, onlyFocus } = gameTexte;

  const showSolution = () => {
    dispatch(setSelf({ ...self, solution: true }));
  };

  let fontUI = self.font === "no" || self.font === undefined ? "flashcardtexte__guess" : self.font;

  const changeFontUI = () => {
    if (fontUI === "flashcardtexte__guess") {
      dispatch({ type: GAMETEXTE_SELF, payload: { ...self, font: "flashcardtexte__guess bigfont" } });
    } else {
      dispatch({ type: GAMETEXTE_SELF, payload: { ...self, font: "flashcardtexte__guess" } });
    }
  }

  const getFocus = () => {
    if (mots && mots.length > 1 && mots.length === self.randoms.length) {
      return mots[self.randoms[self.position]].focus;
    }
    return '0';
  };

  return (
    <section className="flashcardtexte">
      <GameInfo mots={mots.length} lecons={lecons.length} position={self.position} />

      <div className={fontUI}>{self.guess}</div>

      <GameInsideFromToNav guess={self.guess} onlyFocus={onlyFocus} isfocus={getFocus()} changeFont={changeFontUI} selectFromDecrement={selectFromTexteDecrement}
        selectFromIncrement={selectFromTexteIncrement}
        focus={() =>
          reducerReactPosition("focus", mots, dispatch, self, focusMot)
        }
      />

      {self.solution ? (
        <div className="flashcardtexte__answer">{self.answer}</div>
      ) : (
        <div className="flashcardtexte__answer">&nbsp;</div>
      )}
      <GameBarAction
        selectToDecrement={selectToTexteDecrement}
        selectToIncrement={selectToTexteIncrement}
        goLeft={() =>
          reducerReactPosition("decrement", mots, dispatch, self, setSelf)
        }
        goRight={() =>
          reducerReactPosition("increment", mots, dispatch, self, setSelf)
        }
        reStart={() => dispatch(genereRandomNumber("self"))}
        showSolution={() => showSolution()}
      />
    </section>
  );
}

export default FlashcardSelfTexte;
