import axios from 'axios';
import _ from 'lodash';
import {
    LECONS_FAIL, LECONS_REQUEST, LECON_CREATE_ITEM, LECON_VISIBLE, LECON_FAVORITE, LECON_TEXTE_CREATE_ITEM,
    MOT_CLEAN, TEXTE_CLEAN, LECON_MOT_FAVORITE, LECON_TEXTE_FAVORITE, MOT_LECON_SELECT_UNSELECT_ALL, TEXTE_LECON_SELECT_UNSELECT_ALL, LECONS_SUCCESS, CONFIGS_REQUEST, CONFIGS_SUCCESS, GAME_ONLYFOCUS, GAMETEXTE_ONLYFOCUS, MOT_ONLYFOCUS_BANK_WORD, TEXTE_ONLYFOCUS_BANK_WORD, MOT_PRINT_LIST
} from '../actionTypes';
import configApp from '../../config';
import { setGame } from './gameActions';
import { setGame as setGameText } from './gameTexteActions';


const listLecons = (_id, type, reduceType) => async (dispatch, getState) => {
    try {
        dispatch({ type: LECONS_REQUEST });
        const { data } = await axios.get(configApp.URL_BACKEND + "/api/lecon/user/" + _id + "&" + type);
        dispatch({ type: reduceType, payload: data });
    } catch (error) {
        dispatch({ type: LECONS_FAIL, payload: error.message });
    }
}

const deleteLecon = (leconId, reducerType) => async (dispatch, getState) => {
    try {
        const data = await axios.delete(configApp.URL_BACKEND + "/api/lecon/" + leconId);
        dispatch({ type: reducerType, payload: leconId });
        dispatch(setGame());
        dispatch(setGameText());
    } catch (error) {
        throw error;
    }
}

const createLecon = (lecon, reducerType) => async (dispatch, getState) => {
    try {
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/lecon/", lecon);
        dispatch({ type: reducerType, payload: data });
    } catch (error) {
        throw error;
    }
}

const copyFocus = (user, type, reducerType) => async (dispatch, getState) => {
    try {
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/focus/copy/", { _id: user._id, type: type });
        dispatch({ type: reducerType, payload: data });
    } catch (error) {
        throw error;
    }
}

const changeName = (lecon, reducerType) => async (dispatch, getState) => {
    try {
        const { data } = await axios.put(configApp.URL_BACKEND + "/api/lecon/" + lecon._id, lecon);
        dispatch({ type: reducerType, payload: data });
    } catch (error) {
        throw error;
    }
}

const toggle_favorite = (lecon) => async (dispatch, getState) => {
    try {
        const { data } = await axios.put(configApp.URL_BACKEND + "/api/lecon/favorite/" + lecon._id, lecon);
        if (lecon.type === "word") {
            dispatch({ type: LECON_MOT_FAVORITE, payload: data });
        } else {
            dispatch({ type: LECON_TEXTE_FAVORITE, payload: data });
        }
    } catch (error) {
        throw error;
    }
}

const toggle_status = (lecon) => async (dispatch, getState) => {
    try {
        const { data } = await axios.put(configApp.URL_BACKEND + "/api/lecon/status/" + lecon._id, lecon);
        if (lecon.type === "word") {
            dispatch({ type: LECON_MOT_FAVORITE, payload: data });
        } else {
            dispatch({ type: LECON_TEXTE_FAVORITE, payload: data });
        }
    } catch (error) {
        throw error;
    }
}

const importLecon = (name, lines, user, type) => async (dispatch, getState) => {
    try {
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/lecon/import", { name: name, lines: lines, user: user, type: type });
        if (type === "word") {
            dispatch({ type: LECON_CREATE_ITEM, payload: data });
        } else {
            dispatch({ type: LECON_TEXTE_CREATE_ITEM, payload: data });
        }

    } catch (error) {
        throw error;
    }
}

const toggle_lecon = (type, visibility) => async (dispatch, getState) => {
    dispatch({ type: LECON_VISIBLE, payload: { type, visibility } });
}

const toggle_favorites = (type, favorite, progression, completed, _id, reducerType) => async (dispatch, getState) => {
    try {
        dispatch({ type: LECON_FAVORITE, payload: { type, favorite, progression, completed } });
        dispatch({ type: LECONS_REQUEST });
        if (favorite || progression || completed) {
            const { data } = await axios.get(configApp.URL_BACKEND + "/api/lecon/favorite/" + _id + "&" + type + "&" + favorite + "&" + progression + "&" + completed);
            dispatch({ type: reducerType, payload: data });
        } else {
            const { data } = await axios.get(configApp.URL_BACKEND + "/api/lecon/user/" + _id + "&" + type);
            dispatch({ type: reducerType, payload: data });
        }
        if (type === "word") {
            dispatch({ type: MOT_CLEAN });
            dispatch({ type: GAME_ONLYFOCUS, payload: false });
        } else {
            dispatch({ type: TEXTE_CLEAN });
            dispatch({ type: GAMETEXTE_ONLYFOCUS, payload: false });
        }

    } catch (error) {
        dispatch({ type: LECONS_FAIL, payload: error.message });
    }

}

const toggle_onlyFocus = (type, onlyFocus) => async (dispatch, getState) => {

    if (onlyFocus) {
        dispatch(unSelectAllLecon(type));
        if (type === "word") {
            const { mot } = getState();
            let { focus } = mot;
            dispatch({ type: MOT_ONLYFOCUS_BANK_WORD, payload: { focus } });
            dispatch({ type: GAME_ONLYFOCUS, payload: onlyFocus });
            dispatch(setGame());
        } else {
            const { texte } = getState();
            let { focus } = texte;
            dispatch({ type: TEXTE_ONLYFOCUS_BANK_WORD, payload: { focus } });
            dispatch({ type: GAMETEXTE_ONLYFOCUS, payload: onlyFocus });
            dispatch(setGameText());
        }
    } else {
        if (type === "word") {
            dispatch({ type: MOT_ONLYFOCUS_BANK_WORD, payload: { focus: [] } });
            dispatch({ type: GAME_ONLYFOCUS, payload: onlyFocus });
            dispatch(setGame());
        } else {
            dispatch({ type: TEXTE_ONLYFOCUS_BANK_WORD, payload: { focus: [] } });
            dispatch({ type: GAMETEXTE_ONLYFOCUS, payload: onlyFocus });
            dispatch(setGameText());
        }
    }
}

const selectAllLecon = (type) => async (dispatch, getState) => {
    try {
        const { lecon } = getState();
        if (type === "word") {
            let { lecons } = lecon;
            const ids = _.map(lecons, function (o) { return o._id });
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", ids);
            dispatch({ type: MOT_LECON_SELECT_UNSELECT_ALL, payload: { ids, data } });
        } else {
            let { textes } = lecon;
            const ids = _.map(textes, function (o) { return o._id });
            const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", ids);
            dispatch({ type: TEXTE_LECON_SELECT_UNSELECT_ALL, payload: { ids, data } });
        }
    } catch (error) {
        throw error;
    }
}

const unSelectAllLecon = (type) => async (dispatch, getState) => {
    try {
        if (type === "word") {
            dispatch({ type: MOT_LECON_SELECT_UNSELECT_ALL, payload: { ids: [], data: [] } });
            dispatch({ type: MOT_PRINT_LIST, payload: [] });
        } else {
            dispatch({ type: TEXTE_LECON_SELECT_UNSELECT_ALL, payload: { ids: [], data: [] } });
        }
        dispatch(setGame());
        dispatch(setGameText());
    } catch (error) {
        throw error;
    }
}

const importAutoLecon = (user, english, french, nk5, nk4, nk3, nk2, nk1, nv5, nv4, nv3, nv2, nv1) => async (dispatch, getState) => {
    try {
        dispatch({ type: LECONS_REQUEST });
        dispatch({ type: CONFIGS_REQUEST });
        await axios.post(configApp.URL_BACKEND + "/api/lecon/autoimport", { user: user, english, french, nk5, nk4, nk3, nk2, nk1, nv5, nv4, nv3, nv2, nv1 });
        const { data } = await axios.get(configApp.URL_BACKEND + "/api/config/user/" + user._id);
        dispatch({ type: CONFIGS_SUCCESS, payload: data });
        dispatch(listLecons(user._id, "word", LECONS_SUCCESS));
    } catch (error) {
        throw error;
    }
}

const exportLecon = (leconId, email) => async (dispatch, getState) => {
    try {
        await axios.post(configApp.URL_BACKEND + "/api/mail/export/", { leconId, email });
    } catch (error) {
        throw error;
    }
}

export { exportLecon, copyFocus, toggle_onlyFocus, listLecons, deleteLecon, createLecon, changeName, importLecon, importAutoLecon, toggle_lecon, toggle_favorite, toggle_favorites, selectAllLecon, unSelectAllLecon, toggle_status };