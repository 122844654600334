const dev = {
  //URL_BACKEND : 'https://localhost:3000'
  URL_BACKEND: "https://192.168.1.26:3000",
  //URL_BACKEND: "https://169.254.123.193:3000",
  //URL_BACKEND : 'https://memodeep.com:3000'
};

const prod = {
  //URL_BACKEND : 'https://192.168.1.11:3000'
  URL_BACKEND: "https://memodeep.com:3000"
  //URL_BACKEND: "https://petiteassiettejaponaise.com:3000",
};

const configApp =
  process.env.REACT_APP_STAGE === undefined ||
  process.env.REACT_APP_STAGE.trim() === "production"
    ? prod
    : dev;

configApp.MAXLECON = 5;
configApp.MAXWORD = 5;
configApp.SUBCRIPTIONFREE = "free";
configApp.SUBCRIPTIONFULL = "full";

configApp.PAYPALPLAN = "P-478032738K6401116MGEM2UI";

export default {
  ...configApp,
};
