import React, { useEffect, useState } from "react";
import mot from "../../../images/PNG/Mot.webp";
import reglage from "../../../images/PNG/Reglage.png";
import jeux from "../../../images/PNG/JeuxMotMulti.webp";
import signature from "../../../images/PNG/Signature.png";
import europeexemple from "../../../images/PNG/exemple-europe.PNG";
import asianexemple from "../../../images/PNG/exemple-asian.PNG";
import mathexemple from "../../../images/PNG/exemple-scientifique.PNG";
import generalexemple from "../../../images/PNG/exemple-general.PNG";
import kanjiPrint from "../../../images/PNG/KanjiPrint.PNG";
import kanjiShow from "../../../images/PNG/KanjiShow.PNG";
import kanjiGame from "../../../images/PNG/KanjiGame.PNG";

import {
  Link
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLangue } from "../../../redux/actions/langueActions";

function Presentation() {

  const langueState = useSelector(state => state.langue);
  const { langue } = langueState;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langued, setLangued] = useState(langue);

  useEffect(() => {
    if (langued === 'jap') {
      i18n.changeLanguage('jap');
    } else if (langued === 'fre') {
      i18n.changeLanguage('fre');
    } else {
      i18n.changeLanguage('en');
    }
    dispatch(changeLangue(langued));
    return () => {
    };
  }, [langued]);

  return (
    <section className="presentation">
      <section className="presentation__container">
        <div className="user__language">
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
              onChange={() => setLangued('en')}
              type="radio" name="langue" />
            <label className="user__language__radio--label" htmlFor="en" ></label>
            <span className="user__language__lg">English</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
              onChange={() => setLangued('fre')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="fr" ></label>
            <span className="user__language__lg">Français</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
              onChange={() => setLangued('jap')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="jp" ></label>
            <span className="user__language__lg">日本語</span>
          </div>
        </div>
        <h1>
          Ne jamais s'arrêter d'apprendre, parce que la vie ne s'arrête jamais d'enseigner.
        </h1>
        <h1>
          Bienvenue sur le site <strong>Memodeep.com.</strong>
        </h1>
        <br />
        <p>
          J'ai créé cette web application pour moi-même. Quand j'ai commencé à l'utiliser, j'ai pu apprendre et réviser tellement de mots et expressions en si peu de temps que j'ai pensé que je devrais la partager.
        </p>
        <p>
          Les cinqs prochaines minutes pour comprendre cette application seront les clefs pour votre succès. 
        </p>
        <br />
        <p>
          Nous voulons tous améliorer
          <strong> notre apprentissage.</strong>
        </p>
        <p>
          Que ce soit pour une nouvelle langue ou pour tout autre domaine comme
          l’histoire, la science ou la littérature, cette web application sera
          <strong> idéale</strong> pour travailler votre mémoire.
        </p>
        <span>
          <p>
            Créer un compte gratuit  &#128512;
          </p>
        </span>
        <Link to="/user" >
          <span className="material-icons blue md-36">subscriptions</span>
        </Link>
        <div className="messagewidth">
          <p className="backgroundgreen padding">
            <strong>Pour les étudiants en Japonais.</strong><br /><br />
            Vous pouvez profiter dans les leçons de démo d'une leçon libre pour les Kanji <strong>JLPT N5.</strong><br />
            En créant un compte utilisateur gratuit, vous pourrez importer tous les Kanji du niveau <strong>JLPT N5 et N4</strong> avec l'image de l'ordre du tracé.<br />
            Pour les abonnés, vous pourrez importer tous les niveaux JLPT <strong>N5, N4, N3, N2, N1 Kanji et vocabularire</strong> en quelques clicks<br />
            Environ <strong>130 leçons</strong> et plus de <strong>10 000 flashcards</strong> prête à utiliser.<br />
            Vous pourrez imprimer jusqu'à <strong>16 Kanji</strong> de votre choix.<br />
            De plus, je fournirai régulièrement des fichiers à importer se basant sur des points de grammaire avec des phrases que vous pourrez réviser dans les quiz.<br /><br />
            <div className="presentation__japanese">
              <div className="presentation__japanese--width">
                <div className="presentation__japanese--center">Quiz</div>
                <img
                  className="presentation__japanese--width"
                  alt="Photo 2"
                  src={kanjiGame}
                />
              </div>
              <div className="presentation__japanese--width">
                <div className="presentation__japanese--center">Flashcard</div>
                <img
                  className="presentation__japanese--width"
                  alt="Photo 2"
                  src={kanjiShow}
                />
              </div>
              <div className="presentation__japanese--width">
                <div className="presentation__japanese--center">Feuille imprimable A4</div>
                <img
                  className="presentation__japanese--width"
                  alt="Photo 2"
                  src={kanjiPrint}
                />
              </div>
            </div>
            <br />
          </p>
        </div>
        <br />
        {/* <br />
        <div className="messagewidth">
          <p className="backgroundgreen padding">
            <strong>Pour les étudiants en anglais.</strong><br /><br />
            Bientôt, tous le vocabulaire necessaire pour le TOIC et le TOEFL sera disponible.</p>
        </div> */}
        <br />
        <br />
        <div className="presentation__bg-video" >
          <iframe width="100%" height="348px" src="https://www.youtube.com/embed/MM0h9vV05uk" title="intro francais" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
        </div>
        <br />
        <br />
        <p>
          Cette application se base sur les <strong>flash cartes.</strong>
        </p>
        <p>
          En 3 simples étapes, vous pourrez commencer à exploiter cette application.
        </p>
        <ul className="presentation__step" style={{ listStyleType: 'decimal' }}>
          <li className="presentation__step__item">Choisir ce que vous souhaitez étudier.</li>
          <li className="presentation__step__item">Créer facilement et rapidement vos leçons.</li>
          <li className="presentation__step__item">Jouer avec les différents type de quiz.</li>
        </ul>
        <h3>
          Fonctionnalités:
        </h3>
        <br />
        <br />
        <ul className="presentation__feature">
          <li className="presentation__feature__item">Création de Flashcards configurables sans limites.</li>
          <li className="presentation__feature__item">2 différents types de configuration : mot ou phrase courte et texte long.</li>
          <li className="presentation__feature__item">Import des Kanji et vocabulaire japonais.</li>
          <br />
          <img
            alt="Photo 2"
            width="100%"
            src={reglage}
          />
        </ul>
        <ul className="presentation__feature">
          <li className="presentation__feature__item">Création rapide de leçons.</li>
          <li className="presentation__feature__item">Gestion  aisée des leçons.</li>
          <li className="presentation__feature__item">Création de listes d'informations par leçons.</li>
          <li className="presentation__feature__item">Affichage des différentes listes en un click.</li>
          <li className="presentation__feature__item">Affichage des listes multi leçons en un click.</li>
          <li className="presentation__feature__item">Informations centralisées dans un écran.</li>
          <li className="presentation__feature__item">Recherche d'informations.</li>
          <li className="presentation__feature__item">Saisi des informations.</li>
          <li className="presentation__feature__item">Import des leçons avec fichier texte pour saisie rapide.</li>
          <li className="presentation__feature__item">Utilisation des images libre du Web.</li>
          <br />
          <img
            alt="Photo 1"
            width="100%"
            src={mot}
          />
        </ul>
        <ul className="presentation__feature">
          <li className="presentation__feature__item">Différents type de quiz: auto évaluation, choix multiples, écriture.</li>
          <li className="presentation__feature__item">Changement de réglages au choix pendant le quiz.</li>
          <li className="presentation__feature__item">Navigation arrière et avant du quiz.</li>
          <li className="presentation__feature__item">Mélange des cartes au choix pendant le quiz.</li>
          <li className="presentation__feature__item">Navigation rapide entre les configurations.</li>
          <li className="presentation__feature__item">Bouton pour la taille de la police pour une meilleur lecture.</li>
          <li className="presentation__feature__item">Compatible avec tablette et smartphone.</li>
          <br />
          <img
            alt="Photo 3"
            width="100%"
            src={jeux}
          />
        </ul>
        <br />
        <div className="presentation__liendoc">
          <p className="presentation__liendoc__message">
            Visionner les tutoriaux
          </p>
          <Link to="/question" >
            <span className="material-icons blue md-36">help_center</span>
          </Link>
        </div>
        <br />
        <p style={{ alignSelf: "center" }}>
          Laissez-moi vous raconter pourquoi j’ai créé cet <strong>outil </strong>indispensable.
        </p>
        <br />
        <span class="material-icons blue md-36">sentiment_very_satisfied</span>
        <p>
          Il y a bien longtemps, j’ai commencé à apprendre cette belle langue,
          le japonais. Je n’avais pas de smartphone, seulement un livre pour apprendre cette nouvelle langue.
          A chaque leçon apprise, j’écrivais soigneusement chaque mot japonais et
          français sur une page.
          <br /> Une fois le livre fini, toutes les pages de mon cahier étaient
          remplies. Tous les jours, je révisais chaque page sans cesse.
        </p>
        <br />
        <span class="material-icons blue md-36">menu_book</span>
        <p>
          Il y avait beaucoup de mots et je finis par tous les retenir mais
          quelque chose était étrange. J’avais retenu quelque chose en plus,
          quelque chose d’inutile mais qui avait une influence sur tous les mots
          que j’avais mémorisé.
        </p>
        <p>
          Souvent lorsque, dans une phrase, je rencontrais un mot que j’avais
          pourtant appris, je ne le reconnaissais pas tout de suite et j’avais
          besoin de le vérifier de nouveau dans mon cahier. C’est là que j’ai
          réalisé que j’avais mémorisé sans le savoir l’ordre de tous les mots
          de mon cahier et, sorti du contexte de cette liste, le mot était
          difficile à reconnaître. J’avais pollué
          <strong> ma mémoire </strong>
          et rendu chaque mot trop lié à sa place dans cette liste.
        </p>
        <br />
        <span class="material-icons blue md-36">sentiment_very_dissatisfied</span>
        <p>
          C’est pour cela que la solution des <strong>flashcards</strong> m’est
          venue. Cela allait résoudre le problème de la liste.
          <br /> Mais à l’époque, tout devait se faire à la main et l’apparition
          des smartphones commençait doucement.
          <br />
          De plus, je suis allé au Japon et tous les livres pour apprendre le
          japonais étaient écrit en anglais. Mon anglais était loin d'être parfait à
          l’époque et je me retrouvais à devoir apprendre les deux langues en même temps.
        </p>
        <br />
        <span class="material-icons blue md-36">style</span>
        <br />
        <p>
          C’est alors que j’ai eu l’idée de créer cette application qui permet de
          créer des cartes configurables.
          <br /> J'allais pouvoir écrire plusieurs informations, les lier entre
          elles et passer d'une information à l'autre facilement.
        </p>
        <p>
          Avec cette application, une carte peut être renseignée avec de
          nombreuses entrées. Vous pouvez mettre pour un mot français, le mot
          anglais, japonais, espagnol et aussi des caractères japonais, chinois ou
          thailandais.
          <br /> Pendant le quiz, vous pouvez passer facilement d’une information
          à l’autre sans arrêter le jeu.
          <br />
          Cela vous permet d’apprendre <strong>plusieurs langues </strong>en parallèle
          facilement.
          <br /> Pour ajouter une information, il vous suffit juste de créer une
          nouvelle entrée sans recréer de carte.
          <br />
          Toutes vos données sont à un seul endroit.
        </p>
        <p>
          Pour le mode texte, vous pouvez mettre des phrases que vous voulez
          retenir.
        </p>
        <p>
          Et aussi, mettre les renseignements pour retrouver dans vos livres leur
          emplacement sans chercher de longues minutes.
        </p>
        <br />
        <span class="material-icons blue md-36">sticky_note_2</span>
        <p>
          Mais vous pouvez l’utiliser pour autre chose que des langues. Vous
          pouvez choisir des domaines comme l’histoire et lier la carte avec une
          date, un évènement, un lieu, etc.
          <br /> Cela peut être aussi la science avec le nom d’une formule et sa
          formule.
        </p>
        <div className="presentation__encouragement">
          <p style={{ alignSelf: "center" }}>
            <i>Apprenez tout ce que vous souhaitez sans limites !!!</i>
          </p>
          <span class="material-icons md-36">sentiment_very_satisfied</span>
        </div>
        <br />
        <p>
          J’espère que vous apprécierez cette application et qu'elle vous aidera
          dans votre apprentissage.
        </p>
        <p>
          N’hésitez pas à envoyer un message pour me faire part de vos remarques
          et vos encouragements.
        </p>
        <br />
        <div className="presentation__email">
          <Link className="footer__item" to="/mail">
            <span className="material-icons md-36 blue md">mail</span>
          </Link>
        </div>
        <p>
          Je continuerai à l’améliorer et y ajouter de nouvelles fonctionnalités.
        </p>
        <div className="presentation__cordialement">
          <p>Cordialement.</p>
        </div>
        <div className="presentation__signature">
          <img
            alt="Photo 3"
            className="presentation__signature__writing"
            src={signature}
          />
        </div>
        <div className="composition">
          <img
            alt="Photo 1"
            className="composition__photo composition__photo--p1"
            src={mot}
          />
          <img
            alt="Photo 2"
            className="composition__photo composition__photo--p2"
            src={reglage}
          />
          <img
            alt="Photo 3"
            className="composition__photo composition__photo--p3"
            src={jeux}
          />
        </div>
        <div className="presentation__extitle">
          Exemples de configuration
        </div>
        <br />
        <h3>
          Langue européenne
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="europe"
          src={europeexemple}
        />
        <br />
        <h3>
          Langue asiatique
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="asiatique"
          src={asianexemple}
        />
        <br />
        <h3 >
          Scientifique
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="science"
          src={mathexemple}
        />
        <br />
        <h3 >
          Générale
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="general"
          src={generalexemple}
        />
        <br />
        <p style={{ alignSelf: "center" }} >
        </p>
        <div className="presentation__encouragement">
          <p style={{ margin: '0px', alignSelf: "center" }}>
            <i>Mixer, créer tout ce que vous voulez!</i>
          </p>
        </div>
        <br />
        <br />
        <div className="presentation__liendoc">
          <p className="presentation__liendoc__message">
            Visionner les tutoriaux
          </p>
          <Link to="/question" >
            <span className="material-icons blue md-36">help_center</span>
          </Link>
        </div>
        <br />
        <span>
          <p>
            Créer un compte gratuit  &#128512;
          </p>
        </span>
        <Link to="/user" >
          <span className="material-icons blue md-36">subscriptions</span>
        </Link>
        <br />
      </section>
    </section>
  );
}

export { Presentation };
