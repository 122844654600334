import React, { useState, useEffect } from 'react'
import { useAuth } from "../use-auth";
import UserInfo from "./UserInfo";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { changeLangue } from '../../redux/actions/langueActions';
import { resetPassword } from '../../redux/actions/mailActions';
import { usePopup } from '../popup/PopupContext';
import _ from 'lodash';
import { USER_SIGNOUT } from '../../redux/actionTypes';

const checkAntiBot = (fac1, fac2) => {
    const total = Number(fac1) + Number(fac2, 10);
    return total === 7;
};

function User() {

    const langueState = useSelector(state => state.langue);
    const { langue } = langueState;

    const dispatch = useDispatch();

    const auth = useAuth();
    const popup = usePopup();
    const { t, i18n } = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [username2, setUsername2] = useState('');
    const [password2, setPassword2] = useState('');
    const [remember, setRemember] = useState('');
    const [username3, setUsername3] = useState('');
    const [langued, setLangued] = useState(langue);

    const [fac1, setFac1] = useState(0);
    const [fac2, setFac2] = useState(0);

    useEffect(() => {
        if (langued === 'jap') {
            i18n.changeLanguage('jap');
        } else if (langued === 'fre') {
            i18n.changeLanguage('fre');
        } else {
            i18n.changeLanguage('en');
        }
        dispatch(changeLangue(langued));
        return () => {
        };
    }, [langued]);


    const login = (event) => {
        event.preventDefault();
        if (checkAntiBot(fac1, fac2)) {
//            if (_.isEmpty(username2) && _.isEmpty(username3) && _.isEmpty(password2)) {
                auth.login(username, password).then(result => dispatch({ type: USER_SIGNOUT }));
            // } else {
            //     popup.open(t("onlylogin"));
            // }
        } else {
            popup.open(t("antibotwrong"));
        }
    }

    const signin = (event) => {
        event.preventDefault();
        if (checkAntiBot(fac1, fac2)) {
//            if (_.isEmpty(username) && _.isEmpty(username3) && _.isEmpty(password)) {
                auth.signup(username2, password2, remember, langued).then(result => dispatch({ type: USER_SIGNOUT }));;
            // } else {
            //     popup.open(t("onlysignin"));
            // }
        } else {
            popup.open(t("antibotwrong"));
        }
    }

    const signout = (event) => {
        event.preventDefault();
        if (auth.user) {
            auth.signout(auth.user._id).then(result => dispatch({ type: USER_SIGNOUT }));
        }
    }

    const passwordRecovery = (event) => {
        event.preventDefault();
        if (checkAntiBot(fac1, fac2)) {
            if (_.isEmpty(username) && _.isEmpty(password) && _.isEmpty(username2) && _.isEmpty(password2)) {
                dispatch(resetPassword({ email: username3, langue: langue })).then(ok => popup.open(t("passwordsent"))).catch(err => popup.open(err));
            } else {
                popup.open(t("onlypassword"));
            }
        } else {
            popup.open(t("antibotwrong"));
        }
    }

    return (

        <section className="user">
            <div className="user__language">
                <div className="user__language__radio" >
                    <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
                        onChange={() => setLangued('en')}
                        type="radio" name="langue" />
                    <label className="user__language__radio--label" htmlFor="en" ></label>
                    <span className="user__language__lg">English</span>
                </div>
                <div className="user__language__radio" >
                    <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
                        onChange={() => setLangued('fre')}
                        name="langue" />
                    <label className="user__language__radio--label" htmlFor="fr" ></label>
                    <span className="user__language__lg">Français</span>
                </div>
                <div className="user__language__radio" >
                    <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
                        onChange={() => setLangued('jap')}
                        name="langue" />
                    <label className="user__language__radio--label" htmlFor="jp" ></label>
                    <span className="user__language__lg">日本語</span>
                </div>
            </div>
            <div className="user__login">
                {auth.user === null &&
                    <div className="user__antibot" >
                        <div className="user__fac">{t("antibot")} &#128512;</div>
                        <div className="user__login__antibot" >
                            <input id="fac1" type="number" name="fac1" value={fac1} placeholder={0} onChange={(e) => setFac1(e.target.value)} className="user__login__inputfac" required />
                            <div className="user__fac" > + </div>
                            <input id="fac2" type="number" name="fac2" value={fac2} placeholder={0} onChange={(e) => setFac2(e.target.value)} className="user__login__inputfac" required />
                            <div className="user__fac" >= 7</div>
                        </div>
                    </div>
                }
                {auth.user === null &&
                    <form onSubmit={signin} className="user__login__form user__login__form--register">
                        <label htmlFor="email2">{t("email")}</label>
                        <input id="email2" type="email" name="email2" value={username2} placeholder={t("newuseremail")} onChange={(e) => setUsername2(e.target.value)} className="user__login__input" required />
                        <label htmlFor="password2">{t("password")}</label>
                        <input id="password2" type="password" name="password2" value={password2} placeholder={t("newuserpassword")} onChange={(e) => setPassword2(e.target.value)} className="user__login__input" required />
                        {t("rememberme")}
                        <div className="checkbox" >
                            <input id="remember" type="checkbox" value={remember} placeholder="Remember" onChange={(e) => setRemember(e.target.checked)} />
                            <label htmlFor="remember" >
                                <span className="box"></span>
                            </label>
                        </div>
                        <button type="submit" className="user__logout__button" >
                            {t("signup")}
                        </button>
                    </form>
                }
                {auth.user === null && <form onSubmit={login} className="user__login__form user__login__form--login" >
                    <label htmlFor="email">{t("email")}</label>
                    <input id="email" type="email" name="email" value={username} placeholder="Email" onChange={(e) => setUsername(e.target.value)} className="user__login__input" required />
                    <label htmlFor="password">{t("password")}</label>
                    <input id="password" type="password" name="password" value={password} placeholder={t("password")} onChange={(e) => setPassword(e.target.value)} className="user__login__input" required />
                    <button type="submit" className="user__logout__button" >
                        {t("login")}
                    </button>
                </form>
                }
                {auth.user === null &&
                    <form onSubmit={passwordRecovery} className="user__login__form">
                        <label htmlFor="email3">{t("email")}</label>
                        <input id="email3" type="email" name="email3" value={username3} placeholder="Email" onChange={(e) => setUsername3(e.target.value)} className="user__login__input" required />
                        <button type="submit" className="user__logout__button" >
                            {t("passwordrecovery")}
                        </button>
                    </form>
                }
                {auth.user !== null &&
                    <form onSubmit={signout} className="user__login__form" >
                        <button type="submit"
                            className="user__logout__button">
                            {t("logout")}
                        </button>
                    </form>}
            </div>
            <div className="user__info">
                {auth.user ? <UserInfo /> : ''}
            </div>
        </section>

    )
}

export default User
