import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { focusMot, selectFocusMot } from '../../redux/actions/texteActions';

function FocusTexte(props) {

    const dispatch = useDispatch();
    const [isFadingOut, setIsFadingOut] = useState(false);

    let [isSelected, setisSelected] = useState(props.isSelected);

    const toggleWord = () => {
        // setisSelected(!isSelected);
        dispatch(selectFocusMot(props._id)).catch((error) => alert("Texte.js 13 " + error));
    }

    useEffect(() => {
        setisSelected(props.isSelected);
        return () => {
        };
    }, [props.isSelected]);

    let classN = "mot__form mot__focus";

    return (

        <div className={isFadingOut ? 'mot-fadeout' : 'mot'}>
            <div className="mot__radio" >
                <input className="mot__radio--input" id={props._id} type="radio" name="word" checked={isSelected} onChange={() => toggleWord()} />
                <label className="mot__radio--label" htmlFor={props._id} >
                </label>
            </div>
            <div className={classN}>
                <div className="flexis" >
                    <span className="material-icons md-light pointer" onClick={() => {
                        setIsFadingOut(true);
                        setTimeout(() => dispatch(focusMot(props._id)), 500);
                    }}>remove_circle_outline</span>
                    <div className={props.font}>{props.name}</div>
                </div>
            </div>
        </div>
    )
}

export default FocusTexte