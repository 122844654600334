import React from "react";
let Spinner = require('react-spinkit');

const PopupMemoLoading = props => {
    return (
        <div className="popup"  >
            <div className="popup__notification">
                <div className="popup__notification__message">{props.content}</div>
                <div><Spinner name='cube-grid-bounce' /></div>
            </div>
        </div>
    );
};

export default PopupMemoLoading;