import React, { useEffect, useState } from "react";
import upload from "../../../images/PNG/upload-japanese.png";
import setting from "../../../images/PNG/reglage-upload-japanese.png";
import fileexemple from "../../../images/PNG/file-exemple.png";
import quizexplanation from "../../../images/PNG/quizexplanationenglish.webp";
import lessonstatus from "../../../images/PNG/lessonStatusEN.webp";
import focus1 from "../../../images/PNG/focus1En.webp";
import focus2 from "../../../images/PNG/focus2En.webp";
import imagesetting from "../../../images/PNG/imageSetting.webp";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLangue } from "../../../redux/actions/langueActions";

function Document() {

  const langueState = useSelector(state => state.langue);
  const { langue } = langueState;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langued, setLangued] = useState(langue);

  useEffect(() => {
    if (langued === 'jap') {
      i18n.changeLanguage('jap');
    } else if (langued === 'fre') {
      i18n.changeLanguage('fre');
    } else {
      i18n.changeLanguage('en');
    }
    dispatch(changeLangue(langued));
    return () => {
    };
  }, [langued]);

  return (
    <section className="doc">
      <section className="doc__container">

        <div className="user__language">
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
              onChange={() => setLangued('en')}
              type="radio" name="langue" />
            <label className="user__language__radio--label" htmlFor="en" ></label>
            <span className="user__language__lg">English</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
              onChange={() => setLangued('fre')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="fr" ></label>
            <span className="user__language__lg">Français</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
              onChange={() => setLangued('jap')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="jp" ></label>
            <span className="user__language__lg">日本語</span>
          </div>
        </div>
        <div id='top' className="doc__index">
          <div className="doc__short">
            <span className="material-icons md-24">schedule</span>
            <a href="#quick">
              <h2>アカウントなし、クイックスタート。</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">extension</span>
            <a href="#newfeaturequiz">
              <h2>New feature Quiz.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">description</span>
            <a href="#quickaccount">
              <h2>クイックスタート。</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">view_module</span>
            <a href="#lessonstatus">
              <h2>Lesson status.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">grade</span>
            <a href="#bookmark">
              <h2>Bookmark.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">add_photo_alternate</span>
            <a href="#image">
              <h2>How to set the image.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">content_copy</span>
            <a href="#file">
              <h2>どうやってレッスンをインポートするか。</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">build</span>
            <a href="#trouble">
              <h2>Troubleshooting.</h2>
            </a>
          </div>
        </div>
        <div id="quick" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">schedule</span>
            アカウントなし、クイックスタート。</h1>
          <br />
          <p>
            アカウント無しで,このビデオはアプリのコンセプトを紹介します。
          </p><p>
            サンプルデータでフラッシュカードを使用することができ、このツールを使って何ができるかわかります。
          </p>
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/4fiHTeBgixI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
        </div>
        <div id="newfeaturequiz" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">extension</span>New feature in Quiz.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            New features in the quiz.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image2"
            src={quizexplanation}
          />
        </div>
        <div id="quickaccount" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">description</span>クイックスタート。<a href="#top">⬆️</a></h1>
          <br />
          <p>
            このビデオではデータの作成について説明します。レッスンの作り方、勉強のセットアッ
            プ、単語のリストなど。
          </p>
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/EDxoopCck6E" title="Demo Logged" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
        </div>
        <div id="lessonstatus" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">view_module</span>Lesson status.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            You can set up to 3 differents status to your lessons and filter them. It will help you to organize your progression.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={lessonstatus}
          />
        </div>
        <div id="bookmark" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">grade</span>Bookmark.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            We always have some struggling words to remember. This bookmark list will be very helpful.<br />
            During practicing flashcards, you can bookmark the word...
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={focus1}
          />
          <br />
          <p>
            ...and retrieve it in the bookmark list.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={focus2}
          />
        </div>
        <div id="image" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">add_photo_alternate</span>How to set the image.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            You can set any image from the web. Just copy the link of the image in the image input.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={imagesetting}
          />
          <br />
        </div>
        <div id="file" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">content_copy</span>どうやってレッスンをインポートするか。<a href="#top">⬆️</a></h1>
          <br />
          <p>
            少なくとも1回はデータを書き込むことをお勧めしますが多くの単語を入力するのは面倒な場合があります。
            <br />購読者限定のインポート機能によりテキストファイルで簡単にレッスンを作成できます。
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image"
            src={upload}
          />
          <br />
          <p>
            この設定を例に使用してみましょう
          </p>
          <br />
          <img
            alt="Upload setting"
            className="doc__image"
            src={setting}
          />
          <br />
          <p>
            例えば、<strong>body.txt</strong>の「.txt」とテキストファイルを作成します。<br />
            このファイル名はレッスン名になります。<br />
            メモ帳などのテキストエディタでファイルを編集、ファイルの各行は新しい単語になります。<br />
            各設定ごとに---を区切り文字として使用、これが例としてのファイルです。
          </p>
          <br />
          <img
            alt="Upload setting"
            className="doc__image"
            src={fileexemple}
          />
          <br />
          <br />
          <h1>
            デモ
          </h1>
          <br />
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/643AiLLRy_o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
          <br />
          <p>
            この機能のおかげで、レッスンをすばやく作成できますが、同じ設定を使用して他の人とファイルを共有することもできます。
            <br />
            教材として使うこともでき、授業前に準備して生徒と共有する事も出来ます。
          </p>
        </div>
        <div id="trouble" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">build</span>Troubleshooting.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            You may sometimes encounter connection problems.<br />
            Here are two methods that will solve these problems.<br />
            1 - You can first log out and log in.<br />
            If the problem persists :<br />
            2 - Delete cookies for this site.<br /><br />
          </p>
        </div>

      </section>
    </section>
  );
}

export { Document };
