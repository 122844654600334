import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { sendEmail, updateEmail } from '../redux/actions/mailActions';
import { useAuth } from "./use-auth";
import { useTranslation } from "react-i18next";
import { usePopup } from "../components/popup/PopupContext";

function Mail() {

    const mailState = useSelector(state => state.email);

    const auth = useAuth();
    const popup = usePopup();

    const [email, setEmail] = useState(auth.user ? auth.user.email : '');
    const [name, setName] = useState(auth.user ? auth.user.name : '');
    const [title, setTitle] = useState(mailState.email.title);
    const [message, setMessage] = useState(mailState.email.message);

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const sendMail = (event) => {
        event.preventDefault();
        dispatch(sendEmail({ email: { email, name, title, message } })).then(ok => popup.open(t("emailsent"))).catch(err => popup.open(err));
    }

    useEffect(() => {
        dispatch(updateEmail({ email: { email, name, title, message } }));
        return () => {
            //
        };
    }, [email, name, title, message]);

    return (
        <div className="mail">
            <div className="mail__container">
                <form onSubmit={sendMail} className="mail__form">
                    <label htmlFor="email" className="mail__info" >{t("email")}</label>
                    <input id="email" type="email" name="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="mail__input" required />

                    <label htmlFor="name" className="mail__info" >{t("name")}</label>
                    <input id="name" type="text" name="name" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} className="mail__input" required />

                    <label htmlFor="title" className="mail__info" >{t("title")}</label>
                    <input id="title" type="text" name="title" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} className="mail__input" required />

                    <label htmlFor="message" className="mail__info" >{t("message")}</label>
                    <textarea id="message" rows="8" name="message" value={message} placeholder={t("message")} onChange={(e) => setMessage(e.target.value)} className="mail__textarea" />

                    <button type="submit" className="mail__button" >
                        {t("send")}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Mail

