import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedMot, selectConfig } from '../../redux/actions/texteActions';
import { getTranslateByConfig } from './utils';

function TranslateTexte(props) {

    const texte = useSelector(state => state.texte);
    const { motSelected } = texte;

    const [translate, setTranslate] = useState(props.translate);

    const dispatch = useDispatch();

    useEffect(() => {
        setTranslate(getTranslateByConfig(props._id, motSelected));
        return () => {
            //
        };
    }, [motSelected]);

    useEffect(() => {
        dispatch(updateSelectedMot(translate, props._id)).catch((error) => alert("TranslateTexte.js 23 " + error));
        return () => {
            //
        };
    }, [translate]);

    return (

        <div className="translate">
            <div className="translate__radio" >
                <input className="translate__radio--input" id={"translateRadio" + props._id} type="radio" name="translate" checked={props.isSelected} onChange={() => dispatch(selectConfig(props._id)).catch((error) => alert("TranslateTexte.js 33 " + error))} />
                <label className="translate__radio--label" htmlFor={"translateRadio" + props._id}>
                </label>
            </div>
            <div className="translate__formText">
                <div className="translate__formText__name">{props.name}</div>
                <textarea id={"translate" + props._id} type="text" row="6" value={translate} className="translate__formText__input" onChange={(e) => setTranslate(e.target.value)} />
            </div>
        </div>
    )
}

export default TranslateTexte