import axios from 'axios';
import {
    TEXTES_FAIL, TEXTES_REQUEST, TEXTES_SUCCESS, TEXTES_REMOVE_ITEM,
    TEXTE_LECON_SELECTED, TEXTE_LECON_UNSELECTED, TEXTE_SELECTED, TEXTE_UNSELECTED,
    TEXTE_SELECTED_UPDATE, TEXTE_SAVE, TEXTE_RESET,
    TEXTE_CONFIG_SELECTED, LECON_TEXTE_CREATE_WORD, LECON_TEXTE_REMOVE_WORD, LECON_TEXTE_UPDATE, TEXTE_LECON_SELECTED_BY_MOT,
    TEXTES_BANK_REQUEST, TEXTES_BANK_SUCCESS, TEXTES_BANK_CLEAN, TEXTE_UPDATE_BANK_WORD, TEXTE_CREATE_BANK_WORD, TEXTES_FOCUS, GAMETEXTE_ONLYFOCUS
} from '../actionTypes';
import configApp from '../../config';
import _ from 'lodash';
import { setGame } from './gameTexteActions';
import { toggle_onlyFocus } from './leconActions';

//get the list of words to refresh ( when change the configuration)
const listMots = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TEXTES_REQUEST });

        const { texte } = getState();
        const { lecons, configSelected, mots, loadingBank, bank } = texte;

        if (lecons && lecons.length !== 0) {
            if (loadingBank) {
                let lessons = [...lecons];
                const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", { configId: configSelected._id, lessons });
                dispatch({
                    type: TEXTES_SUCCESS, payload: data
                });
            } else {
                dispatch({
                    type: TEXTES_SUCCESS, payload: mots.map(mot => {
                        let motFromBank = _.find(bank, function (o) { return o._id === mot._id });
                        const foundConfig = _.find(motFromBank.names, function (c) { return c.cid === configSelected._id });
                        return {
                            '_id': mot._id, 'focus': mot.focus, 'name': foundConfig ? foundConfig.t : "---", 'lesson': mot.lesson
                        }
                    })
                });
            }
        } else {
            dispatch({ type: TEXTES_SUCCESS, payload: [] });
        }
    } catch (error) {
        dispatch({ type: TEXTES_FAIL, payload: error.message });
    }
}

const deleteMot = () => async (dispatch, getState) => {
    try {
        const { texte } = getState();

        let { motSelected } = texte;
        if (motSelected._id) {
            const data = await axios.delete(configApp.URL_BACKEND + "/api/word/" + motSelected._id);
            dispatch({ type: TEXTES_REMOVE_ITEM, payload: motSelected._id });
            dispatch({ type: LECON_TEXTE_REMOVE_WORD, payload: motSelected.lessun });
        } else {
            dispatch({ type: TEXTES_REMOVE_ITEM, payload: "-1" });
        }

    } catch (error) {
        throw error;
    }
}

const selectLeconTexte = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEXTES_REQUEST });
        dispatch({ type: TEXTES_BANK_REQUEST });

        const { texte, gameTexte } = getState();
        const { configSelected } = texte;
        const { onlyFocus } = gameTexte;

        if (onlyFocus) {
            dispatch(toggle_onlyFocus("texte", false));
        }

        let lessons = [_id];
        //load all the word
        axios.post(configApp.URL_BACKEND + "/api/word/lessun/bank", { lessons }).then(({ data }) => dispatch({ type: TEXTES_BANK_SUCCESS, payload: data }));

        const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", { configId: configSelected._id, lessons });

        dispatch({ type: TEXTE_LECON_SELECTED, payload: { _id, data } });
        dispatch(setGame());
    } catch (error) {
        throw error;
    }
}

const selectLeconTexteByWord = (word, type) => async (dispatch, getState) => {
    try {
        const ids = await axios.get(configApp.URL_BACKEND + "/api/lecon/word/" + word + "&" + type);

        const { texte, gameTexte } = getState();
        const { configSelected } = texte;
        const { onlyFocus } = gameTexte;

        if (onlyFocus) {
            dispatch(toggle_onlyFocus("texte", false));
        }

        let lessons = ids.data;
        //load all the word
        axios.post(configApp.URL_BACKEND + "/api/word/lessun/bank", { lessons }).then(({ data }) => dispatch({ type: TEXTES_BANK_SUCCESS, payload: data }));

        const { data } = await axios.post(configApp.URL_BACKEND + "/api/word/lessun/", { configId: configSelected._id, lessons });
        dispatch({ type: TEXTE_LECON_SELECTED_BY_MOT, payload: { ids: ids.data, data } });
        dispatch({ type: GAMETEXTE_ONLYFOCUS, payload: false });
        dispatch(setGame());
    } catch (error) {
        throw error;
    }
}

const unselectLeconTexte = (_id) => async (dispatch, getState) => {
    try {
        const { texte } = getState();
        const { lecons, mots, bank } = texte;
        const lessons = lecons.filter(x => x !== _id);

        dispatch({ type: TEXTE_LECON_UNSELECTED, payload: { _id, data: mots.filter(x => x.lesson !== _id) } });
        dispatch({ type: TEXTES_BANK_CLEAN, payload: bank.filter(x => x.lesson !== _id) });

        dispatch(setGame());

        if (lessons.length === 0) {
            dispatch(unselectMot());
        };

        const { motSelected } = texte;
        if (motSelected) {
            const motList = motSelected.lessun;
            let found = [];
            _.each(motList, function (id) {
                found.push(_.find(lessons, function (o) { return o === id }));
            });
            const res = _.filter(found, function (o) { return o !== undefined });
            if (res.length === 0) {
                dispatch(unselectMot());
            }
        }

    } catch (error) {
        throw error;
    }
}

const selectMot = (_id) => async (dispatch, getState) => {
    try {
        const { texte } = getState();
        const { bank, loadingBank } = texte;
        if (loadingBank) {
            const { data } = await axios.get(configApp.URL_BACKEND + "/api/word/" + _id);
            dispatch({ type: TEXTE_SELECTED, payload: data });
        } else {
            let word = _.find(bank, function (o) { return o._id === _id });
            dispatch({ type: TEXTE_SELECTED, payload: { _id: word._id, focus: word.focus, image: word.image, lessun: [word.lesson], mot: word.names.map(o => { return { config: o.cid, translate: o.t } }) } });
        }
    } catch (error) {
        throw error;
    }
}

const selectFocusMot = (_id) => async (dispatch, getState) => {
    try {
        const { texte } = getState();
        const { focus } = texte;
        let word = _.find(focus, function (o) { return o._id === _id });
        dispatch({ type: TEXTE_SELECTED, payload: { _id: word._id, focus: word.focus, image: word.image, lessun: [word.lesson], mot: word.names.map(o => { return { config: o.cid, translate: o.t } }) } });
    } catch (error) {
        throw error;
    }
}

const unselectMot = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEXTE_UNSELECTED, payload: null });
    } catch (error) {
        throw error;
    }
}

const resetMot = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TEXTE_RESET, payload: {} });
    } catch (error) {
        throw error;
    }
}

const updateSelectedMot = (value, configId) => async (dispatch, getState) => {
    try {
        const { texte } = getState();

        let { motSelected } = texte;
        if (motSelected && motSelected.mot) {
            let found = motSelected.mot.find(x => x.config === configId);
            if (found) {
                motSelected.mot = motSelected.mot.map(x => x.config === configId ? { translate: value, config: configId } : x);
            } else {
                motSelected.mot.push({ translate: value, config: configId });
            }
        } else {
            motSelected = {
                mot: [{ translate: value, config: configId }]
            };
        }

        dispatch({ type: TEXTE_SELECTED_UPDATE, payload: motSelected });

    } catch (error) {
        throw error;
    }
}

const saveMot = () => async (dispatch, getState) => {
    try {
        const { texte } = getState();
        let { motSelected, lecons, configSelected } = texte;

        if (motSelected._id) {
            let res = await axios.put(configApp.URL_BACKEND + "/api/word/" + motSelected._id, motSelected);
            dispatch({ type: TEXTE_SAVE, payload: motSelected });
            dispatch({ type: LECON_TEXTE_UPDATE, payload: motSelected.lessun });
            for (let index = 0; index < lecons.length; index++) {
                let wordBank = { _id: motSelected._id, image: motSelected.image, names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }), lesson: lecons[index] };
                if (_.isEmpty(configSelected)) {
                    dispatch({ type: TEXTE_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, image: motSelected.image, name: _.head(motSelected.mot).translate, lesson: lecons[index] } } });
                } else {
                    dispatch({ type: TEXTE_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, image: motSelected.image, name: _.head(motSelected.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), lesson: lecons[index] } } });
                }
            }
        } else {
            motSelected.lessun = lecons;
            let { data } = await axios.post(configApp.URL_BACKEND + "/api/word/", motSelected);
            dispatch({ type: TEXTE_SAVE, payload: data });
            dispatch({ type: LECON_TEXTE_CREATE_WORD, payload: lecons });
            for (let index = 0; index < lecons.length; index++) {
                let wordBank = { _id: data._id, image: data.image, names: data.mot.map(o => { return { 'cid': o.config, 't': o.translate } }), lesson: lecons[index] };
                if (_.isEmpty(configSelected)) {
                    dispatch({ type: TEXTE_CREATE_BANK_WORD, payload: { wordBank, mot: { _id: data._id, image: data.image, name: _.head(data.mot).translate, lesson: lecons[index] } } });
                } else {
                    dispatch({ type: TEXTE_CREATE_BANK_WORD, payload: { wordBank, mot: { _id: data._id, image: data.image, name: _.head(data.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), lesson: lecons[index] } } });
                }
            }
        }
        dispatch(setGame());
    } catch (error) {
        throw error;
    }
}

const focusMot = (_id) => async (dispatch, getState) => {
    try {
        const { texte } = getState();
        let { lecons, configSelected, focus } = texte;

        if (_id) {
            let { data } = await axios.put(configApp.URL_BACKEND + "/api/word/focus/" + _id, { _id: _id });
            let motSelected = data;
            for (let index = 0; index < lecons.length; index++) {
                let wordBank = { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }), lesson: lecons[index] };
                if (_.isEmpty(configSelected)) {
                    dispatch({ type: TEXTE_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot).translate, lesson: lecons[index] } } });
                } else {
                    dispatch({ type: TEXTE_UPDATE_BANK_WORD, payload: { wordBank, mot: { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), lesson: lecons[index] } } });
                }
            }

            if (motSelected.focus === '1') {
                if (_.isEmpty(configSelected)) {
                    let wordBank = { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot).translate, names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }) };
                    dispatch({ type: TEXTES_FOCUS, payload: [...focus, wordBank] });
                } else {
                    let wordBank = { _id: motSelected._id, focus: motSelected.focus, image: motSelected.image, name: _.head(motSelected.mot.filter(o => o.config === configSelected._id).map(x => x.translate)), names: motSelected.mot.map(o => { return { 'cid': o.config, 't': o.translate } }) };
                    dispatch({ type: TEXTES_FOCUS, payload: [...focus, wordBank] });
                }

            } else {
                dispatch({ type: TEXTES_FOCUS, payload: focus.filter(o => o._id !== motSelected._id) });
            }

        }
    } catch (error) {
        throw error;
    }
}

const listFocusMots = () => async (dispatch, getState) => {
    try {
        const { texte } = getState();
        const { configSelected, focus } = texte;

        dispatch({
            type: TEXTES_FOCUS, payload: focus.map(mot => {
                const foundConfig = _.find(mot.names, function (c) { return c.cid === configSelected._id });
                return {
                    ...mot, 'name': foundConfig ? foundConfig.t : "---"
                }
            })
        });
    } catch (error) {
        dispatch({ type: TEXTES_FAIL, payload: error.message });
    }
}

const selectConfig = (configId) => async (dispatch, getState) => {
    try {
        const { config } = getState();
        let { configs } = config;
        let found = configs.find(x => x._id === configId);

        dispatch({ type: TEXTE_CONFIG_SELECTED, payload: found });

        dispatch(listMots());
        dispatch(listFocusMots());

    } catch (error) {
        throw error;
    }
}

const listFocusTexte = (_id) => async (dispatch, getState) => {
    try {
        //load all the word
        axios.post(configApp.URL_BACKEND + "/api/word/focus", { _id, type: "texte" }).then(({ data }) => dispatch({ type: TEXTES_FOCUS, payload: data }));
    } catch (error) {
        throw error;
    }
}

export { selectFocusMot, listFocusTexte, focusMot, listMots, deleteMot, selectLeconTexte, unselectLeconTexte, selectMot, updateSelectedMot, saveMot, resetMot, selectConfig, unselectMot, selectLeconTexteByWord };