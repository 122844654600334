import React, { useEffect, useState } from "react";
import upload from "../../../images/PNG/upload.png";
import setting from "../../../images/PNG/reglage-upload.png";
import fileexemple from "../../../images/PNG/file-exemple.png";
import quizexplanation from "../../../images/PNG/quizexplanationfrench.webp";
import lessonstatus from "../../../images/PNG/lessonStatusFR.webp";
import print from "../../../images/PNG/print.webp";
import kanjilist from "../../../images/PNG/kanjiList.webp";
import jlpt from "../../../images/PNG/oneclick.webp";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLangue } from "../../../redux/actions/langueActions";
import focus1 from "../../../images/PNG/focus1En.webp";
import focus2 from "../../../images/PNG/focus2En.webp";
import imagesetting from "../../../images/PNG/imageSetting.webp";

function Document() {

  const langueState = useSelector(state => state.langue);
  const { langue } = langueState;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langued, setLangued] = useState(langue);

  useEffect(() => {
    if (langued === 'jap') {
      i18n.changeLanguage('jap');
    } else if (langued === 'fre') {
      i18n.changeLanguage('fre');
    } else {
      i18n.changeLanguage('en');
    }
    dispatch(changeLangue(langued));
    return () => {
    };
  }, [langued]);

  return (
    <section className="doc">
      <section className="doc__container">
        <div className="user__language">
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
              onChange={() => setLangued('en')}
              type="radio" name="langue" />
            <label className="user__language__radio--label" htmlFor="en" ></label>
            <span className="user__language__lg">English</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
              onChange={() => setLangued('fre')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="fr" ></label>
            <span className="user__language__lg">Français</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
              onChange={() => setLangued('jap')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="jp" ></label>
            <span className="user__language__lg">日本語</span>
          </div>
        </div>
        <div id='top' className="doc__index">
          <div className="doc__short">
            <span className="material-icons md-24">schedule</span>
            <a href="#quick">
              <h2>Quickstart sans compte.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">extension</span>
            <a href="#newfeaturequiz">
              <h2>Nouvelles fonctionalités.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">description</span>
            <a href="#quickaccount">
              <h2>Quickstart.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">view_module</span>
            <a href="#lessonstatus">
              <h2>Statuts des leçons.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">grade</span>
            <a href="#bookmark">
              <h2>Bookmark.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">add_photo_alternate</span>
            <a href="#image">
              <h2>Comment définir l'image.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">print</span>
            <a href="#print">
              <h2>Comment imprimer les images ou Kanji.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">filter_9_plus</span>
            <a href="#jlpt">
              <h2>Comment importer les JLPT.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">content_copy</span>
            <a href="#file">
              <h2>Comment importer des leçons.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">build</span>
            <a href="#trouble">
              <h2>Dépannage.</h2>
            </a>
          </div>
        </div>
        <div id="quick" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">schedule</span>
            Quickstart sans compte.</h1>
          <br />
          <p>
            Cette vidéo vous présentera le concept de cette application et celà sans avoir à créer de compte.
          </p><p>
            Vous pourrez utiliser les flashcards avec les données exemples et comprendre le potentiel que va vous offrir cet outil.
          </p>
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/92m0By59SXI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
        </div>
        <div id="newfeaturequiz" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">extension</span>
            Nouvelles fonctionalités dans les quiz.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Nouvelles fonctionalités dans les quiz.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image2"
            src={quizexplanation}
          />
        </div>
        <div id="quickaccount" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">description</span>Quickstart.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Cette vidéo vous guidera dans la création de vos données. Comment créer vos lecons, votre configuration d'étude, vos listes de mot.
          </p>
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/EDxoopCck6E" title="Demo Logged" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
        </div>
        <div id="lessonstatus" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">view_module</span>Statuts des leçons.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Vous pouvez définir jusqu'à 3 statuts différents pour vos leçons et les filtrer. Cela vous aidera à organiser votre progression.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={lessonstatus}
          />
        </div>
        <div id="bookmark" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">grade</span>Bookmark.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Nous avons toujours des mots difficiles à retenir. Cette liste de signets vous sera très utile.<br />
            Pendant la pratique des flashcards, vous pouvez mettre le mot en signet...
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={focus1}
          />
          <br />
          <p>
            ... et le retrouver dans la liste des favoris.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={focus2}
          />
        </div>
        <div id="image" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">add_photo_alternate</span>Comment définir l'image.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Vous pouvez définir n'importe quelle image du web. Vous avez juste à copier le lien de l'image dans le champs image.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={imagesetting}
          />
          <br />
        </div>
        <div id="print" className="doc__section">
          <br /><h1><span className="material-icons md-24">print</span>Imprimer les images ou Kanji.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Vous pouvez imprimer jusqu'à 16 images ou Kanji pour exercer votre écriture.
          </p>
          <p>
            Sélectionnez les mots et cliquez sur l'icône de l'imprimante. Optimisé pour le format A4.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={print}
          />
          <p>
            Une nouvelle fenêtre apparaitra avec les images ou Kanji.
          </p>
          <p>
            Vous n'aurez plus qu'à les imprimer avec les options de votre browser.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={kanjilist}
          />
        </div>
        <div id="jlpt" className="doc__section">
          <br /><h1><span className="material-icons md-24">filter_9_plus</span>Comment importer le JLPT.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Dans l'onglet rêglage, vous pouvez sélectionner la langue que vous voulez utiliser<br />
            Si vous choissisez l'anglais et français, l'application créera cette configuration :
          </p>
          <br />
          <div className="doc__list">
            <ul>
              <li>Français</li>
              <li>Anglais</li>
              <li>Japonais Kanji</li>
              <li>Furigana</li>
              <li>Romanji</li>
            </ul>
          </div>
          <br />
          <p>
            Si vous choissisez l'anglais, l'application créera cette configuration :
          </p>
          <br />
          <div className="doc__list">
            <ul>
              <li>Anglais</li>
              <li>Japonais Kanji</li>
              <li>Furigana</li>
              <li>Romanji</li>
            </ul>
          </div>
          <br />
          <p>
            Si vous choissisez le français, l'application créera cette configuration :
          </p>
          <br />
          <div className="doc__list">
            <ul>
              <li>Français</li>
              <li>Japonais Kanji</li>
              <li>Furigana</li>
              <li>Romanji</li>
            </ul>
          </div>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={jlpt}
          />
          <br />
        </div>
        <div id="file" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">content_copy</span>Comment importer une ou plusieurs leçons.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Même si il est conseillé d'écrire au moins une fois vos données, Il peut parfois être fastidieux de les saisir si elles sont nombreuses.
            <br />
            Avec la fonction import, disponible uniquement aux abonnés, vous pourrez facilement créer des leçons avec un fichier texte.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image"
            src={upload}
          />
          <br />
          <p>
            Utilisons ce rêglage comme exemple:
          </p>
          <br />
          <img
            alt="Upload setting"
            className="doc__image"
            src={setting}
          />
          <br />
          <br />
          <p>
            1 - Créer un fichier texte ".txt" comme par exemple <strong>body.txt</strong><br />
            Le nom de ce fichier sera le nom de la leçon à importer.<br />
            2 - Editer le fichier avec un éditeur de texte comme notepad.<br />
            Chaque ligne sera un nouveau mot.<br />
            Pour chaque configuration, vous utiliserez --- comme séparateur.<br />
            Voici un fichier comme exemple pour vous aider.
          </p>
          <br />
          <img
            alt="Upload setting"
            className="doc__image"
            src={fileexemple}
          />
          <br />
          <br />
          <h1>
            Démonstration
          </h1>
          <br />
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/MFn-BUbflH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
          <br />
          <br />
          <p>
            Grâce à cette fonctionnalité, vous pourrez plus rapidement créer vos leçons mais vous pourrez aussi partager vos fichiers avec d'autres personnes qui partagerait la même configuration.
            <br />
            Dans une classe, un professeur pourrait aussi préparer les informations et les donner à ses élèves.
          </p>
        </div>
        <div id="trouble" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">build</span>Dépannage.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Vous pourrez parfois rencontrer des problêmes de connections.<br />
            Voici deux méthodes qui résoudront ces problêmes.<br />
            1 - Vous pourrez d'abord juste faire un logout et login.<br />
            Si le problême persiste :<br />
            2 - Supprimer les cookies pour ce site.<br /><br />
          </p>
        </div>
      </section>
    </section>
  );
}

export { Document };
