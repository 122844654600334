import React from 'react'
import { useAuth } from "../use-auth";
import { useTranslation } from "react-i18next";
import PayPalComponent from '../PayPalComponent';

function UserInfoPayment(props) {

    const { t } = useTranslation();

    const subscribe = (event) => {
        event.preventDefault();
        props.handleClose();
        props.openStripe(true);
    }

    return (

        <div className="popup__paiement">
            <div className="popup__paiement__container">

                <form onSubmit={subscribe} className="user__login__form">
                    <button type="submit" className="user__logout__button" >
                        {t("card")}
                    </button>
                </form>
                <div className="popup__paiement__paypal">
                    <PayPalComponent />
                </div>
                <button type="button" className="popup__paiement__button" onClick={props.handleClose}>
                    {t("close")}
                </button>
            </div>
        </div>
    )
}

export default UserInfoPayment
