import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  genereRandomNumber,
  selectFromDecrement,
  selectFromIncrement,
  selectToDecrement,
  selectToIncrement,
  setAttempt,
  setMulti,
} from "../../../redux/actions/gameActions";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { reducerReactPosition } from "../reducerReactPosition";
import GameInfo from "../GameInfo";
import GameBarAction from "../GameBarAction";
import GameInsideFromToNav from "../GameInsideFromToNav";
import { GAME_MULTI } from "../../../redux/actionTypes";
import { focusMot } from "../../../redux/actions/motActions";

function FlashcardMulti() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mot = useSelector((state) => state.mot);
  const { lecons, mots } = mot;

  const game = useSelector((state) => state.game);
  const { from, multi, onlyFocus } = game;

  let fontUI = multi.font === "no" || multi.font === undefined ? "flashcardmulti__guess" : multi.font;

  const getToGuessImage = () => {
    if (mots && mots.length > 1 && mots.length === multi.randoms.length) {
      return {
        image: multi.guess
      };

    }
    return { translate: "---" };
  };

  const getClass = (mot) => {
    if (multi.solution) {
      if (mot === multi.answer) {
        return "flashcardmulti__solution";
      }
    }
    if (multi.attempt === -1) {
      return "flashcardmulti__proposal";
    }

    if (multi.attempt.t === mot) {
      if (multi.attempt.t === multi.answer) {
        return "flashcardmulti__solution";
      } else {
        return "flashcardmulti__wrong";
      }
    }
    return "flashcardmulti__proposal";
  };

  const showSolution = () => {
    dispatch(setMulti({ ...multi, solution: true }));
  };

  const changeFontUI = () => {
    if (fontUI === "flashcardmulti__guess") {
      dispatch({ type: GAME_MULTI, payload: { ...multi, font: "flashcardmulti__guess bigfont" } });
    } else {
      dispatch({ type: GAME_MULTI, payload: { ...multi, font: "flashcardmulti__guess" } });
    }
  }

  const getFocus = () => {
    if (mots && mots.length > 1 && mots.length === multi.randoms.length) {
      return mots[multi.randoms[multi.position]].focus;
    }
    return '0';
  };

  return (
    <section className="flashcardmulti">
      <GameInfo mots={mots.length} lecons={lecons.length} position={multi.position} />

      {from === 'image' && getToGuessImage().image ?
        <img className="imageMot"
          src={getToGuessImage().image}
          alt="new"
        />
        :
        <div className={fontUI}>{multi.guess}</div>
      }

      <GameInsideFromToNav guess={multi.guess}  onlyFocus={onlyFocus} isfocus={getFocus()} changeFont={changeFontUI} selectFromDecrement={selectFromDecrement} selectFromIncrement={selectFromIncrement} focus={() =>
        reducerReactPosition("focus", mots, dispatch, multi, focusMot)
      } />

      <ul className="flashcardmulti__answer">
        {multi.choices.map((mot, index) => (
          <li
            key={index}
            onMouseDown={() => {
              dispatch(setAttempt({ ...multi, attempt: mot }));
            }}
            className="flashcardmulti__choice"
          >
            <div className={getClass(mot.t)}>{mot.t}</div>
          </li>
        ))}
      </ul>
      <GameBarAction
        selectToDecrement={selectToDecrement}
        selectToIncrement={selectToIncrement}
        goLeft={() =>
          reducerReactPosition("decrement", mots, dispatch, multi, setMulti)
        }
        goRight={() =>
          reducerReactPosition("increment", mots, dispatch, multi, setMulti)
        }
        reStart={() => dispatch(genereRandomNumber("multi"))}
        showSolution={() => showSolution()}
        focus={() =>
          reducerReactPosition("focus", mots, dispatch, multi, focusMot)
        }

      />
    </section>
  );
}

export default FlashcardMulti;
