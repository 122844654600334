import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

const PopupPrint = props => {

    const { t } = useTranslation();

    const [image, setImage] = useState(false);
    const [size, setSize] = useState(false);

    const save = (event) => {
        event.preventDefault();
        props.handleSave(image, size);
        props.handleClose();
    }

    return (
        <div className="popup__print"  >

            <form onSubmit={save} className="popup__print__form">

                <div className="popup__print__container">

                    <label id="icon" className="popup__print__label" htmlFor="name"><i className="icon-user"></i>{t("image")}:</label>
                    <div className="checkbox" >
                        <input id="img" type="checkbox" checked={image} onChange={(e) => setImage(e.target.checked)} />
                        <label htmlFor="img" >
                            <span className="box"></span>
                        </label>
                    </div>
                    <label id="icon" className="popup__print__label" htmlFor="name"><i className="icon-user"></i>{t("format")}:</label>
                    <div className="checkbox" >
                        <input id="siz" type="checkbox" checked={size} onChange={(e) => setSize(e.target.checked)} />
                        <label htmlFor="siz" >
                            <span className="box"></span>
                        </label>
                    </div>

                </div>
                <div className="popup__print__container--button">
                    <button type="button"
                        className="popup__print__button"
                        onClick={props.handleClose}>
                        {t("cancel")}
                    </button>
                    <button type="submit"
                        className="popup__print__button"
                    >
                        {t("print")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PopupPrint;