import {
    USER_SIGNOUT, LECONS_FAIL, LECONS_REQUEST, LECONS_SUCCESS, LECON_REMOVE_ITEM,
    LECON_CREATE_ITEM, LECON_CHANGE_ITEM, LECON_CREATE_WORD, LECON_REMOVE_WORD, LECON_VISIBLE, LECON_FAVORITE,
    LECONS_TEXTE_SUCCESS, LECON_TEXTE_REMOVE_ITEM, LECON_TEXTE_CREATE_ITEM, LECON_TEXTE_CREATE_WORD, LECON_TEXTE_REMOVE_WORD, LECON_TEXTE_CHANGE_ITEM,
    LECON_MOT_UPDATE, LECON_TEXTE_UPDATE, LECON_TEXTE_FAVORITE, LECON_MOT_FAVORITE
} from "../actionTypes";
import _ from 'lodash';

function leconReducers(state = {
    lecons: [], textes: [],
    word_visible: true, texte_visible: true,
    word_progression: false, texte_progression: false,
    word_completed: false, texte_completed: false,
    word_favorite: false, texte_favorite: false
}, action) {
    switch (action.type) {
        case USER_SIGNOUT:
            return {
                lecons: [], textes: [], loading: true,
                word_progression: false, texte_progression: false,
                word_completed: false, texte_completed: false,
                word_visible: true, texte_visible: true,
                word_favorite: false, texte_favorite: false
            };
        case LECONS_REQUEST:
            return { ...state, loading: true };
        case LECONS_SUCCESS:
            return { ...state, loading: false, lecons: action.payload };
        case LECONS_TEXTE_SUCCESS:
            return { ...state, loading: false, textes: action.payload };
        case LECONS_FAIL:
            return { ...state, loading: false, error: action.payload };
        case LECON_REMOVE_ITEM:
            return { ...state, lecons: state.lecons.filter(x => x._id !== action.payload) };
        case LECON_CREATE_ITEM:
            return { ...state, lecons: _.orderBy([...state.lecons, action.payload], ['last'], ['desc']) };
        case LECON_CREATE_WORD:
            let newWordsListAdd = state.lecons.map(x => action.payload.includes(x._id) ? { ...x, mots: x.mots + 1, last: new Date().toISOString() } : x);
            newWordsListAdd = _.orderBy(newWordsListAdd, ['last'], ['desc']);
            return {
                ...state, lecons: newWordsListAdd
            };
        case LECON_REMOVE_WORD:
            let newWordsListRem = state.lecons.map(x => action.payload.includes(x._id) ? { _id: x._id, mots: x.mots - 1, status: x.status, name: x.name, user: x.user, type: x.type, last: new Date().toISOString() } : x);
            newWordsListRem = _.orderBy(newWordsListRem, ['last'], ['desc']);
            return {
                ...state, lecons: newWordsListRem
            };
        case LECON_CHANGE_ITEM:
            return {
                ...state, lecons: _.orderBy(state.lecons.map(x => x._id !== action.payload._id ? x : action.payload), ['last'], ['desc'])
            };
        case LECON_MOT_FAVORITE:
            return {
                ...state, lecons: state.lecons.map(x => x._id !== action.payload._id ? x : action.payload)
            };
        case LECON_TEXTE_REMOVE_ITEM:
            return { ...state, textes: state.textes.filter(x => x._id !== action.payload) };
        case LECON_TEXTE_CREATE_ITEM:
            return { ...state, textes: _.orderBy([...state.textes, action.payload], ['last'], ['desc']) };
        case LECON_TEXTE_CREATE_WORD:
            let newTextesListAdd = state.textes.map(x => action.payload.includes(x._id) ? { ...x, mots: x.mots + 1, last: new Date().toISOString() } : x);
            newTextesListAdd = _.orderBy(newTextesListAdd, ['last'], ['desc']);
            return {
                ...state, textes: newTextesListAdd
            };
        case LECON_TEXTE_REMOVE_WORD:
            let newTextesListRem = state.textes.map(x => action.payload.includes(x._id) ? { ...x, mots: x.mots - 1, last: new Date().toISOString() } : x);
            newTextesListRem = _.orderBy(newTextesListRem, ['last'], ['desc']);
            return {
                ...state, textes: newTextesListRem
            };
        case LECON_TEXTE_CHANGE_ITEM:
            return {
                ...state, textes: _.orderBy(state.textes.map(x => x._id !== action.payload._id ? x : action.payload), ['last'], ['desc'])
            };
        case LECON_TEXTE_FAVORITE:
            return {
                ...state, textes: state.textes.map(x => x._id !== action.payload._id ? x : action.payload)
            };
        case LECON_VISIBLE:
            if (action.payload.type === 'word') {
                return { ...state, word_visible: action.payload.visibility };
            } else {
                return { ...state, texte_visible: action.payload.visibility };
            }
        case LECON_FAVORITE:
            if (action.payload.type === 'word') {
                return { ...state, word_favorite: action.payload.favorite, word_progression: action.payload.progression, word_completed: action.payload.completed };
            } else {
                return { ...state, texte_favorite: action.payload.favorite, texte_progression: action.payload.progression, texte_completed: action.payload.completed };
            }
        case LECON_TEXTE_UPDATE:
            let newTextesListUpd = state.textes.map(x => action.payload.includes(x._id) ? { ...x, last: new Date().toISOString() } : x);
            newTextesListUpd = _.orderBy(newTextesListUpd, ['last'], ['desc']);
            return {
                ...state, textes: newTextesListUpd
            };
        case LECON_MOT_UPDATE:
            let newMotsListUpd = state.lecons.map(x => action.payload.includes(x._id) ? { ...x, last: new Date().toISOString() } : x);
            newMotsListUpd = _.orderBy(newMotsListUpd, ['last'], ['desc']);
            return {
                ...state, lecons: newMotsListUpd
            };
        default:
            return state;
    }
}

export { leconReducers }