import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {
    Switch,
    Route,
    NavLink,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import IconSite from '../../IconSite';
import _ from 'lodash';
import FlashcardSelf from './FlashcardSelf';
import FlashcardMulti from './FlashcardMulti';
import FlashcardWrite from './FlashcardWrite';

import { chooseGame } from '../../../redux/actions/gameActions';

function Flashcards() {

    const game = useSelector(state => state.game);
    const { game_choice } = game;

    let [gameChoose, setGameChoose] = useState(game_choice);

    const dispatch = useDispatch();

    let { path, url } = useRouteMatch();

    const showSolution = (game) => {
        dispatch(chooseGame(game));
        setGameChoose(game);
    }

    return (

        <section className="flashcards" >

            <ul className="flashcards__menu" >
                <li >
                    <NavLink to={`${url}/flashcardSelf`} onClick={() => showSolution("/flashcardSelf")}>
                        {gameChoose === "/flashcardSelf" ? <span className="material-icons green md-36">done_outline</span>
                            : <span className="material-icons md-36 md-light">done_outline</span>
                        }
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`${url}/flashcardMult`} onClick={() => showSolution("/flashcardMult")}>
                        {gameChoose === "/flashcardMult" ? <span className="material-icons green md-36">grading</span>
                            : <span className="material-icons md-36 md-light">grading</span>
                        }
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`${url}/flashcardWrite`} onClick={() => showSolution("/flashcardWrite")} >
                        {gameChoose === "/flashcardWrite" ? <span className="material-icons green md-36">border_color</span>
                            : <span className="material-icons md-36 md-light">border_color</span>
                        }
                    </NavLink>
                </li>
            </ul>

            <Switch>
                <Route exact path={path}>
                    {game_choice === '/flashcardMult' ? <Redirect to={`${path}/flashcardMult`} /> :
                        game_choice === '/flashcardLink' ? <Redirect to={`${path}/flashcardLink`} /> :
                            game_choice === '/flashcardWrite' ? <Redirect to={`${path}/flashcardWrite`} /> :
                                <Redirect to={`${path}/flashcardSelf`} />
                    }
                </Route>
                <Route path={`${path}/flashcardSelf`}>
                    <FlashcardSelf />
                </Route>
                <Route path={`${path}/flashcardMult`}>
                    <FlashcardMulti />
                </Route>
                <Route path={`${path}/flashcardLink`}>
                    Link
                </Route>
                <Route path={`${path}/flashcardWrite`}>
                    <FlashcardWrite/>
                </Route>
            </Switch>

        </section>
    )
}

export default Flashcards
