import axios from 'axios';
import { MAIL_MESSAGE, MAIL_UPDATE } from '../actionTypes';
import configApp from '../../config';

const sendEmail = (email) => async (dispatch, getState) => {
    try {
        const { data } = await axios.post(configApp.URL_BACKEND + "/api/mail/send", email);
        dispatch({ type: MAIL_MESSAGE, payload: email });
    } catch (error) {
        throw error;
    }
}

const updateEmail = (email) => async (dispatch, getState) => {
    dispatch({ type: MAIL_UPDATE, payload: email });
}

const resetPassword = (email) => async (dispatch, getState) => {
    try {
        await axios.post(configApp.URL_BACKEND + "/api/mail/password", email);
    } catch (error) {
        throw error;
    }
}


const confirmSubscription = (email) => async (dispatch, getState) => {
    try {
        await axios.post(configApp.URL_BACKEND + "/api/mail/subscription", email);
    } catch (error) {
        throw error;
    }
}


const confirmSubscriptionPaypal = (email) => async (dispatch, getState) => {
    try {
        await axios.post(configApp.URL_BACKEND + "/api/mail/subscriptionpaypal", email);
    } catch (error) {
        throw error;
    }
}


const confirmResiliation = (email) => async (dispatch, getState) => {
    try {
        await axios.post(configApp.URL_BACKEND + "/api/mail/resiliation", email);
    } catch (error) {
        throw error;
    }
}

export { sendEmail, updateEmail, resetPassword, confirmSubscription, confirmResiliation, confirmSubscriptionPaypal };