import {
    MAIL_MESSAGE, MAIL_UPDATE
} from "../actionTypes";

function mailReducers(state = { email: { email: '', name: '', title: '', message: '' } }, action) {
    switch (action.type) {
        case MAIL_MESSAGE:
            return { ...action.payload };
        case MAIL_UPDATE:
            return { ...action.payload };
        default:
            return state;
    }
}

export { mailReducers }