import React from "react";

function SubcriptionFree() {
  return (
    <section className="subcriptionfree">
      <h1>無料</h1>
      <ul>
        <li>ワードモードとテキストモードの設定を作成。</li>
        <li>レッスンを作成、変更、削除します。</li>
        <li>単語やテキストを作成、変更、削除します。</li>
        <li>テキストファイルを介して、レッスンをインポートします。</li>
        <li>全てのフラッシュカードスタイルを使用。</li>
        {/* <li>
        契約による縛りなし<br/>ワンクリックでいつでも退会できます
          <br/>開始月ごとに期限があります。
        </li> */}
      </ul>
    </section>
  );
}

export { SubcriptionFree };
