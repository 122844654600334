import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { listConfigs } from '../../redux/actions/configActions';
import { listVisitorConfigs } from '../../redux/actions/visitorActions';
import _ from 'lodash';
import IconSite from '../IconSite';
import { useAuth } from "../use-auth";
import { useTranslation } from "react-i18next";

function FromTo(props) {

    const auth = useAuth();

    const { t } = useTranslation();

    const config = useSelector(state => state.config);
    const { configs, loading, error } = config;

    let listClass = 'fromtos__list';
    let iconClass = 'icon-resize-100';

    if (!props.rstate.visible_from_to) {
        listClass = 'fromtos__list--visible';
        iconClass = 'icon-resize-full-screen';
    }

    const dispatch = useDispatch();

    return (
        loading ?
            <section className="fromtos" >
            </section>
            : error ? <div>{error}</div> :
                <form className="fromtos">
                    <div className="fromtos__menu">
                        <span>{t("gameconfig")}</span>
                        <button type="button" className="icon-button" onClick={() => dispatch(props.toogle_from_to(!props.rstate.visible_from_to))}>
                            <IconSite name={iconClass} cssname="icon" />
                        </button>
                    </div>
                    <div className="fromtos__container">
                        <ul className={listClass}>
                            {
                                configs.filter(o => o.type === props.type && o.isEnable === true ).map((config) =>
                                    <li key={config._id} className="fromtos__item">
                                        <div className="fromto">
                                            <div className="fromto__radio" >
                                                <input className="fromto__radio--input" id={"from" + config._id} type="radio" name="from" checked={props.rstate.from && props.rstate.from._id === config._id ? true : false} onChange={() => dispatch(props.selectFrom(config))} />
                                                <label className="fromto__radio--label" htmlFor={"from" + config._id} >
                                                </label>
                                            </div>
                                            <div className="fromto__form">
                                                <div className="fromto__form__name">{config.name}</div>
                                            </div>
                                            <div className="fromto__radio" >
                                                <input className="fromto__radio--input" id={"to" + config._id} type="radio" name="to" checked={props.rstate.to && props.rstate.to._id === config._id ? true : false} onChange={() => dispatch(props.selectTo(config))} />
                                                <label className="fromto__radio--label" htmlFor={"to" + config._id}>
                                                </label>
                                            </div>
                                            <div className="fromto__form">
                                                <div className="fromto__form__name">{config.name}</div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                            {
                                props.type === "word" &&
                                <li key="image" className="fromtos__item">
                                    <div className="fromto">
                                        <div className="fromto__radio" >
                                            <input className="fromto__radio--input" id={"fromImage"} type="radio" name="from" checked={props.rstate.from && props.rstate.from === "image" ? true : false} onChange={() => dispatch(props.selectFrom('image'))} />
                                            <label className="fromto__radio--label" htmlFor={"fromImage"} >
                                            </label>
                                        </div>
                                        <div className="fromto__form">
                                            <div className="fromto__form__name">Image</div>
                                        </div>
                                        <div className="fromto__radio" >
                                        </div>
                                        <div className="fromto__form">
                                        </div>
                                    </div>
                                </li>

                            }
                        </ul>
                    </div>
                    {auth.user === null &&
                        <div className="tuto tuto--fromto">
                            <p>{t("chosefrom")}</p>
                            <p>{t("choseto")}</p>
                            <p className="tuto__help tuto__help--choose">{t("trick")}</p>
                        </div>}
                </form>
    )
}

export default FromTo