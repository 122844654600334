import React from "react";
import { useTranslation } from "react-i18next";
import { Document as DocumentEN }  from "./EN/Document";
import { Document as DocumentFR } from "./FR/Document";
import { Document as DocumentJP } from "./JP/Document";

function Document() {
  const { t } = useTranslation();

  const language = t("language");

  return (
    <>
      {language === "JP" && <DocumentJP />}
      {language === "EN" && <DocumentEN />}
      {language === "FR" && <DocumentFR />}
    </>
  );
}

export default Document;