import React, { useEffect, useState } from "react";

function Policy() {

  const [allowVideo, setAllowVideo] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  useEffect(() => {
    setAllowVideo(true);
  }, []);

  const videoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <section className="policy">
      <div className="policy__container">

        <div id='top' className="doc__index">
          <div className="doc__short">
            <span className="material-icons md-24">policy</span>
            <a href="#privacy">
              <h1>memodeep.comのプライバシーポリシー。</h1>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">copyright</span>
            <a href="#term">
              <h1>利用規約。</h1>
            </a>
          </div>
        </div>
        <h1>memodeep.comのプライバシーポリシー</h1>

        <p> memodeep.comからアクセスできるmemodeep.comでは、私たちの主な優先事項の1つは訪問者のプライバシーです。このプライバシーポリシー文書には、memodeep.comによって収集および記録される情報の種類と、その使用方法が含まれています。</p>

        <p>プライバシーポリシーについてさらに質問がある場合、または詳細が必要な場合は、遠慮なくお問い合わせください。</p>

        <p>このプライバシーポリシーは、当社のオンラインアクティビティにのみ適用され、memodeep.comで共有および/または収集した情報に関して、当社のWebサイトへの訪問者に有効です。このポリシーは、オフラインまたはこのWebサイト以外のチャネルを介して収集された情報には適用されません。私たちのプライバシーポリシーは、
          <a href="https://h-supertools.com/web/privacy-policy-generator/"> H-supertoolsプライバシーポリシージェネレーター</a>。</p>

        <h2>同意</h2>

        <p>当社のウェブサイトを使用することにより、お客様は当社のプライバシーポリシーに同意し、その条件に同意するものとします。</p>

        <h2>収集する情報</h2>

        <p>提供を求められた個人情報、および提供を求められた理由は、個人情報の提供を求められた時点で明らかになります。</p>
        <p>直接お問い合わせいただくと、お名前、メールアドレス、電話番号、送信するメッセージや添付ファイルの内容、その他の情報など、お客様に関する追加情報を受け取る場合があります。 。</p>
        <p>アカウントに登録すると、名前、会社名、住所、メールアドレス、電話番号などの連絡先情報を求められる場合があります。</p>

        <h2>お客様の情報の使用方法</h2>

        <p>収集した情報は、次のようなさまざまな方法で使用されます。</p>

        <ul>
          <li>当社のウェブサイトを提供、運営、維持する</li>
          <li>当社のウェブサイトを改善、パーソナライズ、拡張する</li>
          <li>当社のウェブサイトの使用方法を理解して分析する</li>
          <li>新しい製品、サービス、機能、機能を開発する</li>
          <li>カスタマーサービスを含め、直接またはパートナーの1つを介して、ウェブサイトに関連する最新情報やその他の情報を提供するため、およびマーケティングやプロモーションの目的で、お客様と連絡を取ります。</li>
          <li>メールを送信</li>
          <li>詐欺を見つけて防止する</li>
        </ul>

        <h2>ログファイル</h2>

        <p> memodeep.comは、ログファイルを使用する標準的な手順に従います。これらのファイルは、訪問者がWebサイトにアクセスしたときにログに記録します。すべてのホスティング会社がこれを行い、ホスティングサービスの分析の一部を行います。ログファイルによって収集される情報には、インターネットプロトコル（IP）アドレス、ブラウザーの種類、インターネットサービスプロバイダー（ISP）、日付とタイムスタンプ、参照/終了ページ、および場合によってはクリック数が含まれます。これらは、個人を特定できる情報にはリンクされていません。この情報の目的は、傾向の分析、サイトの管理、Webサイトでのユーザーの動きの追跡、および人口統計情報の収集です。</p>

        <h2> CookieとWebビーコン</h2>

        <p>他のウェブサイトと同様に、memodeep.comは「Cookie」を使用します。これらのCookieは、訪問者の好みや、訪問者がアクセスまたは訪問したWebサイト上のページなどの情報を保存するために使用されます。この情報は、訪問者のブラウザの種類やその他の情報に基づいてWebページのコンテンツをカスタマイズすることにより、ユーザーエクスペリエンスを最適化するために使用されます。</p>

        <h2> Google DoubleClick DART Cookie </h2>

        <p> Googleは、当サイトのサードパーティベンダーの1つです。また、DART Cookieと呼ばれるCookieを使用して、www.website.comやインターネット上の他のサイトへのアクセスに基づいてサイト訪問者に広告を配信します。ただし、訪問者は、次のURLにあるGoogle広告およびコンテンツネットワークのプライバシーポリシーにアクセスして、DARTCookieの使用を拒否することを選択できます– <a href="https://policies.google.com/technologies/ads"> https：/ /policies.google.com/technologies/ads </a> </p>

        <h2>当社の広告パートナー</h2>

        <p>当サイトの一部の広告主は、CookieおよびWebビーコンを使用する場合があります。当社の広告パートナーは以下のとおりです。当社の各広告パートナーには、ユーザーデータに関するポリシーについて独自のプライバシーポリシーがあります。アクセスを容易にするために、以下のプライバシーポリシーにハイパーリンクしました。</p>

        <ul>
          <li>
            <p> Google </p>
            <p> <a href="https://policies.google.com/technologies/ads"> https://policies.google.com/technologies/ads </a> </p>
          </li>
        </ul>

        <h2>広告パートナーのプライバシーポリシー</h2>

        <p>このリストを参照して、memodeep.comの各広告パートナーのプライバシーポリシーを見つけることができます。</p>

        <p>サードパーティの広告サーバーまたは広告ネットワークは、ユーザーのブラウザに直接送信されるmemodeep.comに表示されるそれぞれの広告およびリンクで使用されるCookie、JavaScript、またはWebビーコンなどのテクノロジーを使用します。これが発生すると、彼らは自動的にあなたのIPアドレスを受け取ります。これらのテクノロジーは、広告キャンペーンの効果を測定したり、アクセスしたWebサイトに表示される広告コンテンツをパーソナライズしたりするために使用されます。</p>

        <p> memodeep.comは、サードパーティの広告主が使用するこれらのCookieにアクセスしたり制御したりすることはできません。</p>

        <h2>サードパーティのプライバシーポリシー</h2>

        <p> memodeep.comのプライバシーポリシーは、他の広告主やWebサイトには適用されません。したがって、詳細については、これらのサードパーティの広告サーバーのそれぞれのプライバシーポリシーを参照することをお勧めします。特定のオプションをオプトアウトする方法についての実践と指示が含まれる場合があります。 </p>

        <p>個々のブラウザオプションからCookieを無効にすることを選択できます。特定のWebブラウザでのCookie管理の詳細については、ブラウザのそれぞれのWebサイトを参照してください。</p>

        <h2> CCPAプライバシー権（私の個人情報を販売しないでください）</h2>

        <p> CCPAの下では、他の権利の中でも、カリフォルニア州の消費者は次の権利を有します。</p>
        <p>消費者の個人データを収集する企業に、企業が消費者に関して収集したカテゴリと特定の個人データの開示を要求します。</p>
        <p>企業が収集した消費者に関する個人データを削除するよう企業に要求します。</p>
        <p>消費者の個人データを販売するのではなく、消費者の個人データを販売する企業に要求します。</p>
        <p>ご要望があった場合は、1ヶ月以内にご返答いたします。これらの権利の行使をご希望の場合は、お問い合わせください。</p>

        <h2> GDPRデータ保護権</h2>

        <p>すべてのデータ保護権を十分に認識していることを確認したいと思います。すべてのユーザーには、次の権利があります。</p>
        <p>アクセスする権利–あなたにはあなたの個人データのコピーを要求する権利があります。このサービスには少額の料金がかかる場合があります。</p>
        <p>修正する権利–お客様には、不正確であると思われる情報の修正を要求する権利があります。また、不完全であると思われる情報の記入を要求する権利もあります。</p>
        <p>消去する権利–特定の条件下で、お客様には個人データの消去を要求する権利があります。</p>
        <p>処理を制限する権利–お客様には、特定の条件下で、お客様の個人データの処理を制限するよう要求する権利があります。</p>
        <p>処理に異議を唱える権利–特定の条件下で、個人データの処理に異議を唱える権利があります。</p>
        <p>データの移植性に対する権利–お客様には、特定の条件下で、収集したデータを別の組織に転送するか、直接お客様に転送するよう要求する権利があります。</p>
        <p>ご要望があった場合は、1ヶ月以内にご返答いたします。これらの権利の行使をご希望の場合は、お問い合わせください。</p>

        <h2>子供の情報</h2>

        <p>私たちの優先事項のもう1つの部分は、インターネットの使用中に子供を保護することです。保護者の方には、オンラインアクティビティを観察、参加、監視、指導することをお勧めします。</p>

        <p> memodeep.comは、13歳未満の子供から故意に個人を特定できる情報を収集することはありません。子供がこの種の情報を当社のWebサイトで提供したと思われる場合は、すぐに当社に連絡することを強くお勧めします。そのような情報を当社の記録から迅速に削除するための最善の努力。</p>

        <br />
        <hr />

        <h2 id="term"><strong>利用規約</strong> </h2>

        <p> memodeep.comへようこそ！</p>

        <p>これらの利用規約は、memodeep.comにあるmemodeep.comのWebサイトの使用に関する規則と規制の概要を示しています。</p>

        <p>このWebサイトにアクセスすることにより、これらの利用規約に同意したものと見なされます。このページに記載されているすべての利用規約に同意しない場合は、memodeep.comを引き続き使用しないでください。</p>

        <p>次の用語は、これらの利用規約、プライバシーに関する声明、免責事項の通知、およびすべての契約に適用されます。「クライアント」、「あなた」、「あなたの」とは、あなた、このWebサイトにログオンし、会社の条件に準拠している人を指します。条件。 「当社」、「当社」、「当社」、「当社」および「当社」は、当社を指します。 「当事者」、「当事者」、または「私たち」は、クライアントと私たちの両方を指します。すべての条件は、当社が定めたサービスの提供に関するクライアントのニーズを満たすという明確な目的のために、クライアントへの支援のプロセスを最も適切な方法で行うために必要な支払いの申し出、受諾、および対価を指します。オランダの一般法に従います。上記の用語または他の単語を単数形、複数形、大文字、および/または彼/彼女またはそれらで使用する場合は、互換性があると見なされ、したがって同じものを指すものと見なされます。</p>

        <h2> <strong>クッキー</strong> </h2>

        <p> Cookieを使用しています。 memodeep.comにアクセスすることにより、memodeep.comのプライバシーポリシーに同意してCookieを使用することに同意したことになります。 </p>

        <p>ほとんどのインタラクティブなウェブサイトはCookieを使用して、訪問ごとにユーザーの詳細を取得できるようにしています。クッキーは、特定の領域の機能を有効にして、人々が当社のWebサイトにアクセスしやすくするために、当社のWebサイトで使用されます。一部のアフィリエイト/広告パートナーもCookieを使用する場合があります。</p>

        <h2> <strong>ライセンス</strong> </h2>

        <p>特に明記されていない限り、memodeep.comおよび/またはそのライセンサーは、memodeep.com上のすべての資料の知的所有権を所有します。すべての知的財産権は留保されています。これらの利用規約で設定された制限に従って、個人的な使用のためにmemodeep.comからこれにアクセスできます。</p>

        <p>次のことはできません：</p>
        <ul>
          <li> memodeep.comから資料を再公開する</li>
          <li> memodeep.comから素材を販売、レンタル、またはサブライセンスする</li>
          <li> memodeep.comからの資料の複製、複製、またはコピー</li>
          <li> memodeep.comからコンテンツを再配布する</li>
        </ul>

        <p>本契約は、本契約の日付から開始するものとします。利用規約は、<a href="https://h-supertools.com/web/terms-and-conditions-generator/"> H-supertools利用規約ジェネレーター</a>を使用して作成されました。 </p>

        <p>このウェブサイトの一部は、ユーザーがウェブサイトの特定の領域に意見や情報を投稿および交換する機会を提供します。 memodeep.comは、コメントがWebサイトに表示される前に、コメントをフィルタリング、編集、公開、またはレビューすることはありません。コメントは、memodeep.com、そのエージェントおよび/または関連会社の見解や意見を反映していません。コメントは、自分の意見や意見を投稿した人の意見や意見を反映しています。適用法で許可されている範囲で、memodeep.comは、コメント、またはコメントの使用および/または投稿および/または表示の結果として生じたおよび/または被った責任、損害または費用について責任を負わないものとします。このウェブサイトで。</p>

        <p> memodeep.comは、すべてのコメントを監視し、不適切、攻撃的、またはこれらの利用規約の違反を引き起こす可能性のあるコメントを削除する権利を留保します。</p>

        <p>あなたはそれを保証し、表明します：</p>

        <ul>
          <li>あなたは私たちのウェブサイトにコメントを投稿する権利があり、そうするために必要なすべてのライセンスと同意を持っています; </li>
          <li>コメントは、第三者の著作権、特許、または商標を含むがこれらに限定されない知的財産権を侵害するものではありません。</li>
          <li>コメントには、プライバシーの侵害である中傷的、中傷的、攻撃的、下品、またはその他の違法な内容は含まれていません。</li>
          <li>コメントは、ビジネスや慣習を勧誘または促進したり、商業活動や違法な活動を提示したりするために使用されることはありません。</li>
        </ul>

        <p>これにより、memodeep.comに、あらゆる形式、形式、またはメディアでのコメントの使用、複製、編集、および他者による使用、複製、編集を許可する非独占的なライセンスを付与します。</p>

        <h2> <strong>コンテンツへのハイパーリンク</strong> </h2>

        <p>以下の組織は、事前の書面による承認なしに当社のWebサイトにリンクする場合があります。</p>

        <ul>
          <li>政府機関; </li>
          <li>検索エンジン; </li>
          <li>ニュース組織; </li>
          <li>オンラインディレクトリディストリビューターは、他の上場企業のWebサイトにハイパーリンクするのと同じ方法で、当社のWebサイトにリンクできます。および</li>
          <li>非営利団体、チャリティーショッピングモール、チャリティー募金グループを勧誘することを除き、システム全体の認定企業。当社のWebサイトにハイパーリンクできない場合があります。</li>
        </ul>

        <p>これらの組織は、以下のリンクがある限り、当社のホームページ、出版物、または他のWebサイト情報にリンクすることができます。 （b）リンク先およびその製品および/またはサービスのスポンサー、承認、または承認を誤って示唆するものではありません。 （c）リンク先のサイトのコンテキストに適合します。</p>

        <p>次の種類の組織からの他のリンクリクエストを検討して承認する場合があります。</p>

        <ul>
          <li>一般的に知られている消費者および/またはビジネス情報ソース; </li>
          <li> dot.comコミュニティサイト; </li>
          <li>慈善団体を代表する協会またはその他のグループ; </li>
          <li>オンラインディレクトリディストリビューター; </li>
          <li>インターネットポータル; </li>
          <li>会計、法律、コンサルティング会社。および</li>
          <li>教育機関および業界団体。</li>
        </ul>

        <p>次のように判断した場合、これらの組織からのリンクリクエストを承認します。（a）リンクによって、私たち自身または認定されたビジネスに不利に見えることはありません。 （b）組織が私たちに否定的な記録を持っていない。 （c）ハイパーリンクの可視性から得られるメリットは、memodeep.comの不在を補うものです。 （d）リンクは一般的なリソース情報のコンテキストにあります。</p>

        <p>これらの組織は、次のリンクがある限り、当社のホームページにリンクすることができます。 （b）リンク先およびその製品またはサービスのスポンサー、承認、または承認を誤って示唆するものではありません。 （c）リンク先のサイトのコンテキストに適合します。</p>

        <p>上記の第2項に記載されている組織のいずれかであり、当社のWebサイトへのリンクに関心がある場合は、memodeep.comに電子メールを送信して通知する必要があります。あなたの名前、あなたの組織名、連絡先情報、あなたのサイトのURL、あなたが私たちのウェブサイトにリンクしようとしているURLのリスト、そしてあなたがリンクしたい私たちのサイトのURLのリストを含めてくださいリンク。応答が出るまで2〜3週間待ちます。</p>

        <p>承認された組織は、次のように当社のWebサイトにハイパーリンクすることができます。</p>

        <ul>
          <li>当社の社名を使用する。または</li>
          <li>リンクされているURIを使用する。または</li>
          <li>リンク先のウェブサイトの他の説明を使用することにより、リンク先のサイトのコンテンツのコンテキストと形式の範囲内で意味があります。</li>
        </ul>

        <p>商標使用許諾契約がない限り、リンクにmemodeep.comのロゴやその他のアートワークを使用することはできません。</p>

        <h2> <strong> iFrame </strong> </h2>

        <p>事前の承認と書面による許可なしに、当社のWebページの視覚的表現または外観を変更するフレームを当社のWebページの周囲に作成することはできません。</p>

        <h2> <strong>コンテンツの責任</strong> </h2>

        <p>当社は、お客様のWebサイトに表示されるコンテンツについて責任を負わないものとします。あなたはあなたのウェブサイトで起きているすべての主張から私たちを保護し、守ることに同意します。猥褻、猥褻、または犯罪と解釈される可能性のある、または第三者の権利を侵害する、さもなければ侵害する、または侵害またはその他の侵害を主張するリンクをWebサイトに表示しないでください。</p>

        <h2> <strong>あなたのプライバシー</strong> </h2>

        <p>プライバシーポリシーをお読みください</p>

        <h2> <strong>権利の留保</strong> </h2>

        <p>当社は、当社のWebサイトへのすべてのリンクまたは特定のリンクの削除を要求する権利を留保します。あなたは、要求に応じて、当社のウェブサイトへのすべてのリンクを直ちに削除することを承認します。また、これらの利用規約とリンクポリシーをいつでも修正する権利を留保します。当社のウェブサイトに継続的にリンクすることにより、これらのリンク条件に拘束され、それに従うことに同意したことになります。</p>

        <h2> <strong>当社のウェブサイトからのリンクの削除</strong> </h2>

        <p>当社のウェブサイトで何らかの理由で不快なリンクを見つけた場合は、いつでも自由に連絡してお知らせください。リンクの削除リクエストを検討しますが、そのような義務はなく、直接返信する義務もありません。</p>

        <p>このウェブサイトの情報が正しいことを保証するものではなく、その完全性または正確性を保証するものでもありません。また、ウェブサイトが引き続き利用可能であること、またはウェブサイト上の資料が最新の状態に保たれることを保証することも約束しません。</p>

        <h2> <strong>免責事項</strong> </h2>

        <p>適用法で認められる最大限の範囲で、当社のWebサイトおよびこのWebサイトの使用に関連するすべての表明、保証、条件を除外します。この免責事項には次の内容は含まれていません：</p>

        <ul>
          <li>死亡または人身傷害に対する当社またはお客様の責任を制限または除外します。</li>
          <li>詐欺または詐欺的な不実表示に対する当社またはお客様の責任を制限または除外します。</li>
          <li>適用法で許可されていない方法で、当社またはお客様の責任を制限する。または</li>
          <li>適用法の下で除外されない可能性のある当社またはお客様の責任を除外します。</li>
        </ul>

        <p>このセクションおよびこの免責事項の他の場所で設定されている責任の制限および禁止事項：（a）前の段落に従う。 （b）契約、不法行為、および法定義務違反で発生する責任を含む、免責事項に基づいて発生するすべての責任を管理します。</p>

        <p>ウェブサイトおよびウェブサイト上の情報とサービスが無料で提供されている限り、当社はいかなる性質の損失または損害についても責任を負いません。</p>

      </div>
    </section>
  );
}

export { Policy };
