import React, { useEffect, useState } from "react";
import mot from "../../../images/PNG/Mot.webp";
import reglage from "../../../images/PNG/Reglage.png";
import jeux from "../../../images/PNG/JeuxMotMulti.webp";
import signature from "../../../images/PNG/Signature.png";
import europeexemple from "../../../images/PNG/example-europe-jp.png";
import asianexemple from "../../../images/PNG/example-asian-jp.png";
import mathexemple from "../../../images/PNG/exemple-scientific-jp.png";
import generalexemple from "../../../images/PNG/example-general-jp.png";
import { Link } from "react-router-dom";
import { changeLangue } from "../../../redux/actions/langueActions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

function Presentation() {

  const langueState = useSelector(state => state.langue);
  const { langue } = langueState;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langued, setLangued] = useState(langue);

  useEffect(() => {
    if (langued === 'jap') {
      i18n.changeLanguage('jap');
    } else if (langued === 'fre') {
      i18n.changeLanguage('fre');
    } else {
      i18n.changeLanguage('en');
    }
    dispatch(changeLangue(langued));
    return () => {
    };
  }, [langued]);

  return (
    <section className="presentation">
      <section className="presentation__container">
        <div className="user__language">
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
              onChange={() => setLangued('en')}
              type="radio" name="langue" />
            <label className="user__language__radio--label" htmlFor="en" ></label>
            <span className="user__language__lg">English</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
              onChange={() => setLangued('fre')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="fr" ></label>
            <span className="user__language__lg">Français</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
              onChange={() => setLangued('jap')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="jp" ></label>
            <span className="user__language__lg">日本語</span>
          </div>
        </div>
        <h1>
          ようこそ　<strong>Memodeep.com</strong>　へ。
        </h1>
        <br />
        <p>
          学習方法を向上したいと誰もが思います。
        </p>
        <p>
          新しい言語、歴史、科学や文学等を学ぼうとするとき、このアプリケーションは記憶や暗記に一役買うことでしょう。
        </p>
        <span>
          <p>
            新規登録  &#128512;
          </p>
        </span>
        <Link to="/user" >
          <span className="material-icons blue md-36">subscriptions</span>
        </Link>
        <br />
        <br />
        <div className="messagewidth">
          <p className="backgroundgreen padding">
            <strong>For English learner.</strong><br /><br />
            Soon, all the vocabulary for TOIC and TOEFL will be available.</p>
        </div>
        <br />
        <br />
        <div className="presentation__bg-video" >
          <iframe width="100%" height="348px" src="https://www.youtube.com/embed/DxsqJXNK_BQ" title="intro japanese" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
        </div>
        <br />
        <br />
        <p>
          このアプリケーションは<strong>フラッシュカード</strong>を使います
        </p>
        <p>
          簡単な3つのステップでアプリを始められます。
        </p>
        <ul className="presentation__step" style={{ listStyleType: 'decimal' }}>
          <li className="presentation__step__item">学びたいことを選ぶ。</li>
          <li className="presentation__step__item">レッスンを簡単に素早く作成。</li>
          <li className="presentation__step__item">色々なクイズをプレイできる。</li>
        </ul>
        <h3>
          特徴
        </h3>
        <br />
        <br />
        <ul className="presentation__feature">
          <li className="presentation__feature__item">制限なしで単語帳を設定することができます</li>
          <li className="presentation__feature__item">設定タイプ：単語　または短い文章や長い文章</li>
          <br />
          <img
            alt="Photo 2"
            width="100%"
            src={reglage}
          />
        </ul>
        <ul className="presentation__feature">
          <li className="presentation__feature__item">素早くレッスン作成</li>
          <li className="presentation__feature__item">簡単なレッスン管理</li>
          <li className="presentation__feature__item">レッスンの照会リスト作成</li>
          <li className="presentation__feature__item">ワンクリックで様々なリストを表示</li>
          <li className="presentation__feature__item">ワンクリックでマルチレッスンリストを表示</li>
          <li className="presentation__feature__item">一つの画面に全てのデータを表示</li>
          <li className="presentation__feature__item">データを検索</li>
          <li className="presentation__feature__item">データを入力</li>
          <li className="presentation__feature__item">テキストファイルでレッスンをインポートする</li>
          <br />
          <img
            alt="Photo 1"
            width="100%"
            src={mot}
          />
        </ul>
        <ul className="presentation__feature">
          <li className="presentation__feature__item">様々なタイプのクイズ：自動的に採点、複数選択、スペリング</li>
          <li className="presentation__feature__item">クイズ中に設定を変更可能</li>
          <li className="presentation__feature__item">クイズの前後のナビゲーション</li>
          <li className="presentation__feature__item">クイズ中にカードをシャッフル可能</li>
          <li className="presentation__feature__item">タブレットやスマートフォンと互換性があります</li>
          <br />
          <img
            alt="Photo 3"
            width="100%"
            src={jeux}
          />
        </ul>
        <br />
        <div className="presentation__liendoc">
          <p className="presentation__liendoc__message">
            チュートリアルを見る
          </p>
          <Link to="/question" >
            <span className="material-icons blue md-36">help_center</span>
          </Link>
        </div>
        <br />
        <p style={{ alignSelf: "center" }}>
          さて、私がこの素晴らしいツールを作成した理由をお話ししましょう。
        </p>
        <br />
        <span class="material-icons blue md-36">sentiment_very_satisfied</span>
        <p>
          私はかなり前にこの美しい言語、日本語を学び始めました。
          当時スマートフォンは当然無く、日本語を勉強するための本だけ。
          レッスンごとに日本語とフランス語の単語をノートに丁寧に書きました。
          <br />
          テキストが終わるころにはノートのすべてのページが一杯になりました。
          私は毎日各ページを絶え間なく復習していきました。
        </p>
        <br />
        <span class="material-icons blue md-36">menu_book</span>
        <p>
          そこにはたくさんの言葉があり、私はそのすべてを暗記していきましたが何かが違う感じでした。
          せっかく覚えた単語の記憶を邪魔する何かが私の頭の中にあるのです。
        </p>
        <p>
          文章の中ですでに覚えた言葉に出くわしてもわからず、ノートを度々確認することがよくありました。
          その時、ノートにある単語すべての順序を無意識に覚えていて、そのリストにある文脈から外れてしまうと、単語を認識しづらかったことに気づきました。
          私は覚えたすべての単語をこの記憶した順序に当てはめることに頼りすぎていました。
        </p>
        <br />
        <span class="material-icons blue md-36">sentiment_very_dissatisfied</span>
        <p>
          だから、フラッシュカードソリューションを思いつきました。
          <br />しかし、当時はすべて手作業で行う必要がありました。そしてスマートフォンの登場がゆっくりと始まっていました。
          <br />
          また、私は日本へ行き、日本語のテキストはすべて英語で書かれていました。
          当時の私の英語は完璧ではありませんでしたので、日本語、英語両方を同時に学ばなければなりませんでした。
        </p>
        <br />
        <span class="material-icons blue md-36">style</span>
        <br />
        <p>
          その時、カスタマイズできるこのアプリケーションを作成することを思いついたのです。
          <br />様々な内容を書き、それらをリンクして、ある項目から別の項目に簡単に移動できるようになりました。
        </p>
        <p>
          このアプリを使用すると、カードに多くのカテゴリを入力できます。フランス語、英語、日本語、スペイン語、そして漢字、中国語、タイ語の文字を入力することができます。
          <br /> クイズでは、ゲームを停止することなく、内容を簡単に切り替えることができます。これにより、複数の言語を簡単に学ぶことができます。
          <br />内容を追加するにはカードを再作成せずに新しい項目を作るだけでいいのです。
          <br />
          全てのデータが一か所にあります。
        </p>
        <p>
          テキストモードでは、覚えたい文章を入力することができます。
        </p>
        <p>
          また、手間をかけずにテキストの内容を入力して探しやすくカスタマイズしてください。
        </p>
        <br />
        <span class="material-icons blue md-36">sticky_note_2</span>
        <p>
          更に、言語だけでなく、歴史などのエリアを選択し、場所、日付、イベントにリンクすることができます。
          <br /> または、数式や化学式とその名前を使ってフラッシュカードで遊べます。
        </p>
        <div className="presentation__encouragement">
          <p style={{ alignSelf: "center" }}>
            <i>望むままに全てを学びましょう。</i>
          </p>
          <span class="material-icons md-36">sentiment_very_satisfied</span>
        </div>
        <br />
        <p>
          このアプリを楽しみながら使って、それがあなたの学習の役立つことを願っています。
        </p>
        <p>
          コメントや応援メッセージなどお待ちしています。
        </p>
        <br />
        <div className="presentation__email">
          <Link className="footer__item" to="/mail">
            <span className="material-icons md-36 blue md">mail</span>
          </Link>
        </div>
        <p>
          コメントに応じて改善し、新しい機能を追加し続けていきます。
        </p>
        <div className="presentation__cordialement">
          <p>どうぞよろしくお願い致します。.</p>
        </div>
        <div className="presentation__signature">
          <img
            alt="Photo 3"
            className="presentation__signature__writing"
            src={signature}
          />
        </div>
        <div className="composition">
          <img
            alt="Photo 1"
            className="composition__photo composition__photo--p1"
            src={mot}
          />
          <img
            alt="Photo 2"
            className="composition__photo composition__photo--p2"
            src={reglage}
          />
          <img
            alt="Photo 3"
            className="composition__photo composition__photo--p3"
            src={jeux}
          />
        </div>
        <div className="presentation__extitle">
          設定例
        </div>
        <br />
        <h3>
          ヨーロッパ言語
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="europe"
          src={europeexemple}
        />
        <br />
        <h3>
          アジア言語
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="asiatique"
          src={asianexemple}
        />
        <br />
        <h3 >
          科学的
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="science"
          src={mathexemple}
        />
        <br />
        <h3 >
          文化
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="general"
          src={generalexemple}
        />
        <br />
        <div className="presentation__encouragement">
          <p style={{ margin: '0px', alignSelf: "center" }}>
            <i>あなたの好きなように混ぜて作ることができます!</i>
          </p>
        </div>
        <br />
        <br />
        <div className="presentation__liendoc">
          <p className="presentation__liendoc__message">
            チュートリアルを見る
          </p>
          <Link to="/question" >
            <span className="material-icons blue md-36">help_center</span>
          </Link>
        </div>
        <br />
        <span>
          <p>
            新規登録  &#128512;
          </p>
        </span>
        <Link to="/user" >
          <span className="material-icons blue md-36">subscriptions</span>
        </Link>
        <br />
      </section>
    </section>
  );
}

export { Presentation };
