import React, { useEffect, useState } from "react";
import mot from "../../../images/PNG/Mot.webp";
import reglage from "../../../images/PNG/Reglage.png";
import jeux from "../../../images/PNG/JeuxMotMulti.webp";
import signature from "../../../images/PNG/Signature.png";
import europeexemple from "../../../images/PNG/example-europe.PNG";
import asianexemple from "../../../images/PNG/example-asian.PNG";
import mathexemple from "../../../images/PNG/example-scientifique.PNG";
import generalexemple from "../../../images/PNG/example-general.PNG";
import kanjiPrint from "../../../images/PNG/KanjiPrint.PNG";
import kanjiShow from "../../../images/PNG/KanjiShow.PNG";
import kanjiGame from "../../../images/PNG/KanjiGame.PNG";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLangue } from "../../../redux/actions/langueActions";

function Presentation() {

  const langueState = useSelector(state => state.langue);
  const { langue } = langueState;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langued, setLangued] = useState(langue);

  useEffect(() => {
    if (langued === 'jap') {
      i18n.changeLanguage('jap');
    } else if (langued === 'fre') {
      i18n.changeLanguage('fre');
    } else {
      i18n.changeLanguage('en');
    }
    dispatch(changeLangue(langued));
    return () => {
    };
  }, [langued]);

  return (
    <section className="presentation">
      <section className="presentation__container">
        <div className="user__language">
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
              onChange={() => setLangued('en')}
              type="radio" name="langue" />
            <label className="user__language__radio--label" htmlFor="en" ></label>
            <span className="user__language__lg">English</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
              onChange={() => setLangued('fre')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="fr" ></label>
            <span className="user__language__lg">Français</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
              onChange={() => setLangued('jap')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="jp" ></label>
            <span className="user__language__lg">日本語</span>
          </div>
        </div>
        <h1>
          Never stop learning, because life never stops teaching.
        </h1>
        <h1>
          Welcome on <strong>Memodeep.com.</strong>
        </h1>
        <br />
        <p>
          I have first created this web application for myself. When I started to use it, I could learn and review so many words and expressions in such a short time I thought I should share it.
        </p>
        <p>
          The following five minutes to understand this application will be the key to leveling up your learning.
        </p>
 
        <br />
        <p>
          We all want to improve
          <strong> our learning process.</strong>
        </p>
        <p>
          Whether for a new language or any other field such as history,
          science, or literature, this web application will be <strong>great </strong>
          for working on your memory.
        </p>
        <span>
          <p>
            Let's create free account and start using it! &#128512;
          </p>
        </span>
        <Link to="/user" >
          <span className="material-icons blue md-36">subscriptions</span>
        </Link>
        <div className="messagewidth">
          <p className="backgroundgreen padding">
            <strong>For Japanese learner.</strong><br /><br />
            You can enjoy <strong>free JLPT N5 Kanji</strong> in the demo lesson.<br />
            Using the free account, you can import Kanji <strong>JLPT N5 and N4</strong> with drawing order picture.<br />
            In addition, you can import all JLPT level <strong>N5, N4, N3, N2, N1 Kanji and vocabulary</strong> with few click for subscribers.<br />
            Around <strong>130 lessons</strong> and more than <strong>10,000 flashcards</strong> ready to use.<br />
            You can print up to <strong>16 kanji </strong>of your choice in A4 format.<br />
            Furthermore, I will regularly provide free files to import based on grammar points with sentences you can revise with the quizzes.<br /><br />
            <div className="presentation__japanese">
              <div className="presentation__japanese--width">
                <div className="presentation__japanese--center">Quiz</div>
                <img
                  className="presentation__japanese--width"
                  alt="Photo 2"
                  src={kanjiGame}
                />
              </div>
              <div className="presentation__japanese--width">
                <div className="presentation__japanese--center">Flashcard</div>
                <img
                  className="presentation__japanese--width"
                  alt="Photo 2"
                  src={kanjiShow}
                />
              </div>
              <div className="presentation__japanese--width">
                <div className="presentation__japanese--center">Printable sheet A4</div>
                <img
                  className="presentation__japanese--width"
                  alt="Photo 2"
                  src={kanjiPrint}
                />
              </div>
            </div>
            <br />
          </p>
        </div>
        <br />
        <br />
        {/* <div className="messagewidth">
          <p className="backgroundgreen padding">
            <strong>For English learner.</strong><br /><br />
            Soon, all the vocabulary for TOIC and TOEFL will be available.</p>
        </div>
        <br /> */}
        <br />
        <div className="presentation__bg-video" >
          <iframe width="100%" height="348px" src="https://www.youtube.com/embed/K2ZqzJXtghA" title="intro english" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
        </div>
        <br />
        <br />
        <p>
          This application uses <strong>flashcards.</strong>
        </p>
        <p>
          In 3 easy steps, you will start playing with the application.
        </p>
        <ul className="presentation__step" style={{ listStyleType: 'decimal' }}>
          <li className="presentation__step__item">Choose what you want to learn.</li>
          <li className="presentation__step__item">Create easily and quickly your lessons.</li>
          <li className="presentation__step__item">Play with different kinds of quizzes.</li>
        </ul>
        <h3>
          Features:
        </h3>
        <br />
        <br />
        <ul className="presentation__feature">
          <li className="presentation__feature__item">Creation of unlimited configurable Flashcards.</li>
          <li className="presentation__feature__item">2 different types of configurations: short word or phrase and extended text.</li>
          <li className="presentation__feature__item">Import JLPT Japanese Kanji and Vocabulary.</li>
          <br />
          <img
            alt="Photo 2"
            width="100%"
            src={reglage}
          />
        </ul>
        <ul className="presentation__feature">
          <li className="presentation__feature__item">Quick creation of lessons.</li>
          <li className="presentation__feature__item">Easy lesson management.</li>
          <li className="presentation__feature__item">Creation of information lists by lessons.</li>
          <li className="presentation__feature__item">Display the different lists in one click.</li>
          <li className="presentation__feature__item">Display of multi-lesson lists in one click.</li>
          <li className="presentation__feature__item">Information centralized in one screen.</li>
          <li className="presentation__feature__item">Search information.</li>
          <li className="presentation__feature__item">Enter information.</li>
          <li className="presentation__feature__item">Import lessons with a text file for quick entry.</li>
          <li className="presentation__feature__item">Use any free images from Web.</li>
          <br />
          <img
            alt="Photo 1"
            width="100%"
            src={mot}
          />
        </ul>
        <ul className="presentation__feature">
          <li className="presentation__feature__item">Different types of quizzes: self-assessment, multiple-choice, writing.</li>
          <li className="presentation__feature__item">Change the settings of your choice during the quiz.</li>
          <li className="presentation__feature__item">Backward and forward navigation of the quiz.</li>
          <li className="presentation__feature__item">Shuffle cards during the quiz.</li>
          <li className="presentation__feature__item">Quick navigation between configuration.</li>
          <li className="presentation__feature__item">Font size change button for better reading.</li>
          <li className="presentation__feature__item">Compatible with tablet and smartphone.</li>
          <br />
          <img
            alt="Photo 3"
            width="100%"
            src={jeux}
          />
        </ul>
        <br />
        <div className="presentation__liendoc">
          <p className="presentation__liendoc__message">
            Watch the tutorials
          </p>
          <Link to="/question" >
            <span className="material-icons blue md-36">help_center</span>
          </Link>
        </div>
        <br />
        <p style={{ alignSelf: "center" }}>
          Let me tell you why I created this <strong>great tool.</strong>
        </p>
        <br />
        <span class="material-icons blue md-36">sentiment_very_satisfied</span>
        <p>
          A long time ago, I started learning the Japanese language. I didn't have a smartphone, only a book to learn Japanese.
          With each lesson I memorized, I carefully wrote the Japanese and French words in my notebook.
          <br />
          When I finished the book, all the pages in my notebook were full. Every day, I revised each page endlessly.
        </p>
        <br />
        <span class="material-icons blue md-36">menu_book</span>
        <p>
          There were hundreds of words, and I ended up remembering all of them, but I was feeling something strange.
          Somehow this feeling had a strong influence on all the words I had memorized.
        </p>
        <p>
          Often, when, in a sentence, I encountered a word that I had learned, I didn't figure it out right away, and I needed to recheck it in my notebook.
          I realized that I had memorized the order of all the words in my notebook and, taken out of the context of this list,the word was difficult to remember.
          I had polluted my <strong>memory</strong> and made each word too related to its place in this list.
        </p>
        <br />
        <span class="material-icons blue md-36">sentiment_very_dissatisfied</span>
        <p>
          This was when the <strong>flashcard</strong> solution appeared in my head. That would solve the
          problem of the list order.However, back in the day, everything had to be done manually,
          and smartphones' usage was just slowly emerging.
          <br />
          Besides, when I went to Japan, all the books for learning Japanese were written in English.
          My English level being far from perfect at the time, I ended up learning the two languages simultaneously,
          making it even more difficult.
        </p>
        <br />
        <span class="material-icons blue md-36">style</span>
        <br />
        <p>
          It was then that I had the idea to create this application which allows creating configurable flashcards.
          I would write several pieces of information, link them together and move from one part of the information to another quickly.
        </p>
        <p>
          With this application, you will fill a card with many entries.
          For example, you can put for an English word, French, Japanese, Spanish with Latin alphabet, and Japanese, Chinese, and Thai characters.
          Then, during the quiz, you can easily switch between one piece of information to the other without stopping the game.
          <br />
          It allows you to learn
          <strong> multiples languages </strong>easily<br />
          To add information, you only need to create a new
          entry without recreating a new flashcard.
          <br />All your data is in one place.
        </p>
        <p>For text mode, you can put sentences you want to remember.</p>
        <p>And also, put the information to find their location in your books without wasting your time.</p>
        <br />
        <span class="material-icons blue md-36">sticky_note_2</span>
        <p>
          But you can use it for more than languages. For example, you can choose topics like
          history and link the flashcard with a date, an event, a place, etc.
          <br />
          It can also be science-related with the name of a formula and its formula, for example.
        </p>
        <div className="presentation__encouragement">
          <p style={{ alignSelf: "center" }}>
            <i>Learn everything you want without limits !!!</i>
          </p>
          <span class="material-icons  md-36">sentiment_very_satisfied</span>
        </div>
        <br />
        <p>I hope you will enjoy this app and it will help you in your learning.</p>
        <p>
          Do not hesitate to send a message to give me your feedback and your
          encouragement.
        </p>
        <br />
        <div className="presentation__email">
          <Link className="footer__item" to="/mail">
            <span className="material-icons md-36 blue md">mail</span>
          </Link>
        </div>
        <p>I will continue to improve it and add new features to it.</p>
        <div className="presentation__cordialement">
          <p>Regards.</p>
        </div>
        <div className="presentation__signature">
          <img
            alt="Photo 3"
            className="presentation__signature__writing"
            src={signature}
          />
        </div>
        <div className="composition">
          <img
            alt="Photo 1"
            className="composition__photo composition__photo--p1"
            src={mot}
          />
          <img
            alt="Photo 2"
            className="composition__photo composition__photo--p2"
            src={reglage}
          />
          <img
            alt="Photo 3"
            className="composition__photo composition__photo--p3"
            src={jeux}
          />
        </div>
        <div className="presentation__extitle">
          Setting examples
        </div>
        <br />
        <h3>
          European language
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="europe"
          src={europeexemple}
        />
        <br />
        <h3>
          Asian language
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="asiatique"
          src={asianexemple}
        />
        <br />
        <h3 >
          Scientific
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="science"
          src={mathexemple}
        />
        <br />
        <h3 >
          General
        </h3>
        <br />
        <img
          className="presentation__exemple"
          alt="general"
          src={generalexemple}
        />
        <br />
        <div className="presentation__encouragement">
          <p style={{ margin: '0px', alignSelf: "center" }}>
            <i>Mix, create everything you wish!</i>
          </p>
        </div>
        <br />
        <br />
        <div className="presentation__liendoc">
          <p className="presentation__liendoc__message">
            Watch the tutorials
          </p>
          <Link to="/question" >
            <span className="material-icons blue md-36">help_center</span>
          </Link>
        </div>
        <br />
        <span>
          <p>
            Create free account  &#128512;
          </p>
        </span>
        <Link to="/user" >
          <span className="material-icons blue md-36">subscriptions</span>
        </Link>
        <br />
      </section>
    </section>
  );
}

export { Presentation };
