import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  genereRandomNumber,
  selectFromDecrement,
  selectFromIncrement,
  selectToDecrement,
  selectToIncrement,
  setSelf,
} from "../../../redux/actions/gameActions";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { reducerReactPosition } from "../reducerReactPosition";
import GameInfo from "../GameInfo";
import GameBarAction from "../GameBarAction";
import GameInsideFromToNav from "../GameInsideFromToNav";
import { GAME_SELF } from "../../../redux/actionTypes";
import { focusMot } from "../../../redux/actions/motActions";

function FlashcardSelf() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mot = useSelector((state) => state.mot);
  const { lecons, mots } = mot;

  const game = useSelector((state) => state.game);
  const { from, self, onlyFocus } = game;

  let fontUI = self.font === "no" || self.font === undefined ? "flashcardself__guess" : self.font;

  const getToGuessImage = () => {
    if (mots && mots.length > 1 && mots.length === self.randoms.length) {
      return {
        image: self.guess
      };
    }
    return { translate: "---" };
  };

  const showSolution = () => {
    dispatch(setSelf({ ...self, solution: true }));
  };

  const changeFontUI = () => {
    if (fontUI === "flashcardself__guess") {
      dispatch({ type: GAME_SELF, payload: { ...self, font: "flashcardself__guess bigfont" } });
    } else {
      dispatch({ type: GAME_SELF, payload: { ...self, font: "flashcardself__guess" } });
    }
  }

  const getFocus = () => {
    if (mots && mots.length > 1 && mots.length === self.randoms.length) {
      return mots[self.randoms[self.position]].focus;
    }
    return '0';
  };

  return (
    <section className="flashcardself">
      <GameInfo mots={mots.length} lecons={lecons.length} position={self.position} />


      {from === 'image' && getToGuessImage().image ?
        <img className="imageMot"
          src={getToGuessImage().image}
          alt="new"
        />
        :
        <div className={fontUI}>{self.guess} </div>
      }

      <GameInsideFromToNav  guess={self.guess}  onlyFocus={onlyFocus} isfocus={getFocus()} changeFont={changeFontUI} selectFromDecrement={selectFromDecrement} selectFromIncrement={selectFromIncrement} focus={() =>
        reducerReactPosition("focus", mots, dispatch, self, focusMot)
      } />

      {self.solution ? (
        <div className="flashcardself__answer">{self.answer}</div>
      ) : (
        <div className="flashcardself__answer">&nbsp;</div>
      )}
      <GameBarAction
        selectToDecrement={selectToDecrement}
        selectToIncrement={selectToIncrement}
        goLeft={() =>
          reducerReactPosition("decrement", mots, dispatch, self, setSelf)
        }
        goRight={() =>
          reducerReactPosition("increment", mots, dispatch, self, setSelf)
        }
        reStart={() => dispatch(genereRandomNumber("self"))}
        showSolution={() => showSolution()}
      />
    </section>
  );
}

export default FlashcardSelf;
