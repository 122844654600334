import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Texte from './Texte';
import { useTranslation } from "react-i18next";
import { useAuth } from "../use-auth";
import { LECON_TEXTE_CREATE_ITEM, TEXTE_FONT, TEXTE_ISFOCUS } from '../../redux/actionTypes';
import FocusTexte from './FocusTexte';
import { copyFocus } from '../../redux/actions/leconActions';

var Spinner = require('react-spinkit');

function Textes() {

    const auth = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const texte = useSelector(state => state.texte);
    const { lecons, mots, loading, error, motSelected, font, focus, isFocus } = texte;

    let fontUI = font === "no" || font === undefined ? "mot__form__name" : font;

    const changeFont = () => {
        if (fontUI === "mot__form__name") {
            dispatch({ type: TEXTE_FONT, payload: "mot__form__name averagefont" });
        } else {
            dispatch({ type: TEXTE_FONT, payload: "mot__form__name" });
        }
    }

    const toogleFocus = () => {
        dispatch({ type: TEXTE_ISFOCUS, payload: !isFocus });
    }

    const copyFocusUI = () => {
        if (focus && focus.length > 0) dispatch(copyFocus(auth.user, "texte", LECON_TEXTE_CREATE_ITEM)).catch((error) => alert("Lecon.js 53 " + error));
    }

    return (
        loading ?
            <form className="mots">
                <div className="mots__menu">
                    <Spinner name='cube-grid-bounce' />
                </div>
            </form>
            : error ? <div>{error}</div> :
                <form className="mots">
                    <div className="mots__menu">
                        <span>{lecons.length} {t("lecons")}</span>
                        <span>{mots.length} {t("words")}</span>
                        {!isFocus && <span className="material-icons md-36 md-light">feedback</span>}
                        {isFocus ?
                            <>
                                <span className="material-icons md-36 md-light pointer" onClick={() => copyFocusUI()}>content_copy</span>
                                <span className="material-icons md-36 md-light green pointer" onClick={() => toogleFocus()}>grade</span>
                            </>
                            :
                            <span className="material-icons md-36 md-light pointer" onClick={() => toogleFocus()}>grade</span>
                        }
                    </div>
                    {auth.user === null &&
                        <div className="tuto">
                            <p>{t("wordmanagement")}</p>
                            <p className="tuto__help">{t("trick")}</p>
                        </div>}
                    {isFocus ?
                        <ul className="mots__list" >
                            {
                                focus.map(mot =>
                                    <li key={mot._id} className="mots__item" >
                                        <FocusTexte _id={mot._id} font={fontUI} name={mot.name} isSelected={motSelected && mot._id === motSelected._id ? true : false} />
                                    </li>
                                )
                            }
                        </ul>
                        :
                        <ul className="mots__list" >
                            {
                                mots.map(mot =>
                                    <li key={mot._id} className="mots__item" >
                                        <Texte _id={mot._id} font={fontUI} name={mot.name} focus={mot.focus} isSelected={motSelected && mot._id === motSelected._id ? true : false} />
                                    </li>
                                )
                            }
                        </ul>}
                </form>
    )
}

export default Textes