import React from "react";
import game from "../../../images/PNG/smartphone-game.webp";
import lecon from "../../../images/PNG/smartphone-lecon.webp";
import setting from "../../../images/PNG/smartphone-setting.webp";

function Release() {

  return (
    <section className="release">
    <div className="release__container">
      <div id='top' className="release__index">
        <div className="release__short">
          <span className="material-icons md-24">maps_ugc</span>
          <a href="#quick">
            <h1>Le futur de l'application.</h1>
          </a>
        </div>
      </div>
      <ul className="release__feature">
        <li className="release__feature__item">Ajout de quiz.</li>
        <li className="release__feature__item">Option de recherche des leçons.</li>
        <li className="release__feature__item">Filtre de leçons.</li>
        <li className="release__feature__item">Page de synthèse des résultats des quiz.</li>
        <li className="release__feature__item">Ajout de tag pour les leçons.</li>
      </ul>
      <div id='top' className="release__index">
        <div className="release__short">
          <span className="material-icons md-24">maps_ugc</span>
          <a href="#quick">
            <h1>Projets en cours.</h1>
          </a>
        </div>
      </div>
      <ul className="release__feature">
        <li className="release__feature__item">Application smartphone.</li>
        <div className="release__smartphone">
          <img className="release__smartphone__item"
            alt="Game"
            src={game}
          />
          <img className="release__smartphone__item"
            alt="Lecon"
            src={lecon}
          />
          <img className="release__smartphone__item"
            alt="Setting"
            src={setting}
          />
        </div>
      </ul>
      <div id='top' className="release__index">
        <div className="release__short">
          <span className="material-icons md-24">maps_ugc</span>
          <a href="#quick">
            <h1>Projets en attente.</h1>
          </a>
        </div>
      </div>
      <ul className="release__feature">
        <li className="release__feature__item">Gestion d'images.</li>
        <li className="release__feature__item">Reconnaissance vocale.</li>
      </ul>
      <br />
      <p>
        Ces projets me prendront beaucoup de temps à réaliser mais je souhaite les faire pour que l'application devienne un outil pratique pour vos études.<br />
        Chaque abonnement sera une précieuse aide pour accomplir les futurs projets mais aussi et surtout une formidable reconnaissance de mon travail.<br />
      </p>
      <br />
      <br />
      <h1>
        Je vous remercie d'avance pour votre soutien.
      </h1>
      <br />
      </div>
    </section>
  );
}
export { Release };