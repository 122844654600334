import React from "react";
import { useTranslation } from "react-i18next";
import { Policy as PolicyEN } from "./EN/Policy";
import { Policy as PolicyFR } from "./FR/Policy";
import { Policy as PolicyJP } from "./JP/Policy";

function Policy() {
  const { t } = useTranslation();

  const language = t("language");

  return (
    <>
      {language === "JP" && <PolicyJP />}
      {language === "EN" && <PolicyEN />}
      {language === "FR" && <PolicyFR />}
    </>
  );
}

export default Policy;
