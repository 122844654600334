import configApp from '../../config';
import _ from 'lodash';


// in translates and translateTextes

function isInsidePlan(lecons, props) {
    let ok = true;
    _.forEach(lecons, function (lecon) {
        let foundLecon = _.find(props.lecons, function (o) { return o._id === lecon });
        if (foundLecon && foundLecon.mots > configApp.MAXWORD) {
            ok = false;
        }
    });
    return ok;
}

const createNewWord = (event, auth, dispatch, lecons, popup, t, props, resetMot) => {
    event.preventDefault();
    if (auth.user) {
//        if (auth.user.state === configApp.SUBCRIPTIONFREE) {
            dispatch(resetMot());
        // } else {
        //     if (isInsidePlan(lecons, props)) {
        //         dispatch(resetMot()).catch((error) => alert("utils.js 25 " + error));
        //     } else {
        //         popup.open(t("subcribeneed"));
        //     }
        // }
    } else {
        popup.open(t("signupwarning"));
    }
}

const saveWord = (auth, dispatch, lecons, popup, t, props, saveMot) => {
    if (auth.user) {
        if (lecons.length === 0 || lecons.length > 1) {
            popup.open(t("noleconselected"));
        } else {
            //if (auth.user.state === configApp.SUBCRIPTIONFREE) {
                dispatch(saveMot()).then( res => popup.open(t("motsaved"))).catch(error => alert("utils.js 41" + error));
            // } else {
            //     if (isInsidePlan(lecons, props)) {
            //         dispatch(saveMot()).then( res => popup.open(t("motsaved"))).catch(error => alert("utils.js 44" + error));
            //     } else {
            //         popup.open(t("subcribeneed"));
            //     }
            // }
        }
    } else {
        popup.open(t("signupwarning"));
    }
}

const deleteWord = (event, auth, dispatch, popup, t, deleteMot) => {
    event.preventDefault();
    if (auth.user) {
        const monAction = () => {
            dispatch(deleteMot()).catch(error => alert("utils.js 59" + error));
        }
        popup.setAction(() => monAction);
        popup.openConfirm('deleteconfirm', "");
    } else {
        popup.open(t("signupwarning"));
    }
}

const getTranslateByConfig = (_idConfig, motSelected) => {
    if (motSelected && motSelected.mot) {
        const translate = motSelected.mot.find(x => x.config === _idConfig);
        if (translate) {
            return translate.translate;
        }
    }
    return "";
}

// in Textes and Mots

const getTranslate = (mot , configs , configSelected, props) => {
    if (configs) {
        configs = configs.filter(x => x.type === props.type);
        const first = configs.find(x => x.position === (configSelected.position ? configSelected.position : 1));
        if (first) {
            const find = mot.find(x => x.config === first._id);
            if (find)
                return find.translate;
        }
    }
    return "";
}


export { createNewWord, saveWord, deleteWord, getTranslateByConfig , getTranslate };