import React from "react";

const PopupMemo = props => {
    return (
        <div className="popup"  >
            <div className="popup__notification" onClick={props.handleClose}>
                <div className="popup__notification__message">{props.content}</div>
                <span className="material-icons md-36">disabled_by_default</span>
            </div>
        </div>
    );
};

export default PopupMemo;