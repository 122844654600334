import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedMot, selectConfig } from '../../redux/actions/motActions';
import { getTranslateByConfig } from './utils';

function Translate(props) {

    const mot = useSelector(state => state.mot);
    const { motSelected } = mot;

    const [translate, setTranslate] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        setTranslate(getTranslateByConfig(props._id, motSelected));
        return () => {
            //
        };
    }, [motSelected]);

    useEffect(() => {
        dispatch(updateSelectedMot(translate, props._id)).catch((error) => alert("Translate.js 23" + error));
        return () => {
            //
        };
    }, [translate]);

    return (

        <div className="translate">
            <div className="translate__radio" >
                <input className="translate__radio--input" id={"translateRadio" + props._id} type="radio" name="translate" checked={props.isSelected} onChange={() => dispatch(selectConfig(props._id)).catch((error) => alert("Translate.js 33 " + error))} />
                <label className="translate__radio--label" htmlFor={"translateRadio" + props._id}>
                </label>
            </div>
            <div className="translate__form">
                <div className="translate__form__name">{props.name}:</div>
                <input id={"translate" + props._id} type="text" value={translate} className="translate__form__input" onChange={(e) => setTranslate(e.target.value)} />
            </div>
        </div>
    )
}

export default Translate