import React, { useState, useEffect } from 'react'
import { useAuth } from "../use-auth";
import { useTranslation } from "react-i18next";
import UserInfoPayment from '../popup/PopupUserInfoPayment';
import { usePopup } from "../popup/PopupContext";
import { confirmResiliation } from '../../redux/actions/mailActions';
import { useDispatch, useSelector } from 'react-redux';
import UserInfoPaymentStripe from '../popup/PopupUserInfoPaymentStripe';

function UserInfo() {

    const auth = useAuth();
    const popup = usePopup();

    const langueState = useSelector(state => state.langue);
    const { langue } = langueState;

    const dispatch = useDispatch();

    const [email, setEmail] = useState(auth.user.email);
    const [name, setName] = useState(auth.user.name);
    const [prenom, setPrenom] = useState(auth.user.prenom);
    const [country, setCountry] = useState(auth.user.country);
    const [promo, setPromo] = useState(auth.user.promo);
    const [password, setPassword] = useState(auth.user.password);
    const [remember, setRemember] = useState(auth.user.remember);
    const [isActive, setIsActive] = useState(auth.user.isActive);
    const [newsletter, setnewsLetter] = useState(auth.user.newsletter);
    const [actived, setActived] = useState(false);

    const [state, setState] = useState(auth.user.state);

    const { t, i18n } = useTranslation();

    let [isOpen, setIsOpen] = useState(false);
    let [isOpenStripe, setIsOpenStripe] = useState(false);


    useEffect(() => {
        setState(auth.user.state);
    }, [auth.user.state]);

    const updateInfo = (event) => {
        try {
            event.preventDefault();
            auth.update({ '_id': auth.user._id, password, name, email, remember, state, prenom, country, newsletter });
            popup.open(t("userupdated"));
        } catch (error) {
            popup.open(error);
        }
    }

    const activate = async (event) => {
        try {
            event.preventDefault();
            await auth.activate(auth.user._id, langue);
            setActived(true);
            popup.open(t("activateemail"));
        } catch (error) {
            popup.open(error);
        }
    }

    const subscribe = (event) => {
        event.preventDefault();
        if (auth.user.isActive) {
            setIsOpenStripe(true);
        } else {
            popup.open(t("usernoactive"));
        }
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const togglePopupStripe = () => {
        setIsOpenStripe(!isOpenStripe);
    }

    return (
        <>
            {/* {isOpen && <UserInfoPayment
                openStripe={setIsOpenStripe} handleClose={togglePopup}
            />} */}
            {isOpenStripe && <UserInfoPaymentStripe
                handleClose={togglePopupStripe}
            />}
            <form onSubmit={updateInfo} className="user__login__form">
                <label htmlFor="email">{t("email")}</label>
                <input id="email" type="email" name="email" value={email} placeholder={t("email")} onChange={(e) => setEmail(e.target.value)} className="user__login__input" required readOnly={auth.user.isActive || actived} />

                <label htmlFor="name">{t("name")}</label>
                <input id="name" type="text" name="name" value={name} placeholder={t("name")} onChange={(e) => setName(e.target.value)} className="user__login__input" required />

                <label htmlFor="prenom">{t("prenom")}</label>
                <input id="prenom" type="text" name="prenom" value={prenom} placeholder={t("firstname")} onChange={(e) => setPrenom(e.target.value)} className="user__login__input" required />

                <label htmlFor="country">{t("country")}</label>
                <input id="country" type="text" name="country" value={country} placeholder={t("country")} onChange={(e) => setCountry(e.target.value)} className="user__login__input" required />

                <label htmlFor="passwordd">{t("newpassword")}</label>
                <input id="passwordd" type="text" name="passwordd" value={password} placeholder={t("newpassword")} onChange={(e) => { setPassword(e.target.value); }} className="user__login__input" />
                <div className='user__login__cb'>
                    {t("rememberme")}
                    <div className="checkbox" >
                        <input id="remember" type="checkbox" checked={remember} onChange={(e) => setRemember(e.target.checked)} />
                        <label htmlFor="remember" >
                            <span className="box"></span>
                        </label>
                    </div>
                    {t("newsletter")}
                    <div className="checkbox" >
                        <input id="newsletter" type="checkbox" checked={newsletter} onChange={(e) => setnewsLetter(e.target.checked)} />
                        <label htmlFor="newsletter" >
                            <span className="box"></span>
                        </label>
                    </div>
                    {/* {t("active")}
                    <div className={isActive ? "checkbox" : "checkboxnochange"} >
                        <input id="isActive" type="checkbox" checked={isActive} />
                        <label htmlFor="isActive" >
                            <span className="box"></span>
                        </label>
                    </div> */}
                </div>
                {/* <label htmlFor="status">{t("status")}</label>
                <input id="status" type="text" name="status" value={t(state)} className="user__login__input" readOnly /> */}

                <button type="submit" className="user__logout__button" >
                    {t("save")}
                </button>
            </form>
            {/* {state === "free" ?
                <>  {!auth.user.isActive && <div className="user__login__form">
                    <button onClick={(event) => activate(event)} className="user__logout__button"  >
                        {t("activeme")}
                    </button>
                </div>}

                    <form onSubmit={subscribe} className="user__login__form">
                        <button type="submit" className="user__logout__button" >
                            {t("subscribe")}
                        </button>
                    </form>
                </> :

                <div className="user__login__form">
                    <button type="submit" onClick={(event) => {
                        event.preventDefault();
                        const monAction = async () => {
                            const data = await auth.cancelSubcription({ '_id': auth.user._id });
                            if (data) dispatch(confirmResiliation({ email: auth.user.email, name: auth.user.name, prenom: auth.user.prenom, langue: langue }));
                        }
                        popup.setAction(() => monAction);
                        popup.openConfirm('cancelsubscribeconfirm', '', 'cancelsubscribe');

                    }} className="user__logout__button"  >
                        {t("cancelsubscribe")}
                    </button>
                </div>
            } */}
        </>
    )
}

export default UserInfo