export const LECONS_REQUEST = "LECONS_REQUEST";
export const LECONS_SUCCESS = "LECONS_SUCCESS";
export const LECONS_FAIL = "LECONS_FAIL";
export const LECON_REMOVE_ITEM = "LECON_REMOVE_ITEM";
export const LECON_CREATE_ITEM = "LECON_CREATE_ITEM";
export const LECON_CHANGE_ITEM = "LECON_CHANGE_ITEM";
export const LECON_CREATE_WORD = "LECON_CREATE_WORD";
export const LECON_REMOVE_WORD = "LECON_REMOVE_WORD";
export const LECON_VISIBLE = "LECON_VISIBLE";
export const LECON_FAVORITE = "LECON_FAVORITE";
export const LECONS_TEXTE_SUCCESS = "LECONS_TEXTE_SUCCESS";
export const LECON_TEXTE_REMOVE_ITEM = "LECON_TEXTE_REMOVE_ITEM";
export const LECON_TEXTE_CREATE_ITEM = "LECON_TEXTE_CREATE_ITEM";
export const LECON_TEXTE_CREATE_WORD = "LECON_TEXTE_CREATE_WORD";
export const LECON_TEXTE_REMOVE_WORD = "LECON_TEXTE_REMOVE_WORD";
export const LECON_TEXTE_CHANGE_ITEM = "LECON_TEXTE_CHANGE_ITEM";
export const LECON_MOT_UPDATE = "LECON_MOT_UPDATE";
export const LECON_MOT_FAVORITE = "LECON_MOT_FAVORITE";
export const LECON_TEXTE_UPDATE = "LECON_TEXTE_UPDATE";
export const LECON_TEXTE_FAVORITE = "LECON_TEXTE_FAVORITE";

export const MOTS_REQUEST = "MOTS_REQUEST";
export const MOTS_SUCCESS = "MOTS_SUCCESS";
export const MOTS_FAIL = "MOTS_FAIL";
export const MOTS_REMOVE_ITEM = "MOTS_REMOVE_ITEM";
export const MOT_SELECTED = "MOT_SELECTED";
export const MOT_UNSELECTED = "MOT_UNSELECTED";
export const MOT_SELECTED_UPDATE = "MOT_SELECTED_UPDATE";
export const MOT_SAVE = "MOT_SAVE";
export const MOT_RESET = "MOT_RESET";
export const MOT_CONFIG_REMOVE_ITEM = "MOT_CONFIG_REMOVE_ITEM";
export const MOT_LECON_SELECTED = "MOT_LECON_SELECTED";
export const MOT_LECON_UNSELECTED = "MOT_LECON_UNSELECTED";
export const MOT_CONFIG_SELECTED = "MOT_CONFIG_SELECTED";
export const MOT_CLEAN = "MOT_CLEAN";
export const MOT_LECON_SELECTED_BY_MOT = "MOT_SELECTED_BY_MOT";
export const MOT_LECON_SELECT_UNSELECT_ALL = "MOT_LECON_SELECT_UNSELECT_ALL";
export const MOTS_BANK_REQUEST = "MOTS_BANK_REQUEST";
export const MOTS_BANK_SUCCESS = "MOTS_BANK_SUCCESS";
export const MOTS_BANK_CLEAN = "MOTS_BANK_CLEAN";
export const MOT_UPDATE_BANK_WORD = "MOT_UPDATE_BANK_WORD";
export const MOT_CREATE_BANK_WORD = "MOT_CREATE_BANK_WORD";
export const MOT_PRINT_LIST = "MOT_PRINT_LIST";
export const MOT_FONT = "MOT_FONT";
export const MOTS_FOCUS = "MOTS_FOCUS";
export const MOT_ISFOCUS = "MOT_ISFOCUS";
export const MOT_ONLYFOCUS_BANK_WORD = "MOT_ONLYFOCUS_BANK_WORD";

export const TEXTES_REQUEST = "TEXTES_REQUEST";
export const TEXTES_SUCCESS = "TEXTES_SUCCESS";
export const TEXTES_FAIL = "TEXTES_FAIL";
export const TEXTES_REMOVE_ITEM = "TEXTES_REMOVE_ITEM";
export const TEXTE_SELECTED = "TEXTE_SELECTED";
export const TEXTE_UNSELECTED = "TEXTE_UNSELECTED";
export const TEXTE_SELECTED_UPDATE = "TEXTE_SELECTED_UPDATE";
export const TEXTE_SAVE = "TEXTE_SAVE";
export const TEXTE_RESET = "TEXTE_RESET";
export const TEXTE_CONFIG_REMOVE_ITEM = "TEXTE_CONFIG_REMOVE_ITEM";
export const TEXTE_LECON_SELECTED = "TEXTE_LECON_SELECTED";
export const TEXTE_LECON_UNSELECTED = "TEXTE_LECON_UNSELECTED";
export const TEXTE_CONFIG_SELECTED = "TEXTE_CONFIG_SELECTED";
export const TEXTE_CLEAN = "TEXTE_CLEAN";
export const TEXTE_LECON_SELECTED_BY_MOT = "TEXTE_SELECTED_BY_MOT";
export const TEXTE_LECON_SELECT_UNSELECT_ALL = "TEXTE_LECON_SELECT_UNSELECT_ALL";
export const TEXTES_BANK_REQUEST = "TEXTES_BANK_REQUEST";
export const TEXTES_BANK_SUCCESS = "TEXTES_BANK_SUCCESS";
export const TEXTES_BANK_CLEAN = "TEXTES_BANK_CLEAN";
export const TEXTE_UPDATE_BANK_WORD = "TEXTE_UPDATE_BANK_WORD";
export const TEXTE_CREATE_BANK_WORD = "TEXTE_CREATE_BANK_WORD";
export const TEXTE_FONT = "TEXTE_FONT";
export const TEXTES_FOCUS = "TEXTES_FOCUS";
export const TEXTE_ISFOCUS = "TEXTE_ISFOCUS";
export const TEXTE_ONLYFOCUS_BANK_WORD = "TEXTE_ONLYFOCUS_BANK_WORD";

export const CONFIGS_REQUEST = "CONFIGS_REQUEST";
export const CONFIGS_SUCCESS = "CONFIGS_SUCCESS";
export const CONFIGS_FAIL = "CONFIGS_FAIL";
export const CONFIG_REMOVE_ITEM = "CONFIG_REMOVE_ITEM";
export const CONFIG_CREATE_ITEM = "CONFIG_CREATE_ITEM";
export const CONFIG_CHANGE_NAME = "CONFIG_CHANGE_NAME";

export const GAME_RANDOM_ARRAY = "GAME_RANDOM_ARRAY";
export const GAME_FROM = "GAME_FROM";
export const GAME_TO = "GAME_TO";
export const GAME_FROMTO_VISIBLE = "GAME_FROMTO_VISIBLE";
export const GAME_CHOICE = "GAME_CHOICE";
export const GAME_ONLYFOCUS = "GAME_ONLYFOCUS"

export const GAMETEXTE_RANDOM_ARRAY = "GAMETEXTE_RANDOM_ARRAY";
export const GAMETEXTE_FROM = "GAMETEXTE_FROM";
export const GAMETEXTE_TO = "GAMETEXTE_TO";
export const GAMETEXTE_FROMTO_VISIBLE = "GAMETEXTE_FROMTO_VISIBLE";
export const GAMETEXTE_CHOICE = "GAMETEXTE_CHOICE";
export const GAMETEXTE_ONLYFOCUS = "GAME_TEXTEONLYFOCUS"

export const USER_SIGNOUT = "USER_SIGNOUT";
export const CHANGE_LANGUE = "CHANGE_LANGUE";

export const MAIL_MESSAGE = "MAIL_MESSAGE";
export const MAIL_UPDATE = "MAIL_UPDATE";

export const GAME_SELF = "GAME_SELF";
export const GAME_MULTI = "GAME_MULTI";
export const GAME_WRITE = "GAME_WRITE";

export const GAMETEXTE_SELF = "GAMETEXTE_SELF";
export const GAMETEXTE_MULTI = "GAMETEXTE_MULTI";