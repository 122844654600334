import React, { useEffect, useState } from "react";
import upload from "../../../images/PNG/upload-english.png";
import setting from "../../../images/PNG/reglage-upload-english.png";
import fileexemple from "../../../images/PNG/file-exemple.png";
import quizexplanation from "../../../images/PNG/quizexplanationenglish.webp";
import lessonstatus from "../../../images/PNG/lessonStatusEN.webp";
import print from "../../../images/PNG/print.webp";
import kanjilist from "../../../images/PNG/kanjiList.webp";
import jlpt from "../../../images/PNG/oneclick.webp";
import focus1 from "../../../images/PNG/focus1En.webp";
import focus2 from "../../../images/PNG/focus2En.webp";
import imagesetting from "../../../images/PNG/imageSetting.webp";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLangue } from "../../../redux/actions/langueActions";

function Document() {

  const langueState = useSelector(state => state.langue);
  const { langue } = langueState;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langued, setLangued] = useState(langue);

  useEffect(() => {
    if (langued === 'jap') {
      i18n.changeLanguage('jap');
    } else if (langued === 'fre') {
      i18n.changeLanguage('fre');
    } else {
      i18n.changeLanguage('en');
    }
    dispatch(changeLangue(langued));
    return () => {
    };
  }, [langued]);

  return (
    <section className="doc">
      <section className="doc__container">

        <div className="user__language">
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="en" name="word" checked={langued === 'en'}
              onChange={() => setLangued('en')}
              type="radio" name="langue" />
            <label className="user__language__radio--label" htmlFor="en" ></label>
            <span className="user__language__lg">English</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="fr" type="radio" name="word" checked={langued === 'fre'}
              onChange={() => setLangued('fre')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="fr" ></label>
            <span className="user__language__lg">Français</span>
          </div>
          <div className="user__language__radio" >
            <input className="user__language__radio--input" id="jp" type="radio" name="word" checked={langued === 'jap'}
              onChange={() => setLangued('jap')}
              name="langue" />
            <label className="user__language__radio--label" htmlFor="jp" ></label>
            <span className="user__language__lg">日本語</span>
          </div>
        </div>
        <div id='top' className="doc__index">
          <div className="doc__short">
            <span className="material-icons md-24">schedule</span>
            <a href="#quick">
              <h2>Quickstart without an account.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">extension</span>
            <a href="#newfeaturequiz">
              <h2>New feature Quiz.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">description</span>
            <a href="#quickaccount">
              <h2>Quickstart.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">view_module</span>
            <a href="#lessonstatus">
              <h2>Lesson status.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">grade</span>
            <a href="#bookmark">
              <h2>Bookmark.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">add_photo_alternate</span>
            <a href="#image">
              <h2>How to set the image.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">print</span>
            <a href="#print">
              <h2>How to print pictures or Kanji.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">filter_9_plus</span>
            <a href="#jlpt">
              <h2>How to import JLPT.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">content_copy</span>
            <a href="#file">
              <h2>How to import lessons.</h2>
            </a>
          </div>
          <div className="doc__short">
            <span className="material-icons md-24">build</span>
            <a href="#trouble">
              <h2>Troubleshooting.</h2>
            </a>
          </div>

        </div>
        <div id="quick" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">schedule</span>
            Quickstart without an account.</h1>
          <br />
          <p>
            This video will introduce you to the concept of this application without having to create an account.
          </p><p>
            You will be able to use the flashcards with the sample data and understand the potential that this tool will offer you.
          </p>
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/Dqxk7RQJ6_w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
        </div>
        <div id="newfeaturequiz" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">extension</span>
            New feature in Quiz.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            New features in the quiz.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image2"
            src={quizexplanation}
          />
        </div>
        <div id="quickaccount" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">description</span>Quickstart.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            This video will guide you in creating your data. How to create your lessons, your study setup, your word lists.
          </p>
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/EDxoopCck6E" title="Demo Logged" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
        </div>
        <div id="lessonstatus" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">view_module</span>Lesson status.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            You can set up to 3 differents status to your lessons and filter them. It will help you to organize your progression.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={lessonstatus}
          />
        </div>
        <div id="bookmark" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">grade</span>Bookmark.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            We always have some struggling words to remember. This bookmark list will be very helpful.<br />
            During practicing flashcards, you can bookmark the word...
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={focus1}
          />
          <br />
          <p>
            ...and retrieve it in the bookmark list.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={focus2}
          />
        </div>
        <div id="image" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">add_photo_alternate</span>How to set the image.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            You can set any image from the web. Just copy the link of the image in the image input.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={imagesetting}
          />
          <br />
        </div>
        <div id="print" className="doc__section">
          <br /><h1><span className="material-icons md-24">print</span>Print pictures or Kanji.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            You can print up to 16 images or Kanji to practice your writing.          </p>
          <p>
            Select the words and click on the printer icon. Optimized for A4 format.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={print}
          />
          <p>
            A new window will appear with the images or Kanji.
          </p>
          <p>
            You will only have to print them with the options of your browser.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={kanjilist}
          />
        </div>
        <div id="jlpt" className="doc__section">
          <br /><h1><span className="material-icons md-24">filter_9_plus</span>How to import JLPT.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            In the setting tab, you can select the language you want to have to use.<br />
            If you chose English and French, the application will create this configuration :
          </p>
          <br />
          <div className="doc__list">
            <ul>
              <li>French</li>
              <li>English</li>
              <li>Japanese Kanji</li>
              <li>Furigana</li>
              <li>Romanji</li>
            </ul>
          </div>
          <br />
          <p>
            If you chose English , the application will create this configuration :
          </p>
          <br />
          <div className="doc__list">
            <ul>
              <li>English</li>
              <li>Japanese Kanji</li>
              <li>Furigana</li>
              <li>Romanji</li>
            </ul>
          </div>
          <br />
          <p>
            If you chose French , the application will create this configuration :
          </p>
          <br />
          <div className="doc__list">
            <ul>
              <li>French</li>
              <li>Japanese Kanji</li>
              <li>Furigana</li>
              <li>Romanji</li>
            </ul>
          </div>
          <br />
          <img
            alt="Upload"
            className="doc__image3"
            src={jlpt}
          />
          <br />
        </div>
        <div id="file" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">content_copy</span>How to import lessons.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            Even if we recommend writing at least once the data, it can be tough to do it if there are plenty of them.
            With the import feature, available only for the subscribers, you would easily create lessons using a text file.
          </p>
          <br />
          <img
            alt="Upload"
            className="doc__image"
            src={upload}
          />
          <br />
          <p>
            Let's use this setting as an example:
          </p>
          <br />
          <img
            alt="Upload setting"
            className="doc__image"
            src={setting}
          />
          <br />
          <p>
            1- Create a ".txt" file. For example <strong>body.txt</strong><br />
            The name of this file will be the name of the lesson.<br />
            2- Edit the file with a text editor like notepad.<br />
            Each row will be will be a new item.<br />
            For each category, you will use  --- as separator.<br />
            Below is an example to help you.
          </p>
          <br />
          <img
            className="doc__image"
            alt="Upload setting"
            src={fileexemple}
          />
          <br />
          <br />
          <h1>
            Demonstration
          </h1>
          <br />
          <br />
          <div className="doc__bg-video" >
            <iframe width="100%" height="348px" src="https://www.youtube.com/embed/FSkZxSHIfD0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
          <br />
          <p>
            Thank this feature, you will quickly create your lesson, but it will also be possible to share your files among other people using the same configuration.
            <br />
            A teacher can also prepare some information in advance and share it with the students.
          </p>
        </div>
        <div id="trouble" className="doc__section">
          <br />
          <h1><span className="material-icons md-24">build</span>Troubleshooting.<a href="#top">⬆️</a></h1>
          <br />
          <p>
            You may sometimes encounter connection problems.<br />
            Here are two methods that will solve these problems.<br />
            1 - You can first log out and log in.<br />
            If the problem persists :<br />
            2 - Delete cookies for this site.<br /><br />
          </p>
        </div>
      </section>
    </section>
  );
}

export { Document };
