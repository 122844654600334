import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import './index.css';
import App from './App';
import store from './redux/store';
import { ProvideAuth } from "./components/use-auth";
import { ProvidePopUp } from "./components/popup/PopupContext";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";


ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ProvidePopUp>
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </ProvidePopUp>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);