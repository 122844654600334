import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createConfig, saveConfigs } from '../../redux/actions/configActions';
import { importAutoLecon, importLecon } from '../../redux/actions/leconActions';
import { useAuth } from "./../use-auth";
import Config from './Config';
import IconSite from './../IconSite';
import { usePopup } from "../popup/PopupContext";
import { useTranslation } from "react-i18next";
import AutoImportConfig from './AutoImportConfig';
import PopupMemoLoading from '../popup/PopupMemoLoading';

let Spinner = require('react-spinkit');

function Configs() {

    const config = useSelector(state => state.config);
    const { configs, loading, error } = config;

    const auth = useAuth();
    const popup = usePopup();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const createNewConfig = (event, type) => {
        event.preventDefault();
        if (auth.user) {
            dispatch(createConfig(auth.user, type, t('configuration'))).catch(error => alert("configs.js row 26 : " + error));
        } else {
            popup.open(t("signupwarning"));
        }
    }

    const save = (event) => {
        event.preventDefault();
        if (auth.user) {
            dispatch(saveConfigs()).then(res => popup.open(t("configsaved"))).catch(error => alert("configs.js row 35 : " + error));
        } else {
            popup.open(t("signupwarning"));
        }
    }

    const uploadFile = (files, type) => {

        if (auth.user && auth.user.state === 'full') {
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                let name = file.name.split('.')[0];
                let reader = new FileReader();
                reader.onload = function () {
                    let text = reader.result;
                    let lines = text.split('\r\n');
                    dispatch(importLecon(name, lines, auth.user, type)).catch(error => alert("configs.js row 35 : " + error + ",the file may be too long, please split it!"));
                };
                reader.readAsText(file);
            }
        }
    }

    const autoFillFile = (type) => {
        if (auth.user) {
            dispatch(importAutoLecon(auth.user, type)).catch(error => alert("configs.js row 59 : " + error + ",the file may be too long, please split it!"));
        }
    }

    return (

        loading ?
            <>
                <PopupMemoLoading
                    content={t("importprogress")}
                />
                <section className="configs" >
                    <form >
                        <div className="configs__title">
                            {t("word")}
                        </div>
                        <div><Spinner name='cube-grid-bounce' /></div>
                    </form>
                    <form >
                        <div className="configs__title">
                            {t("texte")}
                        </div>
                        <div><Spinner name='cube-grid-bounce' /></div>
                    </form>
                </section></>
            : error ? <div>{error}</div> :
                <>
                    <div className="configs">
                        <form onSubmit={save} >
                            <div className="configs__title">
                                {t("word")}
                            </div>
                            <div className="configs__menu">
                                <button type="button" onClick={(event) => createNewConfig(event, 'word')} className="icon-button" >
                                    <IconSite name="icon-squared-plus" cssname="icon" />
                                </button>
                                <span>{t("addconfig")}</span>
                                <button type="submit" className="icon-button"  >
                                    <IconSite name="icon-save" cssname="icon" />
                                </button>
                                <span>{t("saveconfig")}</span>
                            </div>
                            {auth.user === null &&
                                <div className="tuto">
                                    <p>{t("configmanagement")}</p>
                                    <p className="tuto__help">{t("trick")}</p>
                                </div>}

                            <ul className="configs__list">
                                {
                                    configs.map(config => config.type === 'word' &&
                                        <li key={config._id} className="configs__item">
                                            <Config _id={config._id} config_number={configs.filter(x => x.type === "word").length} name={config.name} position={config.position} isEnable={config.isEnable}/>
                                        </li>
                                    )
                                }
                            </ul>

                        </form>
                        <AutoImportConfig />
                        {auth.user && auth.user.state === 'full' ?
                            <>
                                <div className="fileImport" >
                                    <span className="material-icons md-24 md-light">upload_file</span>
                                    <input id="file-upload-word" type="file" multiple={true} accept='text/plain' onChange={(e) => uploadFile(e.target.files, "word")} />
                                    <label htmlFor="file-upload-word" className="custom-file-upload">
                                        {t("upload")}
                                    </label>
                                </div>
                            </>
                            : ""}
                    </div>
                    <div className="configs">
                        <form onSubmit={save} >
                            <div className="configs__title">
                                {t("texte")}
                            </div>
                            <div className="configs__menu">
                                <button type="button" onClick={(event) => createNewConfig(event, 'texte')} className="icon-button" >
                                    <IconSite name="icon-squared-plus" cssname="icon" />
                                </button>
                                <span>{t("addconfig")}</span>
                                <button type="submit" className="icon-button"  >
                                    <IconSite name="icon-save" cssname="icon" />
                                </button>
                                <span>{t("saveconfig")}</span>
                            </div>
                            <ul className="configs__list">
                                {
                                    configs.map(config => config.type === 'texte' &&
                                        <li key={config._id} className="configs__item">
                                            <Config _id={config._id} config_number={configs.filter(x => x.type === "texte").length} name={config.name} position={config.position} />
                                        </li>
                                    )
                                }
                            </ul>
                        </form>
                        {auth.user && auth.user.state === 'full' ?
                            <div className="fileImport" >
                                <span className="material-icons md-24 md-light">upload_file</span>
                                <input id="file-upload-texte" type="file" multiple={true} accept='text/plain' onChange={(e) => uploadFile(e.target.files, "texte")} />
                                <label htmlFor="file-upload-texte" class="custom-file-upload">
                                    {t("upload")}
                                </label>
                            </div>
                            : ""}
                    </div>
                </>


    )
}

export default Configs
