import React from "react";

function SubcriptionFree() {
  return (
    <section className="subcriptionfree">
      <h1>FREE</h1>
      <ul>
        <li>Create settings for word and text.</li>
        <li>Create, update, delete lessons.</li>
        <li>Create, update, delete words and texts.</li>
        <li>Import lessons with text files.</li>
        <li className="backgroundgreen">One click import All of JLPT N5,N4,N3,N2 and N1.</li>
        <li>Play with every types of flashcard game.</li>
        <li >Print up to 16 Images or Kanji.</li>
        {/* <li>
          No time commitment.<br />You can stop the subscription whenever you want with a simple click.
          <br />Any started month is not refundable.
        </li> */}
      </ul>
    </section>
  );
}

export { SubcriptionFree };
