import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../use-auth";
import { useTranslation } from "react-i18next";

const getClassFocus = (isfocus) => {
  let czz = "material-icons md-light fromCursor";
  if (isfocus === '1') {
    czz = "material-icons fromCursor";
  }
  return czz;
}

const GameInsideFromToNav = (props) => {

  const auth = useAuth();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let classN = getClassFocus(props.isfocus);

  const [cc, setCc] = useState(classN);

  const toogleFocus = (isfocus) => {
    setCc(getClassFocus(isfocus));
  }

  const searchJisho = () => {
    window.open("https://jisho.org/search/" + props.guess);
  }

  const searchJishoS = () => {
    window.open("https://jisho.org/search/" + props.guess + "%20%23sentences");
  }

  const searchJishoK = () => {
    window.open("https://jisho.org/search/" + props.guess + "%20%23kanji");
  }

  useEffect(() => {
    setCc(getClassFocus(props.isfocus));

    return () => {
      //
    };
  }, [props.isfocus]);

  return (
    <>
      <div className="flashcardmultilightbuld">
        {auth.user === null &&
          <p className="tuto__enjoy">{t("enjoy")}&#128512;</p>
        }
        <span className="material-icons md-light fromCursor" onClick={() => dispatch(props.selectFromDecrement())}>vertical_align_top</span>
        <span className="material-icons lightbulb">lightbulb</span>
        <span className="material-icons md-light fromCursor" onClick={() => dispatch(props.selectFromIncrement())} >vertical_align_bottom</span>
        <span className="material-icons md-light fromCursor" onClick={() => props.changeFont()}>format_size</span>
        {!props.onlyFocus && <span className={cc} onClick={() => { toogleFocus(props.isFocus); props.focus(); }}>grade</span>}
      </div>
      <div className="flashcardmultilightbuld">
        <span className="material-icons md-light fromCursor" onClick={() => searchJisho()}>search</span>
        <span className="material-icons md-light fromCursor" onClick={() => searchJishoK()}>zoom_in</span>
        <span className="material-icons md-light fromCursor" onClick={() => searchJishoS()}>zoom_out</span>
      </div>
    </>
  );
};

export default GameInsideFromToNav;
