import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedMotImage } from '../../redux/actions/motActions';

function TranslateImage(props) {

    const mot = useSelector(state => state.mot);
    const { motSelected } = mot;

    const [image, setImage] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (motSelected){
            setImage(motSelected.image ? motSelected.image : '' );
        } 
        return () => {
            //
        };
    }, [motSelected]);

    useEffect(() => {
        dispatch(updateSelectedMotImage(image)).catch((error) => alert("Translate.js 23" + error));
        return () => {
            //
        };
    }, [image]);

    return (

        <div className="translate">
            <div className="translate__radio" >
            </div>
            <div className="translate__form">
                <div className="translate__form__name">Image:</div>
                <input id={"translateImg"} type="text" value={image} className="translate__form__input" onChange={(e) => setImage(e.target.value)} />
            </div>
        </div>
    )
}

export default TranslateImage