import React from 'react'

function CheckBoxJLPT(props) {
    return (
        <div className="autoimport__checkbox">
            <div className="checkbox" >
                <input id={props.name} type="checkbox" checked={props.value} onChange={(e) => props.checker(e.target.checked, props.name, props.setValue)} />
                <label htmlFor={props.name} >
                    <span className="box"></span>
                </label>
            </div>
            <h3>
                {props.display}
            </h3>
        </div>
    )
}
export default CheckBoxJLPT
