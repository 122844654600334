import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Mot from './Mot';
import { useTranslation } from "react-i18next";
import configApp from '../../config';
import { useAuth } from "../use-auth";
import { LECON_CREATE_ITEM, MOT_FONT, MOT_ISFOCUS } from '../../redux/actionTypes';
import FocusMot from './FocusMot';
import { copyFocus } from '../../redux/actions/leconActions';
import PopupPrint from '../popup/PopupPrint';


var Spinner = require('react-spinkit');

function Mots() {

    const auth = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let [isOpen, setIsOpen] = useState(false);

    const mot = useSelector(state => state.mot);
    const { lecons, mots, loading, error, motSelected, motToPrints, font, focus, isFocus, configSelected } = mot;

    const config = useSelector(state => state.config);
    const { configs } = config;

    let fontUI = font === "no" || font === undefined ? "mot__form__name" : font;

    const changeFont = () => {
        if (fontUI === "mot__form__name") {
            dispatch({ type: MOT_FONT, payload: "mot__form__name middlefont" });
        } else {
            dispatch({ type: MOT_FONT, payload: "mot__form__name" });
        }
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const toogleFocus = () => {
        dispatch({ type: MOT_ISFOCUS, payload: !isFocus });
    }

    const copyFocusUI = () => {
        if (focus && focus.length > 0) dispatch(copyFocus(auth.user, "word", LECON_CREATE_ITEM)).catch((error) => alert("Lecon.js 53 " + error));
    }

    const sendForm = (image, size) => {

        let config = configSelected && configSelected._id ? configSelected._id : configs[0]._id;

        window.open(configApp.URL_BACKEND + "/api/word/print?config= " + config
            + "&image=" + image
            + "&format=" + size
            + "&w1=" + motToPrints[0]
            + "&w2=" + motToPrints[1]
            + "&w3=" + motToPrints[2]
            + "&w4=" + motToPrints[3]
            + "&w5=" + motToPrints[4]
            + "&w6=" + motToPrints[5]
            + "&w7=" + motToPrints[6]
            + "&w8=" + motToPrints[7]
            + "&w9=" + motToPrints[8]
            + "&w10=" + motToPrints[9]
            + "&w11=" + motToPrints[10]
            + "&w12=" + motToPrints[11]
            + "&w13=" + motToPrints[12]
            + "&w14=" + motToPrints[13]
            + "&w15=" + motToPrints[14]
            + "&w16=" + motToPrints[15]
        );
    }

    return (
        loading ?
            <form className="mots">
                <div className="mots__menu">
                    <Spinner name='cube-grid-bounce' />
                </div>
            </form>
            : error ? <div>{error}</div> :
                <div className="mots">
                    {isOpen && <PopupPrint
                        content={<>lecon</>
                        }
                        handleClose={togglePopup}
                        handleSave={(image, size) => sendForm(image, size)}
                    />}

                    <div className="mots__menu">
                        <span>{lecons.length} {t("lecons")}</span>
                        <span>{mots.length} {t("words")}</span>
                        {!isFocus && <span className="material-icons md-36 md-light">feedback</span>}
                        {isFocus ?
                            <>
                                <span className="material-icons md-36 md-light pointer" onClick={() => copyFocusUI()}>content_copy</span>
                                <span className="material-icons md-36 md-light green pointer" onClick={() => toogleFocus()}>grade</span>
                            </>
                            :
                            <span className="material-icons md-36 md-light pointer" onClick={() => toogleFocus()}>grade</span>
                        }
                        <span className="material-icons md-36 md-light pointer" onClick={() => changeFont()}>format_size</span>
                        <form  >
                            <button
                                type="button"
                                className="icon-button"
                                onClick={() => togglePopup()}
                            >
                                <span className="material-icons md-36 md-light">print</span>
                            </button>
                        </form>
                    </div>
                    {auth.user === null &&
                        <div className="tuto">
                            <p>{t("wordmanagement")}</p>
                            <p className="tuto__help">{t("trick")}</p>
                        </div>}

                    {isFocus ?
                        <ul className="mots__list" >
                            {
                                focus.map(mot =>
                                    <li key={mot._id} className="mots__item" >
                                        <FocusMot _id={mot._id} font={fontUI} name={mot.name} isSelected={motSelected && mot._id === motSelected._id ? true : false} />
                                    </li>
                                )
                            }
                        </ul>

                        :
                        <ul className="mots__list" >
                            {
                                mots.map(mot =>
                                    <li key={mot._id} className="mots__item" >
                                        <Mot _id={mot._id} font={fontUI} name={mot.name} focus={mot.focus} isSelected={motSelected && mot._id === motSelected._id ? true : false} />
                                    </li>
                                )
                            }
                        </ul>
                    }

                </div>
    )
}

export default Mots
