import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addMotPrint, removeMotPrint, selectMot } from '../../redux/actions/motActions';
import { usePopup } from "../popup/PopupContext";
import { useTranslation } from "react-i18next";

function Mot(props) {

    const dispatch = useDispatch();

    const popup = usePopup();
    const { t } = useTranslation();

    const mot = useSelector(state => state.mot);
    const { motToPrints } = mot;

    let isInPrintList = motToPrints && motToPrints.find(o => o === props._id) ? true : false;

    let [isSelected, setisSelected] = useState(props.isSelected);

    const toggleWord = () => {
        dispatch(selectMot(props._id)).catch((error) => alert("Mot.js 13 " + error));
    }

    const handlePrintWord = (checked) => {
        if (checked) {
            if (motToPrints.length <= 15) {
                dispatch(addMotPrint(props._id)).catch((error) => alert("Mot.js 19 " + error));
            } else {
                popup.open(t("maxnine"));
            }
        } else {
            dispatch(removeMotPrint(props._id)).catch((error) => alert("Mot.js 21 " + error));
        }
    }

    useEffect(() => {
        setisSelected(props.isSelected);
        return () => {
        };
    }, [props.isSelected]);

    let classN = "mot__form";
    if (props.focus === '1') {
        classN = "mot__form mot__focus";
    }

    return (

        <div className="mot">
            <div className="mot__radio" >
                <input className="mot__radio--input" id={props._id} type="radio" name="word" checked={isSelected} onChange={() => toggleWord()} />
                <label className="mot__radio--label" htmlFor={props._id} >
                </label>
            </div>
            <div className={classN}>
                <div className={props.font}>{props.name}</div>
                <div className="checkbox" >
                    <input id={props._id + "cb"} type="checkbox" checked={isInPrintList} onChange={(e) => handlePrintWord(e.target.checked)} />
                    <label htmlFor={props._id + "cb"} >
                        <span className="box"></span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default Mot