import React from "react";
import { useDispatch } from "react-redux";
import IconSite from "../IconSite";

const GameBarAction = (props) => {

  const dispatch = useDispatch();

  return (
    <div className="flashcardself__menu">
      <button onClick={() => props.goLeft()} className="icon-button">
        <IconSite name="icon-chevron-left" cssname="icon" />
      </button>
      <button
        onClick={() => {
          props.reStart();
        }}
        className="icon-button"
      >
        <IconSite name="icon-cycle" cssname="icon" />
      </button>
      <button onClick={() => props.showSolution()} className="icon-button">
        <IconSite name="icon-help" cssname="icon" />
      </button>
      <button onClick={() => props.goRight()} className="icon-button">
        <IconSite name="icon-chevron-right" cssname="icon" />
      </button>
      <span className="material-icons md-light toCursor" onClick={() => dispatch(props.selectToDecrement())}>vertical_align_top</span>
      <span className="material-icons md-light toCursor" onClick={() => dispatch(props.selectToIncrement())} >vertical_align_bottom</span>
    </div>
  );
};

export default GameBarAction;
