import React from "react";
import {  useTranslation } from "react-i18next";

const PopupError = props => {

    const {t} = useTranslation();

    return (
        <div className="popup"  >
            <div className="popup__notification--error" onClick={props.handleClose}>
                <div className="popup__notification__message">{t(props.content)}</div>
                <span className="material-icons md-36">disabled_by_default</span>
            </div>
        </div>
    );
};

export default PopupError;