import React from 'react';
import icons from '../images/symbol-defs.svg';

const IconSite = props => {
	/*
		<use xlinkHref={`${icons}#${props.name}`} />
		<use xlinkHref={`/static/media/symbol-defs.8050f53c.svg#${props.name}`} />
	
		*/

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			className={`${props.cssname}`}
		>
			<use xlinkHref={`${icons}#${props.name}`} />

		</svg>
	)
}

export default IconSite