import React, { useState, useEffect } from 'react'
import { useAuth } from "../use-auth";
import axios from 'axios';
import configApp from '../../config';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    CardNumberElement, CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { confirmSubscription } from '../../redux/actions/mailActions';

var Spinner = require('react-spinkit');

const AccountSubscription = ({ subscription }) => {
    return (
        <section>
            <hr />
            <h4>
                <a href={`https://dashboard.stripe.com/test/subscriptions/${subscription.id}`}>
                    {subscription.id}
                </a>
            </h4>

            <p>
                Status: {subscription.status}
            </p>

            <p>

            </p>

            <p>
                Current period end: {(new Date(subscription.current_period_end * 1000).toString())}
            </p>

            {/* <Link to={{pathname: '/change-plan', state: {subscription: subscription.id }}}>Change plan</Link><br /> */}

        </section>
    )
}

const SubscribeForm = (props) => {

    const auth = useAuth();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const langueState = useSelector(state => state.langue);
    const { langue } = langueState;

    // Get the lookup key for the price from the previous page redirect.
    const [isLoading, setIsLoading] = useState(false);
    const [promo, setPromo] = useState('');

    const [name, setName] = useState(auth.user.name);
    const [messages, _setMessages] = useState('');
    const [subscriptions, setSubscriptions] = useState(auth.user.subscriptions);

    // helper for displaying status messages.
    const setMessage = (message) => {
        _setMessages(`${messages}\n\n${message}`);
    }

    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();

    if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return '';
    }

    // When the subscribe-form is submitted we do a few things:
    //
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement);

        // Use card Element to tokenize payment details
        let { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: name,
            }
        });

        if (error) {
            // show error and collect new card details.
            await auth.sendError(JSON.stringify(error));
            setIsLoading(false);
            setMessage(error.message + t("closeandredo"));
            return;
        }

        //setMessage(`Payment method created ${paymentMethod.id}`);

        // Create the subscription.
        //const { data } = await axios.post(configApp.URL_BACKEND + "/api/auth/create-subscription", { 'paymentMethodId': paymentMethod.id, 'priceLookupKey': 'Basic' });
        const data = await auth.subcription(paymentMethod, promo);
        let { subError, subscription } = data;

        if (subError) {
            // show error and collect new card details.
            await auth.sendError(JSON.stringify(subError));
            setIsLoading(false);
            if (subError.message === 'wrongcoupon') {
                setMessage(t("wrongcoupon") + t("closeandredo"));
            } else {
                setMessage(subError.message + t("closeandredo"));
            }
            return;
        }

        //setMessage(`Subscription created with status: ${subscription.status}`);
        let newSubs = [...subscriptions, subscription];
        setSubscriptions(newSubs);

        // This sample only supports a Subscription with payment
        // upfront. If you offer a trial on your subscription, then
        // instead of confirming the subscription's latest_invoice's
        // payment_intent. You'll use stripe.confirmCardSetup to confirm
        // the subscription's pending_setup_intent.
        switch (subscription.status) {
            case 'active':
                // Redirect to account page
                setIsLoading(false);
                setMessage(t("subcriptionSuccess"));
                await auth.paiementsuccessfull();
                dispatch(confirmSubscription({ email: auth.user.email, name: auth.user.name, prenom: auth.user.prenom, langue: langue, subscription: subscription }));
                break;

            case 'incomplete':
                //setIsLoading(false);
                //setMessage("Please confirm the payment.");

                // Handle next actions
                //
                // If the status of the subscription is `incomplete` that means
                // there are some further actions required by the customer. In
                // the case of upfront payment (not trial) the payment is confirmed
                // by passing the client_secret of the subscription's latest_invoice's
                // payment_intent.
                //
                // For trials, this works a little differently and requires a call to
                // `stripe.confirmCardSetup` and passing the subscription's
                // pending_setup_intent's client_secret like so:
                //
                //   const {error, setupIntent} = await stripe.confirmCardSetup(
                //     subscription.pending_setup_intent.client_secret
                //   )
                //
                // then handling the resulting error and setupIntent as we do below.
                //
                // This sample does not support subscriptions with trials. Instead, use these docs:
                // https://stripe.com/docs/billing/subscriptions/trials
                const { error } = await stripe.confirmCardPayment(
                    subscription.latest_invoice.payment_intent.client_secret,
                )

                if (error) {
                    await auth.sendError(JSON.stringify(error));
                    setIsLoading(false);
                    setMessage(error.message + t("closeandredo"));
                } else {
                    setIsLoading(false);
                    setMessage(t("subcriptionSuccess"));
                    //setSubscriptions({ status: 'active' });
                    await auth.paiementsuccessfull();
                    dispatch(confirmSubscription({ email: auth.user.email, name: auth.user.name, prenom: auth.user.prenom, langue: langue, subscription: subscription }));
                }
                break;

            default:
                setIsLoading(false);
                setMessage(`Unknown Subscription status: ${subscription.status}`);
        }
    }

    return (
        <form onSubmit={handleSubmit} className="popup__paiement__container__stripe" >
            <div className="popup__paiement__notification">
                <div className="paiement_card_container">
                    <div className="paiement_card_container__sectigo">
                        {t("sectigo")}
                    </div>
                    <div className="paiement_card_container__stripe" >
                        {t("stripe")}
                    </div>
                    <div className="paiement_card_container__info" >
                        {t("infopaiement")}
                    </div>
                    <div className="paiement_card_container__promoCode" >
                        {t("promo")}
                        <input id="promo" type="text" name="promo" value={promo} placeholder={t("promo")} onChange={(e) => { setPromo(e.target.value); }} className="paiement_card_container__promo" />
                    </div>

                    <div className="paiement_card_container__cardnumber">
                        <div className="num-margin-bottom">{t("cardnumber")}</div>
                        <div style={{
                            border: '2px solid #fff',
                            borderRadius: '4px',
                            padding: '5px 4px',
                            backgroundColor: '#fff'
                        }}>
                            <CardNumberElement options={{
                                style: {
                                    base: {
                                        fontWeight: '500',
                                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                        fontSize: '16px',
                                        iconColor: '#c4f0ff',
                                        ':-webkit-autofill': {
                                            color: '#fce883',
                                        },
                                        '::placeholder': {
                                            color: '#000',
                                        },
                                    },
                                    invalid: {
                                        iconColor: '#FFCC66',
                                        color: '#FFCC66',
                                    },
                                }
                            }} />
                        </div>
                    </div>
                    <div className="paiement_card_container__expiredate">
                        <div className="num-margin-bottom">{t("expiredate")}</div>
                        <div style={{
                            border: '2px solid #fff',
                            borderRadius: '4px',
                            padding: '5px 4px',
                            backgroundColor: '#fff',
                            marginRight: '5px'
                        }}>
                            <CardExpiryElement options={{
                                style: {
                                    base: {
                                        fontWeight: '500',
                                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                        fontSize: '16px',
                                        iconColor: '#c4f0ff',
                                        ':-webkit-autofill': {
                                            color: '#fce883',
                                        },
                                        '::placeholder': {
                                            color: '#000',
                                        },
                                    },
                                    invalid: {
                                        iconColor: '#FFCC66',
                                        color: '#FFCC66',
                                    },
                                }
                            }} />
                        </div>
                    </div>
                    <div className="paiement_card_container__cvc">
                        <div className="num-margin-bottom">{t("cvc")}</div>
                        <div style={{
                            border: '2px solid #fff',
                            borderRadius: '4px',
                            padding: '5px 4px',
                            backgroundColor: '#fff'
                        }}>
                            <CardCvcElement options={{
                                style: {
                                    base: {
                                        fontWeight: '500',
                                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                        fontSize: '16px',
                                        ':-webkit-autofill': {
                                            color: '#fce883',
                                        },
                                        '::placeholder': {
                                            color: '#000',
                                        },
                                    },
                                    invalid: {
                                        iconColor: '#FFCC66',
                                        color: '#FFCC66',
                                    },
                                }
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup__paiement__msg">{isLoading ? <Spinner name='cube-grid-bounce' /> : messages}</div>
            <button type="submit" className="popup__paiement__button" disabled={messages !== ''}>
                {t("subscribe")}
            </button>
            <button type="button" className="popup__paiement__button" onClick={props.handleClose}>
                {t("close")}
            </button>
        </form>

    )
}

function UserInfoPaymentStripe(props) {

    const auth = useAuth();

    const { t } = useTranslation();

    const [publishableKey, setPublishableKey] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        if (publishableKey) {
            setStripePromise(loadStripe(publishableKey));
        } else {
            axios.get(configApp.URL_BACKEND + "/api/auth/config").then(r => {
                setPublishableKey(r.data.publishableKey);
            })
        }
        return () => {
        };
    }, [publishableKey]);

    return (

        stripePromise ? <Elements stripe={stripePromise} >
            <div className="popup__paiement"  >
                <SubscribeForm name={auth.user.name} handleClose={props.handleClose} />
            </div>
        </Elements> : <div>No Paiement </div>
    )
}

export default UserInfoPaymentStripe
