import React from "react";
import game from "../../../images/PNG/smartphone-game.webp";
import lecon from "../../../images/PNG/smartphone-lecon.webp";
import setting from "../../../images/PNG/smartphone-setting.webp";

function Release() {

  return (
    <section className="release">
      <div className="release__container">
        <div id='top' className="release__index">
          <div className="release__short">
            <span className="material-icons md-24">maps_ugc</span>
            <a href="#quick">
              <h1>The future of the application.</h1>
            </a>
          </div>
        </div>
        <ul className="release__feature">
          <li className="release__feature__item">Add quizzes.</li>
          <li className="release__feature__item">Research lessons option.</li>
          <li className="release__feature__item">Lessons filter.</li>
          <li className="release__feature__item">Add a synthesis page from the result of the quizzes.</li>
          <li className="release__feature__item">Add tag for lessons.</li>
        </ul>
        <div id='top' className="release__index">
          <div className="release__short">
            <span className="material-icons md-24">maps_ugc</span>
            <a href="#quick">
              <h1>Projects in progress.</h1>
            </a>
          </div>
        </div>
        <ul className="release__feature">
          <li className="release__feature__item">Smartphone application.</li>
          <div className="release__smartphone">
            <img className="release__smartphone__item"
              alt="Game"
              src={game}
            />
            <img className="release__smartphone__item"
              alt="Lecon"
              src={lecon}
            />
            <img className="release__smartphone__item"
              alt="Setting"
              src={setting}
            />
          </div>
        </ul>
        <div id='top' className="release__index">
          <div className="release__short">
            <span className="material-icons md-24">maps_ugc</span>
            <a href="#quick">
              <h1>Projects in wait.</h1>
            </a>
          </div>
        </div>
        <ul className="release__feature">
          <li className="release__feature__item">Image management.</li>
          <li className="release__feature__item">Speech Recognition.</li>
        </ul>
        <br />
        <p>
          These projects will take me a long time to complete, but I want to achieve them so that the application will become a practical tool for your studies.
          Each subscription will be a precious help to accomplish future projects and tremendous recognition.
        </p>
        <br />
        <br />
        <h1>
          Thank you in advance for your support.
        </h1>
        <br />
      </div>
    </section>
  );
}
export { Release };
