import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { importAutoLecon } from '../../redux/actions/leconActions';
import { useAuth } from "./../use-auth";
import { usePopup } from "../popup/PopupContext";
import { useTranslation } from "react-i18next";
import IconSite from './../IconSite';
import CheckBoxJLPT from './CheckBoxJLPT';

function AutoImportConfig() {

    const config = useSelector(state => state.config);
    const { configs, loading, error } = config;

    const auth = useAuth();
    const popup = usePopup();
    const { t } = useTranslation();

    const [english, setEnglish] = useState(false);
    const [french, setFrench] = useState(false);

    const [nk5, setNk5] = useState(false);
    const [nk4, setNk4] = useState(false);
    const [nk3, setNk3] = useState(false);
    const [nk2, setNk2] = useState(false);
    const [nk1, setNk1] = useState(false);

    const [nv5, setNv5] = useState(false);
    const [nv4, setNv4] = useState(false);
    const [nv3, setNv3] = useState(false);
    const [nv2, setNv2] = useState(false);
    const [nv1, setNv1] = useState(false);

    const dispatch = useDispatch();

    const loadFile = (event) => {
        event.preventDefault();
        if (auth.user ) {
            dispatch(importAutoLecon(auth.user, english, french, nk5, nk4, nk3, nk2, nk1, nv5, nv4, nv3, nv2, nv1)).catch(error => alert("configs.js row 30 : " + error + ",the file may be too long, please split it!"));
        } else {
            popup.open(t("signupwarning"));
        }
    }

    const isInPlan = (e, name, fn) => {
        if (auth.user) {
            if (auth.user.state === 'full') {
                fn(e);
            } else if (name === 'nk5' || name === 'nk4' || name === 'english' || name === 'french') {
                fn(e);
            } else {
                popup.open(t("signupwarning"));
            }
        }
        else {
            popup.open(t("signupwarning"));
        }
    }

    return (
        // auth.user && auth.user.state === 'full' &&
        <form onSubmit={loadFile} className='autoimport' >
            <div className="autoimport__title">
                JLPT
            </div>
            <div className="autoimport__langue">
                <h3>{t("languechoice")}:</h3>
                <CheckBoxJLPT name="english" display="English" value={english} setValue={setEnglish} checker={isInPlan} />
                <CheckBoxJLPT name="french" display="French" value={french} setValue={setFrench} checker={isInPlan} />
            </div>
            <div className="autoimport__kanji">
                <h3>KANJI:</h3>
                <div className="autoimport__kanjiGrid">
                    <CheckBoxJLPT name="nk5" display="N5 Free" value={nk5} setValue={setNk5} checker={isInPlan} />
                    <CheckBoxJLPT name="nk4" display="N4 Free" value={nk4} setValue={setNk4} checker={isInPlan} />
                    <CheckBoxJLPT name="nk3" display="N3" value={nk3} setValue={setNk3} checker={isInPlan} />
                    <CheckBoxJLPT name="nk2" display="N2" value={nk2} setValue={setNk2} checker={isInPlan} />
                    <CheckBoxJLPT name="nk1" display="N1" value={nk1} setValue={setNk1} checker={isInPlan} />
                </div>
                <div className="autoimport__kanjiGrid">
                    <h3>N5: 2L-79K</h3>
                    <h3>N4: 3L-166K</h3>
                    <h3>N3: 6L-367K</h3>
                    <h3>N2: 6L-367K</h3>
                    <h3>N1: 20L-1232K</h3>
                </div>
                <h3>2211 KANJI, 37 Lessons</h3>
            </div>
            <div className="autoimport__kanji">
                <h3>Vocab:</h3>
                <div className="autoimport__kanjiGrid">
                    <CheckBoxJLPT name="nv5" display="N5" value={nv5} setValue={setNv5} checker={isInPlan} />
                    <CheckBoxJLPT name="nv4" display="N4" value={nv4} setValue={setNv4} checker={isInPlan} />
                    <CheckBoxJLPT name="nv3" display="N3" value={nv3} setValue={setNv3} checker={isInPlan} />
                    <CheckBoxJLPT name="nv2" display="N2" value={nv2} setValue={setNv2} checker={isInPlan} />
                    <CheckBoxJLPT name="nv1" display="N1" value={nv1} setValue={setNv1} checker={isInPlan} />
                </div>
                <div className="autoimport__kanjiGrid">
                    <h3>N5: 9L-669W</h3>
                    <h3>N4: 8L-634W</h3>
                    <h3>N3: 23L-1835W</h3>
                    <h3>N2: 18L-1797W</h3>
                    <h3>N1: 35L-3476W</h3>
                </div>
                <h3>8411 Words, 93 Lessons</h3>
            </div>

            <div className="autoimport__buttonContainer">
                <button type="submit" className="autoimport__button"  >
                    <span className="material-icons md-24 md-light">upload_file</span>
                    {t("jlptimport")}
                </button>
            </div>
        </form>
    )
}

export default AutoImportConfig
