const reducerReactPosition = (type, mots, dispatch, state, set) => {
  switch (type) {
    case "increment":
      if (state.position === mots.length - 1) {
        dispatch(set({ ...state, attempt: -1, solution: false, position: 0 }));
        return;
      } else {
        dispatch(
          set({
            ...state,
            attempt: -1,
            solution: false,
            position: state.position + 1,
          })
        );
        return;
      }
    case "decrement":
      if (state.position === 0) {
        dispatch(set({ ...state, solution: false, attempt: -1, position: mots.length - 1 }));
        return;
      } else {
        dispatch(set({ ...state, solution: false, attempt: -1, position: state.position - 1 }));
        return;
      }
    case "focus":
      if (mots[state.randoms[state.position]] !== undefined) {
        dispatch(set(mots[state.randoms[state.position]]._id));
      }
      return;
    default:
      throw new Error();
  }
};

export { reducerReactPosition };
