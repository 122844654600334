import React, { useState, useContext, createContext } from "react";
import _ from 'lodash';

const popUpContext = createContext();

export function ProvidePopUp({ children }) {
    const popup = useProvidePopup();
    return <popUpContext.Provider value={popup}>{children}</popUpContext.Provider>;
}

export const usePopup = () => {
    return useContext(popUpContext);
};

function useProvidePopup() {

    const [isOpen, setIsOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [isDisplayMessageOpen, setIsDisplayMessageOpen] = useState(false);
    

    const [param, setParam] = useState('');

    const [doit, setDoit] = useState(null);

    const [msg, setMsg] = useState('');
    const [actionname, setActionname] = useState('');

    const close = () => {
        setIsOpen(false);
        setIsConfirmOpen(false);
        setIsErrorOpen(false);
        setIsDisplayMessageOpen(false);
    }

    const open = (message) => {
        setMsg(message);
        setIsOpen(true);
    }

    const openError = (message) => {
        setMsg(message);
        setIsErrorOpen(true);
    }

    const openConfirm = (message, parameter, actionname) => {
        setMsg(message);
        setParam(parameter);
        setIsConfirmOpen(true);
        setActionname(actionname);
    }

    const openDisplayMessage = (message) => {
        setMsg(message);
        setIsDisplayMessageOpen(true);
    }

    const setAction = (methode) => {
        setDoit(methode);
    }

    const confirm = () => {
        doit();
        setDoit(null);
        setIsConfirmOpen(false);
    }

    return {
        isConfirmOpen,
        isErrorOpen,
        isOpen,
        isDisplayMessageOpen,
        msg,
        doit,
        param,
        actionname,
        openDisplayMessage,
        openError,
        openConfirm,
        close,
        open,
        confirm,
        setAction
    };

}