import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { configReducers } from './reducers/configReducers';
import { leconReducers } from './reducers/leconReducers';
import { motReducers } from './reducers/motReducers';
import { texteReducers } from './reducers/texteReducers';
import { gameReducers } from './reducers/gameReducers';
import { gameTexteReducers } from './reducers/gameTexteReducers';
import { mailReducers } from './reducers/mailReducers';
import { langueReducers } from './reducers/langueReducers';
import _ from 'lodash';


const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

let persistedState = loadState();

const reducer = combineReducers({
  lecon: leconReducers,
  mot: motReducers,
  texte: texteReducers,
  config: configReducers,
  game: gameReducers,
  gameTexte: gameTexteReducers,
  langue: langueReducers,
  email: mailReducers
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, persistedState, composeEnhancer(applyMiddleware(thunk)));

store.subscribe(_.throttle(() => {
  saveState({
    lecon: store.getState().lecon,
    game: store.getState().game,
    gameTexte: store.getState().gameTexte,
    mot: store.getState().mot,
    texte: store.getState().texte,    
    config: store.getState().config,
    langue: store.getState().langue
  });
}, 1000))

export default store;
