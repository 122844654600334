import React from 'react'
import { useTranslation } from "react-i18next";
import { SubcriptionFree as SubcriptionFreeEN }  from "./EN/SubcriptionFree";
import { SubcriptionFree as SubcriptionFreeFR } from "./FR/SubcriptionFree";
import { SubcriptionFree as SubcriptionFreeJP } from "./JP/SubcriptionFree";


function SubcriptionFree() {

    const { t } = useTranslation();

    const language = t("language");
  
    return (
      <>
        {language === "JP" && <SubcriptionFreeJP />}
        {language === "EN" && <SubcriptionFreeEN />}
        {language === "FR" && <SubcriptionFreeFR />}
      </>
    );
}

export default SubcriptionFree
