import axios from 'axios';
import { CONFIGS_FAIL, CONFIGS_REQUEST, CONFIGS_SUCCESS, LECONS_FAIL, LECONS_REQUEST, LECONS_SUCCESS, LECON_FAVORITE, LECONS_TEXTE_SUCCESS, MOT_CLEAN, TEXTE_CLEAN } from '../actionTypes';
import configApp from '../../config';

const listVisitorConfigs = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CONFIGS_REQUEST });
        const { data } = await axios.get(configApp.URL_BACKEND + "/api/config/visitor/");
        dispatch({ type: CONFIGS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CONFIGS_FAIL, payload: error.message });
    }
}

const listVisitorLecons = (type, favorite) => async (dispatch, getState) => {
    try {
        dispatch({ type: LECONS_REQUEST });
        if (type === "word") {
            const { data } = await axios.get(configApp.URL_BACKEND + "/api/lecon/visitor/" + type + "&" + favorite);
            dispatch({ type: LECONS_SUCCESS, payload: data });
            dispatch({ type: MOT_CLEAN });
        } else {
            const { data } = await axios.get(configApp.URL_BACKEND + "/api/lecon/visitor/" + type + "&" + favorite);
            dispatch({ type: LECONS_TEXTE_SUCCESS, payload: data });
            dispatch({ type: TEXTE_CLEAN });
        }
        dispatch({ type: LECON_FAVORITE, payload: { type, favorite , progression : false , completed : false } });

    } catch (error) {
        dispatch({ type: LECONS_FAIL, payload: error.message });
    }
}

export { listVisitorConfigs, listVisitorLecons };