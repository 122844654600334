import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteMot, resetMot, saveMot } from '../../redux/actions/motActions';
import Translate from './Translate';
import IconSite from '../IconSite';
import { useAuth } from "../use-auth";
import { usePopup } from "../popup/PopupContext";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { createNewWord, saveWord, deleteWord } from './utils';
import TranslateImage from './TranslateImage';

function Translates(props) {

    const config = useSelector(state => state.config);
    const { configs, loading, error } = config;

    const mot = useSelector(state => state.mot);
    const { lecons, configSelected, motSelected } = mot;

    const auth = useAuth();
    const popup = usePopup();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const save = (event) => {
        event.preventDefault();
        saveWord(auth, dispatch, lecons, popup, t, props, saveMot);
    }

    return (
        loading ?
            <form className="translates"  >
                <div className="translates__menu">
                    <button className="icon-button">
                        <IconSite name="icon-save" cssname="icon" />
                    </button>
                    <button className="icon-button">
                        <IconSite name="icon-squared-plus" cssname="icon" />
                    </button>
                    <button className="icon-button">
                        <IconSite name="icon-squared-minus" cssname="icon" />
                    </button>
                </div>
                <ul className="translates__list">
                </ul>
            </form>
            : error ? <div>{error}</div> :
                <form onSubmit={save} className="translates"  >
                    <div className="translates__menu">
                        <button type="button" onClick={(event) => createNewWord(event, auth, dispatch, lecons, popup, t, props, resetMot)} className="icon-button">
                            <IconSite name="icon-squared-plus" cssname="icon" />
                        </button>
                        <span>{t("addword")}</span>

                        <button type="submit" className="icon-button">
                            <IconSite name="icon-save" cssname="icon" />
                        </button>
                        <span>{t("saveword")}</span>

                        <button type="button" onClick={(event) => deleteWord(event, auth, dispatch, popup, t, deleteMot)} className="icon-button">
                            <IconSite name="icon-squared-minus" cssname="icon" />
                        </button>
                        <span>{t("removeword")}</span>
                    </div>
                    <div className="translates__container" >
                        <ul className="translates__list">
                            {
                                configs.map(config => config.type === props.type &&
                                    <li key={"translate" + config._id} className="translates__item">
                                        <Translate _id={config._id} name={config.name} position={config.position}
                                            isSelected={configSelected && config._id === configSelected._id ? true : false} />
                                    </li>
                                )
                            }
                            <li key={"translateImage"} className="translates__item">
                                <TranslateImage />
                            </li>
                        </ul>

                        {motSelected && motSelected.image && <img className="imageMot"
                            src={motSelected.image}
                            alt="new"
                        />}
                    </div>
                    {auth.user === null &&
                        <div className="tuto">
                            <p>{t("flashcardmanagement")}</p>
                            <p className="tuto__help">{t("trick")}</p>
                        </div>}
                </form>
    )
}

export default Translates
