import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changeName, deleteLecon, toggle_favorite, toggle_status } from '../../redux/actions/leconActions';
import IconSite from './../IconSite';
import Moment from 'react-moment';
import { useAuth } from "./../use-auth";
import { useTranslation } from "react-i18next";
import { usePopup } from "../../components/popup/PopupContext";
import PopupLecon from '../../components/popup/PopupLecon';

function Lecon(props) {

    const auth = useAuth();
    const popup = usePopup();

    const { t } = useTranslation();

    let [isOpen, setIsOpen] = useState(false);

    let [isActive, setisActive] = useState(() => {
        return props.selected.find(x => x === props._id) ? true : false;
    });

    let [favorite, setFavorite] = useState(props.lecon.favorite);
    let [status, setStatus] = useState(props.lecon.status);
    
    const [isFadingOut, setIsFadingOut] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        setisActive(props.selected.find(x => x === props._id) ? true : false);
        return () => {
        };
    }, [props.selected]);

    const monAction = (name , notes) => {
        dispatch(changeName({ name: name, notes : notes , _id: props._id }, props.updateReducerType)).catch(error => alert("Lecon.js 27 " + error));
    }

    const toggleFavorite = () => {
        dispatch(toggle_favorite({ favorite: !favorite, _id: props._id, type: props.type })).catch(error => alert("Lecon.js 31 " + error));
        setFavorite(!favorite);
    }

    const toggleStatus = () => {
        let newStatus = 0;
        if (status === 0 ) {
            newStatus = 1;
        } else if (status === 1){
            newStatus = 2;
        } else {
            newStatus =0;
        }
        dispatch(toggle_status({ status: newStatus, _id: props._id, type: props.type })).catch(error => alert("Lecon.js 31 " + error));
        setStatus(newStatus);
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const toggleLecon = () => {
        if (isActive) {
            dispatch(props.unselectLecon(props._id)).catch(error => alert("Lecon.js 41 " + error));
        } else {
            dispatch(props.selectLecon(props._id)).catch(error => alert("Lecon 43 " + error));
        }
        setisActive(!isActive);
    }


    return (

        <div className={isFadingOut ? 'lecon-fadeout' : isOpen ? 'lecon__open' :  status === 0 ? 'lecon lecon__green' : status === 1 ? 'lecon ' : 'lecon lecon__red' } >
            {isOpen && <PopupLecon
                content={<>lecon</>
                }
                lecon={props.lecon}
                handleClose={togglePopup}
                handleSave={(name , notes) => monAction(name , notes)}
            />}
            <div className="lecon__header">
                <div >{props.lecon.name}</div>
            </div>
            <div className="lecon__main" >
                <div className="checkbox" >
                    <input id={props._id} type="checkbox" checked={isActive} onChange={() => toggleLecon()} />
                    <label htmlFor={props._id}>
                        <span className="box"></span>
                    </label>
                </div>
                <div className="lecon__main__word">{props.lecon.mots} {t("words")}</div>
                <div className="lecon__main__create" ><Moment format="DD/MM/YY" >{props.lecon.last}</Moment> {t("lastaccess")}</div>
            </div>
            <div className={ status === 0 ? 'lecon__footer lecon__footer__green' : status === 1 ? 'lecon__footer ' : 'lecon__footer lecon__footer__red' }  >
                {props.createLecon === 'true' ? <button onClick={(event) => {
                    event.preventDefault();
                    if (auth.user) {
                        togglePopup();
                    } else {
                        popup.open(t("signupwarning"));
                    }
                }} className={ status === 0 ? 'icon-button--smallGreen' : status === 1 ? 'icon-button--small' : 'icon-button--smallRed' }   >
                    <IconSite name="icon-new-message" cssname={ status === 0 ? 'icon--smallGreen' : status === 1 ? 'icon--small' : 'icon--smallRed' } />
                </button> : ""}
                {props.createLecon === 'true' ? <button onClick={(event) => {
                    event.preventDefault();
                    if (auth.user) {
                        const monAction = () => {
                            setIsFadingOut(true);
                            setTimeout(() => dispatch(deleteLecon(props._id, props.deleteReducerType)).catch(error => alert("Lecon.js 86 " + error)), 500);
                            setTimeout(() => dispatch(props.unselectLecon(props._id)).catch(error => alert("Lecon.js 87 " + error)), 500);
                        }
                        popup.setAction(() => monAction);
                        popup.openConfirm('deleteconfirm', props.lecon.name);
                    } else {
                        popup.open(t("signupwarning"));
                    }
                }
                } className={ status === 0 ? 'icon-button--smallGreen' : status === 1 ? 'icon-button--small' : 'icon-button--smallRed' } >
                    <IconSite name="icon-squared-minus" cssname={ status === 0 ? 'icon--smallGreen' : status === 1 ? 'icon--small' : 'icon--smallRed' } />
                </button> : ""}
                <button onClick={(event) => {
                    event.preventDefault();
                    if (auth.user) {
                        toggleFavorite();
                    } else {
                        popup.open(t("signupwarning"));
                    }
                }} className={ status === 0 ? 'icon-button--smallGreen pointer' : status === 1 ? 'icon-button--small pointer' : 'icon-button--smallRed pointer' }>
                    {favorite ? <span className="material-icons green md-24">favorite</span> : <span className="material-icons md-24 md-light">favorite</span>}
                </button>
                <button onClick={(event) => {
                    event.preventDefault();
                    if (auth.user) {
                        toggleStatus();
                    } else {
                        popup.open(t("signupwarning"));
                    }
                }} className={ status === 0 ? 'icon-button--smallGreen pointer' : status === 1 ? 'icon-button--small pointer' : 'icon-button--smallRed pointer' }>
                    {status === 0 ? <span className="material-icons md-24 md-light">crop_square</span> : 
                    status === 1 ? <span className="material-icons md-24 md-light">trending_up</span> : <span className="material-icons md-24 md-light">task</span>}
                </button>
            </div>
        </div>
    )
}

export default Lecon