import React from 'react'
import {
    Link
} from "react-router-dom";
import { useTranslation } from "react-i18next";


export function Footer() {

    const { t, i18n } = useTranslation();

    return (
        <div className="footer" >
            <Link className="footer__item info" to="/policy">
                <span title={t('policy')} className="material-icons md-36 md-light">policy</span>
            </Link>
            <Link className="footer__item" to="/question">
                <span title={t('help')} className="material-icons md-36 md-light">help_center</span>
            </Link>
            <Link className="footer__item" to="/mail">
                <span title={t('email')} className="material-icons md-36 md-light">mail</span>
            </Link>
            <Link className="footer__item" to="/myself">
                <span title={t('presentation')} className="material-icons md-36 md-light">portrait</span>
            </Link>
            <Link className="footer__item" to="/release">
                <span title={t('release')} className="material-icons md-36 md-light">queue_play_next</span>
            </Link>
        </div>
    );
};

export default Footer