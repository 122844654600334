import React from "react";
import { useTranslation } from "react-i18next";
import { Presentation as PresentationEN }  from "./EN/Presentation";
import { Presentation as PresentationFR } from "./FR/Presentation";
import { Presentation as PresentationJP } from "./JP/Presentation";

function Presentation() {
  const { t } = useTranslation();

  const language = t("language");

  return (
    <>
      {language === "JP" && <PresentationJP />}
      {language === "EN" && <PresentationEN />}
      {language === "FR" && <PresentationFR />}
    </>
  );
}

export default Presentation;
