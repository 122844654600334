import { USER_SIGNOUT, CONFIGS_FAIL, CONFIGS_REQUEST, CONFIGS_SUCCESS, CONFIG_REMOVE_ITEM, CONFIG_CREATE_ITEM , CONFIG_CHANGE_NAME} from "../actionTypes";

function configReducers(state = { configs: []}, action) {
    switch (action.type) {
        case USER_SIGNOUT:
            return { configs: [] , loading: true };
        case CONFIGS_REQUEST:
            return { loading: true };
        case CONFIGS_SUCCESS:
            return { loading: false, configs: action.payload };
        case CONFIGS_FAIL:
            return { loading: false, error: action.payload };
        case CONFIG_REMOVE_ITEM:
            return { configs:  action.payload };
        case CONFIG_CREATE_ITEM:
            return { configs: [...state.configs, action.payload] };
        case CONFIG_CHANGE_NAME:
            return {
                configs: state.configs.map(x => x._id !== action.payload._id ?  x : { ...x , name : action.payload.name , isEnable : action.payload.isEnable } )
            };   
        default:
            return state;
    }
}

export { configReducers }