import React from "react";

function SubcriptionFull() {
  return (
    <section className="subcriptionfull">
    
    </section>
  );
}

export { SubcriptionFull };
