import { CHANGE_LANGUE
} from "../actionTypes";

function langueReducers(state = { langue : 'en' }, action) {
    switch (action.type) {
        case CHANGE_LANGUE:
            return { langue : action.payload };
        default:
            return state;
    }
}

export { langueReducers }