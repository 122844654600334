import {
  USER_SIGNOUT,
  TEXTES_FAIL,
  TEXTES_REQUEST,
  TEXTES_SUCCESS,
  TEXTES_REMOVE_ITEM,
  TEXTE_LECON_SELECTED,
  TEXTE_LECON_UNSELECTED,
  TEXTE_SELECTED,
  TEXTE_UNSELECTED,
  TEXTE_SELECTED_UPDATE,
  TEXTE_SAVE,
  TEXTE_RESET,
  TEXTE_CONFIG_REMOVE_ITEM,
  TEXTE_CONFIG_SELECTED,
  TEXTE_CLEAN,
  TEXTE_LECON_SELECTED_BY_MOT,
  TEXTE_LECON_SELECT_UNSELECT_ALL,
  TEXTES_BANK_REQUEST,
  TEXTES_BANK_SUCCESS,
  TEXTES_BANK_CLEAN,
  TEXTE_UPDATE_BANK_WORD,
  TEXTE_CREATE_BANK_WORD,
  TEXTE_FONT,
  TEXTES_FOCUS,
  TEXTE_ISFOCUS,
  TEXTE_ONLYFOCUS_BANK_WORD
} from "../actionTypes";

function texteReducers(
  state = {
    focus: [], mots: [], lecons: [], motSelected: {}, configSelected: {}, bank: [], font: "no",
    isFocus: false
  },
  action
) {
  switch (action.type) {
    case USER_SIGNOUT:
      return {
        focus: [],
        mots: [],
        lecons: [],
        motSelected: {},
        configSelected: {},
        loading: false,
        loadingBank: false,
        bank: [],
        font: "no",
        isFocus: false
      };
    case TEXTE_ISFOCUS:
      return { ...state, isFocus: action.payload };
    case TEXTES_BANK_REQUEST:
      return { ...state, loadingBank: true };
    case TEXTES_BANK_SUCCESS:
      return { ...state, loadingBank: false, bank: [...state.bank, ...action.payload] };
    case TEXTES_BANK_CLEAN:
      return { ...state, loadingBank: false, bank: action.payload };
    case TEXTES_REQUEST:
      return { ...state, loading: true };
    case TEXTES_SUCCESS:
      return { ...state, loading: false, mots: action.payload };
    case TEXTES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case TEXTES_REMOVE_ITEM:
      return {
        ...state,
        mots: state.mots.filter((x) => x._id !== action.payload),
        motSelected: {},
      };
    case TEXTE_LECON_SELECTED:
      let leconToSelectIsThere = state.lecons.find(
        (x) => x === action.payload._id
      );
      if (!leconToSelectIsThere) {
        return {
          ...state,
          loading: false,
          mots: [...state.mots, ...action.payload.data],
          lecons: [...state.lecons, action.payload._id],
        };
      }
      return state;
    case TEXTE_LECON_SELECTED_BY_MOT:
      return {
        ...state,
        mots: action.payload.data,
        lecons: action.payload.ids,
      };
    case TEXTE_LECON_UNSELECTED:
      let leconToUnselectIsThere = state.lecons.find(
        (x) => x === action.payload._id
      );
      if (leconToUnselectIsThere) {
        return {
          ...state,
          mots: action.payload.data,
          lecons: state.lecons.filter((x) => x !== action.payload._id),
        };
      }
      return state;
    case TEXTE_SELECTED:
      return { ...state, motSelected: action.payload };
    case TEXTE_UNSELECTED:
      return { ...state, motSelected: action.payload };
    case TEXTE_SELECTED_UPDATE:
      return { ...state, motSelected: action.payload };
    case TEXTE_SAVE:
      return { ...state, motSelected: action.payload };
    case TEXTE_RESET:
      return { ...state, motSelected: action.payload };
    case TEXTE_FONT:
      return { ...state, font: action.payload };
    case TEXTE_CONFIG_REMOVE_ITEM:
      let motSelected = state.motSelected;
      if (motSelected) {
        if (motSelected.mot) {
          motSelected.mot = motSelected.mot.filter(
            (x) => x.config !== action.payload
          );
        }
        return { ...state, motSelected: motSelected };
      }
    case TEXTE_CONFIG_SELECTED:
      return { ...state, configSelected: action.payload };
    case TEXTE_CLEAN:
      return { ...state, mots: [], lecons: [], motSelected: {}, configSelected: {}, bank: [] };
    case TEXTE_LECON_SELECT_UNSELECT_ALL:
      return {
        ...state,
        mots: action.payload.data,
        lecons: action.payload.ids,
      };
    case TEXTE_ONLYFOCUS_BANK_WORD:
      return { ...state, mots: action.payload.focus, bank: action.payload.focus };
    case TEXTE_CREATE_BANK_WORD:
      return { ...state, mots: [...state.mots, action.payload.mot], bank: [...state.bank, action.payload.wordBank] };
    case TEXTE_UPDATE_BANK_WORD:
      let newState = state.mots.map(o => { return o._id === action.payload.mot._id ? action.payload.mot : o });
      return { ...state, mots: newState, bank: [...state.bank.filter(o => o._id !== action.payload.wordBank._id), action.payload.wordBank] };
    case TEXTES_FOCUS:
      return { ...state, focus: action.payload };
    default:
      return state;
  }
}

export { texteReducers };
