import React from "react";
import game from "../../../images/PNG/smartphone-game.webp";
import lecon from "../../../images/PNG/smartphone-lecon.webp";
import setting from "../../../images/PNG/smartphone-setting.webp";

function Release() {

  return (
    <section className="release">
      <div className="release__container">
        <div id='top' className="release__index">
          <div className="release__short">
            <span className="material-icons md-24">maps_ugc</span>
            <a href="#quick">
              <h1>次のステップ</h1>
            </a>
          </div>
        </div>
        <ul className="release__feature">
          <li className="release__feature__item">クイズを追加</li>
          <li className="release__feature__item">検索パラメータを追加</li>
          <li className="release__feature__item">フィルター</li>
          <li className="release__feature__item">クイズの結果と詳細</li>
          <li className="release__feature__item">タグの追加</li>
        </ul>
        <div id='top' className="release__index">
          <div className="release__short">
            <span className="material-icons md-24">maps_ugc</span>
            <a href="#quick">
              <h1>只今製作中</h1>
            </a>
          </div>
        </div>
        <ul className="release__feature">
          <li className="release__feature__item">スマホアプリ</li>
          <div className="release__smartphone">
            <img className="release__smartphone__item"
              alt="Game"
              src={game}
            />
            <img className="release__smartphone__item"
              alt="Lecon"
              src={lecon}
            />
            <img className="release__smartphone__item"
              alt="Setting"
              src={setting}
            />
          </div>
        </ul>
        <div id='top' className="release__index">
          <div className="release__short">
            <span className="material-icons md-24">maps_ugc</span>
            <a href="#quick">
              <h1>今後製作予定</h1>
            </a>
          </div>
        </div>
        <ul className="release__feature">
          <li className="release__feature__item">画像を追加</li>
          <li className="release__feature__item">音声入力</li>
        </ul>
        <br />
        <p>
          色々な勉強をする人たちの為により良いアプリにして行きたいと思っています。ただ、それにはとても時間がかかります。
          登録してくれた方々には感謝と共に次回のプロジェクトに進めていくための助けとなっていきます。
        </p>
        <br />
        <br />
        <h1>
          応援してくれてありがとうございます！
        </h1>
        <br />
      </div>
    </section>
  );
}

export { Release };
