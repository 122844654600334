import React from "react";
import { useTranslation } from "react-i18next";
import { Release as ReleaseEN } from "./EN/Release";
import { Release as ReleaseFR } from "./FR/Release";
import { Release as ReleaseJP } from "./JP/Release";

function Release() {
  const { t } = useTranslation();

  const language = t("language");

  return (
    <>
      {language === "JP" && <ReleaseJP />}
      {language === "EN" && <ReleaseEN />}
      {language === "FR" && <ReleaseFR />}
    </>
  );
}

export default Release;
